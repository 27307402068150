import { LeaveType } from "@/types/Leave";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface employee {
  employeeId: string;
}

export interface leave {
  leaveTitle: string;
  img: string;
  description: string;
  leaveType: string;
  days: string;
  employees: employee[];
  leaveTypeId: number;
}

export interface LeaveTypes {
  Leave: LeaveType[];
}

const leaveTypes: LeaveType[] = [
  {
    leaveTypeId: 1125899906842625,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Study Leave",
    isPaid: true,
    image: "/img/study-photo.jpg",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24
  },
  {
    leaveTypeId: 1125899908000078,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Parental leave",
    isPaid: true,
    image: "/img/MicrosoftTeams-image.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24,
  },
  {
    leaveTypeId: 1125899908000098,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Sick Leave",
    isPaid: true,
    image: "/img/Sick_Leave.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24,
  },
  {
    leaveTypeId: 4,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Jury Duty Leave",
    isPaid: true,
    image: "/img/Jury_Duty_Leave.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24
  },
  {
    leaveTypeId: 4,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Jury Duty Leave",
    isPaid: true,
    image: "/img/Jury_Duty_Leave.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24
  },
  {
    leaveTypeId: 5,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Vacation/Annual Paid Leave",
    isPaid: true,
    image: "/img/Annual_Paid_Leave.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24
  },
  {
    leaveTypeId: 6,
    tenantId: "",
    isRecoupable: true,
    employmentType: "Full-time",
    status: "",
    maximumAccrueDays: 12,
    accrues: true,
    name: "Bereavement Leave",
    isPaid: true,
    image: "/img/Bereavement_Leave.png",
    description: "You are eligible for this leave",
    maximumLeaveDays: 24,
  }
];

// const initialState: LeaveType[] = {
//   leaveTypes: constructor_array,
// };

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: leaveTypes,
  reducers: {
    setLeaveTypes: (
      state: LeaveType[],
      action: PayloadAction<LeaveType[]>,
    ) => {
      // switch (action.type) {
      //   case "contractor":
      //     state.Contractor = action.payload.contractor;
      //     break;
      //   case "contractor":
      //     state.Candidate = action.payload.candidate;
      //     break;
      //   case "employee":
      //     state.Employee = action.payload.employee;
      //     break;
      //   case "all":
      //     state.Contractor = action.payload.contractor;
      //     state.Candidate = action.payload.candidate;
      //     state.Employee = action.payload.employee;
      //     break;
      //   default:
      //     break;
      // }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLeaveTypes } = contractsSlice.actions;

export default contractsSlice.reducer;
