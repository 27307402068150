import { server } from "@/config/server";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const domesticTaxCollectionApiSlice = createApi({
  reducerPath: "domesticTaxCollectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
  }),
  tagTypes: ["files"],
  endpoints: (builder) => ({
    submitDomesticTaxCollection: builder.mutation<any, any>({
      query: (file) => ({
        url: "/household-employees/pre-onboard",
        method: "POST",
        body: file,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "files", id: "fileId" }],
    }),
  }),
});

export const { useSubmitDomesticTaxCollectionMutation } = domesticTaxCollectionApiSlice;