import { Button, IconButton, Switch, Typography } from "@zigops-material/react";
import React, { useState } from "react";

// Define a TypeScript interface for the DebitRemindersProps
interface DebitRemindersProps {
  id: string;
  label: string;
}

// Initial data for debit reminders
const initalDebitReminders = [
  {
    id: "1",
    label: "On debit date",
  },
  {
    id: "2",
    label: "3 days before payroll debit date",
  },
];

// Functional component named Payroll
function Payroll() {
  // Set up state for DebitReminders using useState hook
  const [DebitReminders, setDebitReminders] = useState<DebitRemindersProps[]>(initalDebitReminders);

  return (
    <div className="w-full animate-fade-left p-5 animate-once">
      <div className="border-b border-b-gray-400 pb-4 dark:border-b-gray-750">Payroll</div>

      <div className="mt-2 h-[760px] w-full overflow-y-auto overflow-x-hidden pr-2">
        {/* Debit reminders section */}
        <section className="animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-700 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <h3 className="font-bold">Standard debit reminders</h3>
            <p>Choose how many days before due date to send approval request</p>
          </div>

          <div className="mt-2">
            <div>
              {/* Map through DebitReminders array and display each reminder */}
              {DebitReminders.map((debitRem) => (
                <div
                  key={debitRem.id}
                  className="mt-2 flex justify-between rounded-md border border-gray-300 bg-white p-2 pl-4 dark:border-gray-750 dark:bg-gray-850"
                >
                  <div className="mt-1">
                    <Typography>{debitRem.label}</Typography>
                  </div>
                  <IconButton variant="text" color="red" size="sm">
                    {/* Delete icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </IconButton>
                </div>
              ))}
            </div>

            <div className="mt-2">
              {/* Button to add new debit reminders */}
              <Button variant="text" size="sm">
                Add debit reminders
              </Button>
            </div>
          </div>
        </section>

        {/* Deduction Notifications section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div className="flex justify-between">
            <div>
              <h3 className="font-bold">Deduction Notifications</h3>
              <p>Receive deduction notification on this payroll</p>
            </div>
            <div className="mt-2">
              {/* Switch for toggling notifications */}
              <Switch defaultChecked />
            </div>
          </div>
        </section>

        {/* Company payment account section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <h3 className="font-bold">Company payment account</h3>
            <p>Update or review your company’s payment information</p>
          </div>

          {/* Bank name */}
          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Bank name</h3>
            </div>
            <div>
              <p>Temboh Studios</p>
            </div>
            <div>
              {/* Edit icon */}
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">SwiftCode</h3>
            </div>
            <div>
              <p>5826 9573 9720 5792</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">SortCode</h3>
            </div>
            <div>
              <p>0689 0368 1235 6426</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Iban</h3>
            </div>
            <div>
              <p>5734 2095 0549</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Account Number</h3>
            </div>
            <div>
              <p>907 423 7530</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Abartn</h3>
            </div>
            <div>
              <p>1039475</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        </section>

        {/* Fund Balance section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <h3 className="font-bold">Fund Balance</h3>
            <p>Choose when you would like to get notified to check your fund balance</p>
          </div>

          <div className="mt-2">
            {/* Default fund balance reminder */}
            <div className="mt-2 flex justify-between rounded-md border border-gray-300 bg-white p-2 pl-4 dark:border-gray-750 dark:bg-gray-850">
              <div className="mt-1">
                <Typography>Default (3 days)</Typography>
              </div>
              <IconButton variant="text" color="red" size="sm">
                {/* Delete icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </IconButton>
            </div>

            <div className="mt-2">
              {/* Button to set fund balance reminder */}
              <Button variant="text" size="sm">
                Set fund balance reminder
              </Button>
            </div>
          </div>
        </section>

        {/* Payslips section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div className="flex justify-between">
            <div>
              <h3 className="font-bold">Payslips</h3>
              <p>Generate and send payslips to employees</p>
            </div>
            <div className="mt-2">
              {/* Switch for toggling payslip generation */}
              <Switch defaultChecked />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Payroll;
