import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HolidayProps {
  country: string;
  date: Date;
  name: string;
  observed: string;
  public: boolean;
  uuid: string;
  weekday: { date: { name: string; numeric: string }; observed: { name: string; numeric: string } };
}

export interface Holiday {
  holidays: HolidayProps[];
}

// Initialize the trigger state
const initialState: any = {
  holidays: [],
};

export const holidaysListSlice = createSlice({
  name: "holidaysList",
  initialState,
  reducers: {
    setHolidays: (state: any, action: PayloadAction<any>) => {
      state.holidays = action.payload.holidays;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHolidays } = holidaysListSlice.actions;

export default holidaysListSlice.reducer;
