import {
  DOMESTIC_TAX_COLLECTION_NHIMA_FILE_NAME,
  DOMESTIC_TAX_COLLECTION_PENDING_PROCESS,
  DOMESTIC_TAX_COLLECTION_SERVER_RESPONSE_ERROR_MESSAGE,
  DOMESTIC_TAX_COLLECTION_SUCCESSFUL_MESSAGE,
  DOMESTIC_TAX_COLLECTION_UPLOAD_FILE_ERROR_MESSAGE,
} from "@/constants/common.constants";
import useStorage from "@/hooks/useStorage";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@zigops-material/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setHouseholdEmployerNhimaUrl } from "@/redux/features/household/household-employer-tax-details";
import { RootState } from "@/redux/configureStore";
import { bytesToSize } from "@/utils/format";

function UploadNHIMA() {
  const [theme, setTheme] = useState("");
  const { getItem } = useStorage();

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null);

  const { employerFullName, employerPhoneNumber } = useSelector(
    (state: RootState) => state.app_reducer.employerTaxDetailsData,
  );

  useEffect(() => {
    setTheme(getItem("theme"));
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    setSelectedFile(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = async () => {
        // @ts-ignore
        const base64Image = reader.result.split(",")[1];

        await toast
          .promise(
            () =>
              axios.post("/api/upload", {
                base64Image,
                employerFullName,
                employerPhoneNumber,
                fileName: DOMESTIC_TAX_COLLECTION_NHIMA_FILE_NAME,
              }),
            {
              pending: DOMESTIC_TAX_COLLECTION_PENDING_PROCESS,
              success: DOMESTIC_TAX_COLLECTION_SUCCESSFUL_MESSAGE,
              error: DOMESTIC_TAX_COLLECTION_SERVER_RESPONSE_ERROR_MESSAGE,
            },
          )
          .then((data) => {
            dispatch(
              setHouseholdEmployerNhimaUrl({
                employerNhimaUrl: { file: selectedFile, fileURL: data.data.blobUrl },
              }),
            );
          });
      };
    } catch (error) {
      console.error(DOMESTIC_TAX_COLLECTION_UPLOAD_FILE_ERROR_MESSAGE, error.message);
    }
  };

  return (
    <div className="mb-5 mt-2 w-full rounded-xl pb-6">
      <Card className="mx-auto w-[350px] shadow-xl dark:bg-gray-850 md:w-[500px] lg:w-[500px]">
        <CardHeader
          variant="gradient"
          className="mb-4 grid h-28 place-items-center bg-transparent shadow-none"
        >
          <div className="overflow-hidden rounded-xl bg-white dark:bg-gray-850">
            <Avatar
              src={theme === "dark" ? "/icons/Nhima.svg" : "/icons/Nhima.svg"}
              variant="square"
              alt="avatar"
              size="xxl"
            />
          </div>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <div className="text-center">
            <Typography variant="small" className="text-gray-900 dark:text-gray-400">
              Select the NHIMA file you wish to upload
            </Typography>
          </div>
          <div>
            <div
              {...getRootProps()}
              style={{
                cursor: "pointer",
                border: "2px dashed #ccc",
                padding: "20px",
                paddingTop: "50px",
                paddingBottom: "50px",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} accept=".pdf" />
              <p>Drag & drop or click to select pdf file</p>
            </div>
            <div className="mt-2 text-gray-700 dark:text-gray-100">
              {selectedFile ? (
                <div className="flex justify-between rounded bg-blue-gray-200 p-2 dark:bg-gray-750">
                  <div className="flex text-sm md:text-base lg:text-base">
                    <div className="w-36 truncate pr-2 md:w-56 lg:w-56">{selectedFile.path}</div>
                    <div>
                      {`Size: ${bytesToSize(selectedFile.size).value.toFixed(2)} ${
                        bytesToSize(selectedFile.size).unit
                      }`}{" "}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                    className="rounded bg-red-200/20 p-1 px-2 text-sm text-red-400 "
                  >
                    X
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <Button
            onClick={handleUpload}
            variant="gradient"
            fullWidth
            className="flex w-full justify-center"
          >
            <div className="flex">
              <div>Upload file</div>
            </div>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default UploadNHIMA;
