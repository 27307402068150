import { Employee } from "@/apis/models/employee.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Employee = {
  avatar: "",
  userId: "",
  tenantId: "",
  tenantName: "",
  status: "",
  employeeId: "",
  email: "",
  firstName: "",
  lastName: "",
  role: "",
  maritalStatus: "",
  statusAsOf: "",
  dateOfBirth: "",
  dateOfHire: "",
  dateUpdated: "",
  gender: "",
  personalId: "",
  taxId: "",
  healthInsuranceId: "",
  paymentMethod: "",
  currency: "",
  phoneNumber: "",
  pensionDetails: {
    pensionDetailsId: 0,
    employeeId: "",
    pfaName: "",
    pensionNumber: "",
    pensionRate: 0,
  },
  bankDetails: {
    bankName: "",
    swiftCode: "",
    sortCode: "",
    iban: "",
    accountNumber: "",
    abartn: "",
  },
  compensationDetails: {
    employeeId: "",
    grossSalary: 0,
    jobTitle: "",
    group: "",
    employmentType: "",
    wageType: "",
    hasHousingContribution: false,
    hasHealthContribution: false,
    hasCompletePaymentDetails: false,
    additions: {
      bonus: 0,
      loan: 0,
      expenseReimbursements: 0,
      other: 0,
      otherDescription: "",
      overtime: 0,
    },
    deductions: { paye: 0, healthContribution: 0, loanRepayment: 0, housingContribution: 0 },
  },
  homeAddress: { employeeId: "", street: "", city: "", state: "", country: "", region: "", address: "" },
  emergencyContactDetails: {
    employeeId: "",
    name: "",
    relationship: "",
    email: "",
    phoneNumber: "",
  },
  mobileMoneyPaymentDetails: {
    providerName: "",
    phoneNumber: "",
  },
};

export const activeEmployeeSlice = createSlice({
  name: "activeEmployeeDetails",
  initialState,
  reducers: {
    setActiveUser: (state: Employee, action: PayloadAction<any>) => {
      state.userId = action.payload.employee.userId;
      state.avatar = action.payload.employee.avatar;
      state.status = action.payload.employee.status;
      state.employeeId = action.payload.employee.employeeId;
      state.email = action.payload.employee.email;
      state.firstName = action.payload.employee.firstName;
      state.lastName = action.payload.employee.lastName;
      state.tenantId = action.payload.employee.tenantId;
      state.tenantName = action.payload.employee.tenantName;
      state.role = action.payload.employee.role;
      state.maritalStatus = action.payload.employee.maritalStatus;
      state.statusAsOf = action.payload.employee.statusAsOf;
      state.dateOfBirth = action.payload.employee.dateOfBirth;
      state.dateOfHire = action.payload.employee.dateOfHire;
      state.gender = action.payload.employee.gender;
      state.personalId = action.payload.employee.personalId;
      state.taxId = action.payload.employee.taxId;
      state.paymentMethod = action.payload.employee.paymentMethod;
      state.currency = action.payload.employee.currency;
      state.homeAddress = action.payload.employee.homeAddress;
      state.emergencyContactDetails = action.payload.employee.emergencyContactDetails;
      state.compensationDetails = action.payload.employee.compensationDetails;
      state.bankDetails = action.payload.employee.bankDetails;
      state.pensionDetails = action.payload.employee.pensionDetails;
      state.mobileMoneyPaymentDetails = action.payload.employee.mobileMoneyPaymentDetails;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveUser } = activeEmployeeSlice.actions;

export default activeEmployeeSlice.reducer;
