import { Company } from './../../../types/Company';
// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formDataState {
  CompanyPhoneNumberError: string;
  CompanyPhysicalAddressError: string;
  CompanyProvinceError: string;
  CompanyCityError: string;
  CompanyPostalAddressError: string;
  CompanyTaxIdError: string;
  CompanyIndustryError: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
CompanyCityError: "",
CompanyPhoneNumberError: "",
CompanyPhysicalAddressError: "",
CompanyPostalAddressError: "",
CompanyProvinceError: "",
CompanyTaxIdError: "",
CompanyIndustryError: "",
};

// Creating a Redux slice for managing company form data
export const TribeCompanyFormDataErrorSlice = createSlice({
  name: "Tribe-plus-company-profile-form-data-error",
  initialState,
  reducers: {
    // Reducer to set Phone number
    setCompanyPhoneNumberError: (state: formDataState, action: PayloadAction<any>) => {
      state.CompanyPhoneNumberError = action.payload.CompanyPhoneNumberError;
    },
    // Reducer to set Postal address
    setCompanyPostalAddressError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyPostalAddressError = action.payload.CompanyPostalAddressError;
    },
    // Reducer to set Province
    setCompanyProvinceError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyProvinceError = action.payload.CompanyProvinceError;
    },
    // Reducer to set City
    setCompanyCityError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyCityError = action.payload.CompanyCityError;
    },
    // Reducer to set Physical address
    setCompanyPhysicalAddressError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyPhysicalAddressError = action.payload.CompanyPhysicalAddressError;
    },
    // Reducer to set Tax ID
    setCompanyTaxIdError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyTaxIdError = action.payload.CompanyTaxIdError;
    },
    // Reducer to set Industry
    setCompanyIndustryError: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyIndustryError = action.payload.CompanyIndustryError;
    },

  },
});

// Exporting action creators and reducer
export const {
setCompanyCityError,
setCompanyPhoneNumberError,
setCompanyPhysicalAddressError,
setCompanyPostalAddressError,
setCompanyProvinceError,
setCompanyTaxIdError,
setCompanyIndustryError,
} = TribeCompanyFormDataErrorSlice.actions;

export default TribeCompanyFormDataErrorSlice.reducer;
