import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";

const redirectUrl="https://staging.zigops.com/";

//SUbscription related endpoints
export const subscriptionApiSlice = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["Subscription", "SubscriptionStatus"],
  endpoints: (builder) => ({
    createSubscription: builder.mutation<any, any>({
      query: ({ tenantId, planId, planPriceId }) => {
        return {
          url: `/subscription`,
          method: "POST",
          body: {
            tenantId: tenantId,
            planId: planId,
            planPriceId: planPriceId,
          },
        };
      },
    }),

    changeSubscription: builder.mutation<any, any>({
      query: ({ tenantId, planId, planPriceId }) => {
        return {
          url: `/subscription/change-subscription`,
          method: "POST",
          body: {
            tenantId: tenantId,
            planId: planId,
            planPriceId: planPriceId,
          },
        };
      },
    }),

    createSubscriptionCustomer: builder.mutation<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/customer`,
          method: "POST",
          body: JSON.stringify(tenantId),
        };
      },
    }),

    getTenantAccessContext: builder.query<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/tenant/access-context/${tenantId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Subscription", id: "Subscriptions"}],
    }),

    getSubscriptions: builder.query<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/${tenantId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Subscription", id: "Subscriptions"}],
    }),

    getSubscriptionDetail: builder.query<any, any>({
      query: ({ tenantId, subscriptionId }) => {
        return {
          url: `/subscription?tenantId=${tenantId}&subscriptionId=${subscriptionId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Subscription", id: "Subscriptions"}],
    }),

    updatePayment: builder.query<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/update-payment-details`,
          method: "GET",
          params:{TenantId:tenantId,RedirectURL:redirectUrl}
        };
      },
      providesTags: [{ type: "Subscription", id: "Subscriptions"}],
    }),

    checkPaymentDetailsStatus: builder.query<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/payment-details-status/${tenantId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "SubscriptionStatus", id: "SubscriptionStatus"}],
    }),

    getPaymentDetailsStatus: builder.mutation<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/payment-details-status/${tenantId}`,
          method: "GET",
        };
      },

    }),
    createTempPayment: builder.mutation<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/tenant/temp-payment/${tenantId}`,
          method: "PUT",
        };
      },
    }),

    verifyPaymentDetailsStatus: builder.mutation<any, any>({
      query: (tenantId) => {
        return {
          url: `/subscription/payment-details-status/${tenantId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useChangeSubscriptionMutation,
  useCreateSubscriptionCustomerMutation,
  useCreateSubscriptionMutation,
  useGetSubscriptionDetailQuery,
  useLazyGetSubscriptionDetailQuery,
  useGetSubscriptionsQuery,
  useGetTenantAccessContextQuery,
  useLazyGetTenantAccessContextQuery,
  useUpdatePaymentQuery,
  useLazyCheckPaymentDetailsStatusQuery,
  useCheckPaymentDetailsStatusQuery,
  useVerifyPaymentDetailsStatusMutation,
  useGetPaymentDetailsStatusMutation,
  useCreateTempPaymentMutation,
} = subscriptionApiSlice;
