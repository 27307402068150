import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { holidayServer } from "@/config/server";

export const holidayApiSlice = createApi({
  reducerPath: "holidayApi",
  baseQuery: fetchBaseQuery({
    baseUrl: holidayServer,
  }),
  tagTypes: ["Holiday"],
  endpoints: (builder) => ({
    getHolidays: builder.query<any, any>({
      query: ({ country, year }) => {
        return {
          url: `/HolidayApi/holidays?country=${country}&year=${year}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Holiday", id: "HolidayId" }],
    }),
  }),
});