import { Spinner, Typography } from "@zigops-material/react";

interface SpinnerLoaderProps {
  text: string;
}

export function SpinnerLoader({ text }: SpinnerLoaderProps) {
  return (
    <div className="flex min-h-screen w-full place-content-center justify-center">
      <div className="m-auto min-h-min w-2/4 place-content-center text-center">
        <Spinner className="m-auto mb-0 h-8 w-8" color="amber" />
        <Typography>{text}</Typography>
      </div>
    </div>
  );
}