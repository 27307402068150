// Importing necessary modules and components
import React, { useEffect, useState } from "react";
import { Stepper, Step, Button, Typography } from "@zigops-material/react";

import { useDispatch} from "react-redux"; // Importing Redux hooks for dispatch and selector

import classNames from "classnames";

import { ToastContainer} from "react-toastify";

import { useRouter } from "next/router";
import FundingSourceOptionView from "./FundingSourceOptionView";
import AccountDetailsView from "./AccountDetailsView";
import FundingSourceCompleteView from "./FundingCompleteView";

// Interface defining the props for the stepper component
interface stepperProps {
  parentIndex: number;
  childIndex: number;
  parentId: string;
  childId: string;
  isError: boolean;
  errorMsg: string;
}

// Functional component for employee
const PayrollFunding = () => {
  // State variables
  const [activeStep, setActiveStep] = useState(0); // State for active step in the main stepper
  const [activeChildStep, setActiveChildStep] = useState<stepperProps>({
    parentIndex: 0,
    childIndex: 0,
    parentId: "",
    childId: "",
    isError: false,
    errorMsg: "",
  }); // State for active step in the nested stepper
  const [isProcessing, setIsProcessing] = useState(false); // State to track if it's the last step
  const [isLastStep, setIsLastStep] = useState(false); // State to track if it's the last step
  const [isFirstStep, setIsFirstStep] = useState(false); // State to track if it's the first step

  // Array of views representing different steps in the form
  const views = [
    {
      id: "FundingSource",
      children: [
        { childId: "FundingOptionsView", view: <FundingSourceOptionView /> },
      ],
    },
    {
      id: "PaymentInstructions",
      children: [ { childId: "AccountDetailsView", view: <AccountDetailsView /> },],
    },
    {
      id: "ProofOfPayment",
      children: [{ childId: "CompleteFundingSourceView", view: <FundingSourceCompleteView /> },],
    },
  ];

  const router = useRouter();
  const dispatch = useDispatch();

  // Function to handle moving to the next step
  const handleNextStep = () => {
    // handle validation here
    let isValid = true; // Assume the form is valid by default

    if (isValid) {
      // Proceed to the next step only if isValid is true
      const currentParentStep = activeStep;
      const currentChildStep = activeChildStep.childIndex;

      if (currentChildStep < views[currentParentStep].children.length - 1) {
        setActiveChildStep((prevState) => ({
          ...prevState,
          childIndex: prevState.childIndex + 1,
        }));
      } else {
        if (currentParentStep < views.length - 1) {
          setActiveStep(currentParentStep + 1);
          setActiveChildStep({
            parentIndex: currentParentStep + 1,
            childIndex: 0,
            parentId: "",
            childId: "",
            isError: false,
            errorMsg: "",
          });
        }
      }

      setIsFirstStep(false); // Reset isFirstStep

      setIsLastStep(
        currentParentStep === views.length - 1 &&
          currentChildStep === views[currentParentStep].children.length - 1,
      ); // Checking if it's the last step
    }
  };

  // Function to handle moving back to the previous step
  const handleBack = () => {
    if (activeChildStep.childIndex > 0) {
      setActiveChildStep((prevState) => ({
        ...prevState,
        childIndex: prevState.childIndex - 1,
      })); // Updating active child step index
    } else {
      if (activeStep > 0) {
        setActiveStep(activeStep - 1); // Moving to the previous parent step
        const newParentIndex = activeStep - 1;
        setActiveChildStep({
          parentIndex: newParentIndex,
          childIndex: views[newParentIndex].children.length - 1,
          parentId: "",
          childId: "",
          isError: false,
          errorMsg: "",
        }); // Resetting active child step
      }
    }

    setIsLastStep(false); // Reset isLastStep
    setIsFirstStep(activeStep === 0 && activeChildStep.childIndex === 1); // Checking if it's the first step
  };

  useEffect(() => {
    // Check if the current activeStep is 0 and the childIndex has reached the end
    if (activeStep === 0 && activeChildStep.childIndex === views[activeStep].children.length) {
      // Set activeStep to 1
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, activeChildStep.childIndex, views]);

  // Function to handle form submission
  const onSubmit = async () => {
    setIsProcessing(true);
  };

  return (
    <>
      <div className="w-full animate-fade-left p-5 animate-once">
        <div className="border-b border-b-gray-400 pb-4 dark:border-b-gray-750">
          Payroll Funding
        </div>
      </div>
      <div className="w-full">
        <div className="pt-2 xl-max-screen:p-10">
          <div className="h-[65vh] w-[100%]">
            <div className="w-[70%] pl-5 pr-12">
              {/* Stepper component to display progress */}
              <Stepper activeStep={activeStep}>
                {/* Step 1 */}
                <Step onClick={() => setActiveStep(0)}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.828 13.243a.5.5 0 0 0-.117.207l-2 5a.5.5 0 0 0 .665.664l5-2a.5.5 0 0 0 .206-.116l9.5-9.5a.5.5 0 0 0 0-.707l-2.586-2.586a.5.5 0 0 0-.707 0l-9.5 9.5zM4.414 14.657l7.086-7.086 1.586 1.586-7.086 7.086-1.586-1.586zm-1.172 4.172l-.5 1.5 1.5-.5 9.5-9.5-1-1L12.828 6.414l-1.414 1.414 1 1L6.828 17.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div
                    className={classNames(
                      "absolute w-max bg-gray-200 pl-2 pr-2 text-center dark:bg-gray-950 bg-[#ffffff]",
                      activeStep === 0 ? "left-12" : "left-10",
                    )}
                  >
                    <Typography
                      className={classNames(
                        activeStep === 0 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                      )}
                    >
                      Funding source
                    </Typography>
                  </div>
                </Step>
                {/* Step 2 */}
                <Step onClick={() => setActiveStep(1)}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.828 13.243a.5.5 0 0 0-.117.207l-2 5a.5.5 0 0 0 .665.664l5-2a.5.5 0 0 0 .206-.116l9.5-9.5a.5.5 0 0 0 0-.707l-2.586-2.586a.5.5 0 0 0-.707 0l-9.5 9.5zM4.414 14.657l7.086-7.086 1.586 1.586-7.086 7.086-1.586-1.586zm-1.172 4.172l-.5 1.5 1.5-.5 9.5-9.5-1-1L12.828 6.414l-1.414 1.414 1 1L6.828 17.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div
                    className={classNames(
                      "absolute w-max bg-gray-200 pl-2 pr-2 text-center dark:bg-gray-950 bg-[#ffffff]",
                      activeStep === 2 ? "left-12" : "left-10",
                    )}
                  >
                    <Typography
                      className={classNames(
                        activeStep === 1 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                      )}
                    >
                      Account details
                    </Typography>
                  </div>
                </Step>
                {/* Step 3 */}
                <Step onClick={() => setActiveStep(2)}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.828 13.243a.5.5 0 0 0-.117.207l-2 5a.5.5 0 0 0 .665.664l5-2a.5.5 0 0 0 .206-.116l9.5-9.5a.5.5 0 0 0 0-.707l-2.586-2.586a.5.5 0 0 0-.707 0l-9.5 9.5zM4.414 14.657l7.086-7.086 1.586 1.586-7.086 7.086-1.586-1.586zm-1.172 4.172l-.5 1.5 1.5-.5 9.5-9.5-1-1L12.828 6.414l-1.414 1.414 1 1L6.828 17.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div
                    className={classNames(
                      "absolute left-12 w-max bg-gray-200 pl-2 pr-2 text-center dark:bg-gray-950 bg-[#ffffff]",
                    )}
                  >
                    <Typography
                      className={classNames(
                        activeStep === 2 ? "text-blue-500" : "text-gray-800 dark:text-gray-150 ",
                      )}
                    >
                      Email proof of payment
                    </Typography>
                  </div>
                </Step>
              </Stepper>
            </div>
            {/* Displaying the current step view */}
            {views[activeStep].children[activeChildStep.childIndex].view}
          </div>
          <div className=" absolute bottom-6 flex w-[50%] justify-between xl-max-screen:bottom-14 ">
            <div className="flex">
              {/* Back button */}
              <Button variant="text" onClick={handleBack} disabled={isFirstStep}>
                Back
              </Button>
              {/* Conditional rendering of Next/Create Payroll button */}
              {isLastStep ? (
                <div>
                  <Button disabled={isProcessing} className="ml-10" onClick={() => onSubmit()}>
                    Complete
                  </Button>
                </div>
              ) : (
                <Button
                  className={classNames("ml-10")}
                  onClick={handleNextStep}
                  disabled={isLastStep}
                >
                  Next
                </Button>
              )}
            </div>
            {/* Displaying current step information */}
            <div className="mr-5 text-gray-800  dark:text-gray-100">
              {activeChildStep.childIndex + 1} / {views[activeStep].children.length}
            </div>
          </div>
        </div>

        <ToastContainer
          theme="dark"
          position="bottom-center"
          autoClose={7000}
          hideProgressBar={true}
        />
      </div>
    </>
  );
};

export default PayrollFunding;
