import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TriggerState {
  month: string;
  startDate: string;
  endDate: string;
}

// Initialize the trigger state
const initialState: TriggerState = {
  month: "",
  startDate: "currentMonth",
  endDate: "currentMonth",
};

export const leaveCalendarStringSlice = createSlice({
  name: "leaveCalendarString",
  initialState,
  reducers: {
    setMonth: (
      state: {
        month: string;
      },
      action: PayloadAction<any>,
    ) => {
      state.month = action.payload.month;
    },
    setStartAndEndDates: (
      state: {
        month: string;
        startDate: string;
        endDate: string;
      },
      action: PayloadAction<any>,
    ) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMonth, setStartAndEndDates } = leaveCalendarStringSlice.actions;

export default leaveCalendarStringSlice.reducer;
