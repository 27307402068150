// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formDataState {
  planId: string;
  planPriceId: string;
  planName: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  planId: "",
  planPriceId: "",
  planName: "",
};

// Creating a Redux slice for managing Employee form data
export const SubscriptionCreationDataSlice = createSlice({
  name: "Subscription-creation-data",
  initialState,
  reducers: {
    // Reducer to set Plan Id
    setSubcriptionCreationPlanId: (state: formDataState, action: PayloadAction<any>) => {
      state.planId = action.payload.planId;
    },

    // Reducer to set Plan Price Id
    setSubsriptionCreationPlanPriceId: (state: formDataState, action: PayloadAction<any>) => {
      state.planPriceId = action.payload.planPriceId;
    },

    // Reducer to set Plan Price Id
    setSubsriptionCreationPlanName: (state: formDataState, action: PayloadAction<any>) => {
      state.planName = action.payload.planName;
    },

    // Reducer to set reset the form data state
    reSetSubsriptionCreation: (state: formDataState, action: PayloadAction<any>) => {
      state.planId = "";
      state.planPriceId = "";
    },
  },
});

// Exporting action creators and reducer
export const {
  setSubcriptionCreationPlanId,
  setSubsriptionCreationPlanPriceId,
  setSubsriptionCreationPlanName,
  reSetSubsriptionCreation,
} = SubscriptionCreationDataSlice.actions;

export default SubscriptionCreationDataSlice.reducer;
