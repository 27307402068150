
import React from "react";

function BillingHistory() {
  return (
    <div className="w-full animate-fade-left p-5 animate-once">
      <div className="border-b border-b-gray-400 pb-4 dark:border-b-gray-750">
        BillingsAndSubscription
      </div>
    </div>
  );
}

export default BillingHistory;
