import {
  useCheckPaymentDetailsStatusQuery,
  useCreateSubscriptionMutation,
  useUpdatePaymentQuery,
  useVerifyPaymentDetailsStatusMutation,
} from "@/apis/subscriptionApiSlice";
import IFrame from "@/components/Iframe";
import { Button } from "@zigops-material/react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { useEffect, useState } from "react";

const UpdateCardDetails: React.FC = () => {
  const [cardDetailsStatus, setCardDetailsStatus] = useState<string>("");
  const [completUpdate, setCompleteUpdate] = useState<boolean>(false);
  const { TenantId } = useSelector((state: RootState) => state.app_reducer.companyInfo);

  const {
    data: paymentStatus,
    isLoading: isLoadingPaymentStatus,
    isError: isErrorPaymentStatus,
  } = useCheckPaymentDetailsStatusQuery(TenantId);

  const [
    verifyCustomerCardMutation,
    { data: verifyCardData, isLoading: isLoadingVerifyCard, isError: isErrorVerify },
  ] = useVerifyPaymentDetailsStatusMutation();

  const verifyCustomerCardDetails = async () => {
    await verifyCustomerCardMutation(TenantId).unwrap();
  };

  const {
    data: payLink,
    isLoading: isLoadingPayLink,
    isError: isErrorPayLink,
  } = useUpdatePaymentQuery(TenantId);

  useEffect(() => {
    setCardDetailsStatus(verifyCardData);
  }, [verifyCardData]);

  const handleCompleteUpdate = () => {
    setCompleteUpdate(true);
  };

  useEffect(() => {}, [completUpdate]);

  useEffect(() => {
    setCardDetailsStatus(paymentStatus);
  }, [paymentStatus]);

  return (
    <>
      {completUpdate ? (
        <>
          <div>
            <div className="relative ml-10 flex flex-col items-center justify-start gap-[9px] pt-10 mt-10">
              <div className="relative flex shrink-0 flex-col items-center justify-start gap-[15px]">
                <div className="relative text-left font-['Montserrat-Medium',_sans-serif] text-[22px] font-medium leading-6 text-[#000000]">
                  Done. Yippee!!!{" "}
                </div>
              </div>
              <img
                className="relative h-[257px] w-[313px] shrink-0 overflow-hidden"
                style={{ objectFit: "cover" }}
                src="/img/happy_people.png"
              />
            </div>
          </div>
        </>
      ) : (
        <div >
          <div className="flex max-h-screen w-full flex-col items-center justify-center mt-10 overflow-auto">
            <div>
              <>
                <IFrame
                  src={payLink?.data.paymentLink || ""}
                  title="ZigOps"
                  width="100%"
                  height="550"
                />
              </>
            </div>
            
              <Button className="mt-5 w-[30%]" onClick={handleCompleteUpdate}>
                Proceed
              </Button>
            
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateCardDetails;
