import React, { useEffect, useRef, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { signalrBaseURL } from "@/config/server";
import { Notify, restTrigger } from "@/redux/features/notifications/triggerNotification";
import { setNotificationState } from "@/redux/features/notifications";
import { useLazyGetUnreadCountQuery } from "@/apis/notificationsApiSlice";

const NotificationView = () => {
  const { tenantId, token } = useSelector((state: RootState) => state.app_reducer.headers);
  const { summarizedMessage, createdAt } = useSelector(
    (state: RootState) => state.app_reducer.triggerNotification,
  );

  const { employeeId } = useSelector((state: RootState) => state.app_reducer.activeEmployee);
  const { unreadCount: currentUnreadNotificationsCount } = useSelector((state: RootState) => state.app_reducer.notificationState);
  const [getUnreadNotificationsCount] = useLazyGetUnreadCountQuery();
  const currentUnreadNotificationsCountRef = useRef(currentUnreadNotificationsCount);

  useEffect(() => {
    currentUnreadNotificationsCountRef.current = currentUnreadNotificationsCount;
  }, [currentUnreadNotificationsCount]);

  const dispatch = useDispatch();

  const [isBrowserTabActive, setIsBrowserTabActive] = useState<boolean>(true);

  const connection = new HubConnectionBuilder()
    .withUrl(signalrBaseURL, {
      headers: {
        Authorization: `Bearer ${token}`,
        Tenant: tenantId,
      },
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

  connection.on("onMessageReceived", (data) => {
    dispatch(Notify(data));
    dispatch(setNotificationState({ unreadCount: currentUnreadNotificationsCountRef.current + 1 }));
  });

  async function start() {
    try {
      await connection.start();
    } catch (err) {
    }
  }

  useEffect(() => {
    if (!("Notification" in window)) {
      toast.error("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    getUnreadNotificationsCount(employeeId)
      .then((response) => {
        // @ts-ignore
        if (response.isSuccess) {
          // @ts-ignore
          dispatch(setNotificationState({ unreadCount: response.data.unreadCount }));
        }
        else {
          dispatch(setNotificationState({ unreadCount: 0 }));
        }
      })
  }, []);

  useEffect(() => {
    start();
  }, [connection.state]);

  const handleBrowserTabFocus = () => {
    setIsBrowserTabActive(true);
  };

  const handleBrowserTabBlur = () => {
    setIsBrowserTabActive(false);
  };

  useEffect(() => {
    window.addEventListener("blur", handleBrowserTabBlur);
    window.addEventListener("focus", handleBrowserTabFocus);

    return () => {
      window.removeEventListener("blur", handleBrowserTabBlur);
      window.removeEventListener("focus", handleBrowserTabFocus);
    };
  }, []);

  useEffect(() => {
    if (summarizedMessage) {
      if (isBrowserTabActive) {
        toast.success(`${summarizedMessage}`, {
          duration: 16000,
        });
      } else {
        showNotification();
      }
    }
  }, [createdAt]);

  const showNotification = () => {
    const notification = new Notification("Notification ", {
      body: summarizedMessage,
      icon: "/img/subscribe.jpg",
      dir: "ltr",
    });

    notification.onclose = () => {
      dispatch(restTrigger([]));
    };
  };

  return (
    <>
      <Toaster
        containerClassName=""
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          position: "top-right"
        }}
      />
    </>
  );
};

export default NotificationView;
