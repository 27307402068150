import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";

export const PlansApiSlice = createApi({
  reducerPath: "PlansApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["Plans", "PlanPrices"],
  endpoints: (builder) => ({
    getPlans: builder.query<any, any>({
      query: () => {
        return {
          url: `plan`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Plans", id: "plans" }],
    }),
    listPlans: builder.query<any, any>({
      query: (currency) => {
        return {
          url: `plan-price/plans/${currency}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Plans", id: "plans" }],
    }),
    getFeatures: builder.query<any, any>({
      query: () => {
        return {
          url: `feature`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Plans", id: "plans" }],
    }),

    getPlanDetails: builder.query<any, any>({
      query: ({ planId, currency }) => {
        return {
          url: `plan-price/plans/${planId}/${currency}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Plans", id: "plansDetails" }],
    }),

    getPlanPrices: builder.mutation<any, any>({
      query: (planId) => {
        return {
          url: `plan-price/prices/${planId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetPlansQuery,
  useGetPlanPricesMutation,
  useLazyGetPlanDetailsQuery,
  useLazyGetFeaturesQuery,
  useListPlansQuery,
  useGetFeaturesQuery,
  useGetPlanDetailsQuery
} = PlansApiSlice;
