// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
export interface IPlanState {
  planId: string;
  description: string;
  planAppId: string;
  name: string;
}

// Initialize the initial state of the form data
const initialState: IPlanState = {
  planId: "",
  description: "",
  planAppId: "",
  name: "",
};

// Creating a Redux slice for managing Employee form data
export const TenantPlanDetailsDataSlice = createSlice({
  name: "Tenant-plan-details-data",
  initialState,
  reducers: {
    // Reducer to set Plan Id
    setTenantPlanDetails: (state: IPlanState, action: PayloadAction<IPlanState>) => {
      state.description = action.payload.description;
      state.planAppId = action.payload.planAppId;
      state.name = action.payload.name;
      state.planId = action.payload.planId;
    },
  },
});

// Exporting action creators and reducer
export const { setTenantPlanDetails } = TenantPlanDetailsDataSlice.actions;

export default TenantPlanDetailsDataSlice.reducer;
