import React, { useEffect, useState } from "react";
import { Stepper, Step, Button, Typography } from "@zigops-material/react";
import Head from "next/head";

import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { ToastContainer, toast } from "react-toastify";

import EmployerFullName from "./EmployerName";
import EmployerEmail from "./EmployerEmail";
import EmployerPhoneNumber from "./EmployerPhoneNumber";
import UploadNAPSA from "./UploadNAPSA";
import UploadNHIMA from "./UploadNHIMA";
import UploadTPINCertificate from "./UploadTPINCertificate";
import {
  setHouseholdEmployerEmailError,
  setHouseholdEmployerFullNameError,
  setHouseholdEmployerNapsaUrlError,
} from "@/redux/features/household/household-employer-tax-details-errors";
import { EMAIL_REGEXP } from "@/constants/common.constants";
import { setHouseholdEmployerPhoneNumber } from "@/redux/features/household/household-employer-tax-details";
import { useSubmitDomesticTaxCollectionMutation } from "@/apis/uploadApiSlice";
import { setType } from "@/redux/actions/stringTrigger";
import { RootState } from "@/redux/configureStore";
import { useWindowSize } from "rooks";

// Interface defining the props for the stepper component
interface stepperProps {
  parentIndex: number;
  childIndex: number;
  parentId: string;
  childId: string;
  isError: boolean;
  errorMsg: string;
}

const TaxInformation = () => {
  const { outerHeight } = useWindowSize();
  const [activeStep, setActiveStep] = useState(0); // State for active step in the main stepper
  const [activeChildStep, setActiveChildStep] = useState<stepperProps>({
    parentIndex: 0,
    childIndex: 0,
    parentId: "",
    childId: "",
    isError: false,
    errorMsg: "",
  }); // State for active step in the nested stepper
  const [isProcessing, setIsProcessing] = useState(false); // State to track if it's the last step
  const [isLastStep, setIsLastStep] = useState(false); // State to track if it's the last step
  const [isFirstStep, setIsFirstStep] = useState(false); // State to track if it's the first step

  const [SubmitDomesticTaxCollection, { isLoading, isSuccess, isError, error, status }] =
    useSubmitDomesticTaxCollectionMutation();

  // Array of views representing different steps in the form
  const views = [
    {
      id: "EmployerDetails",
      children: [
        { childId: "EmployerFullName", view: <EmployerFullName /> },
        { childId: "EmployerEmail", view: <EmployerEmail /> },
        { childId: "EmployerPhoneNumber", view: <EmployerPhoneNumber /> },
      ],
    },
    {
      id: "UploadNAPSA",
      children: [{ childId: "UploadNAPSA", view: <UploadNAPSA /> }],
    },
    {
      id: "UploadNHIMA",
      children: [{ childId: "UploadNHIMA", view: <UploadNHIMA /> }],
    },
    {
      id: "UploadTPINCertificate",
      children: [{ childId: "UploadTPINCertificate", view: <UploadTPINCertificate /> }],
    },
  ];

  const {
    employerFullName,
    employerEmail,
    employerPhoneNumber,
    employerNapsaUrl,
    employerNhimaUrl,
    employerTpinUrl,
  } = useSelector((state: RootState) => state.app_reducer.employerTaxDetailsData);

  const dispatch = useDispatch();

  function isValidEmail(email: string): boolean {
    // Define a regular expression pattern for a basic email format
    const emailPattern: RegExp = EMAIL_REGEXP;

    // Check if the email matches the pattern
    if (!emailPattern.test(email)) {
      return false;
    }

    return true;
  }

  const handleNextStep = () => {
    let isValid = true;

    if (activeChildStep.childIndex === 0) {
      if (employerFullName === "") {
        dispatch(
          setHouseholdEmployerFullNameError({
            employerFullNameError: "Your full names are required",
          }),
        );
        isValid = false;
      }
    } else if (activeChildStep.childIndex === 1) {
      if (employerEmail === "") {
        dispatch(setHouseholdEmployerEmailError({ employerEmailError: "Your email is required" }));
        isValid = false;
      } else if (!isValidEmail(employerEmail)) {
        dispatch(
          setHouseholdEmployerEmailError({
            employerEmailError: `The email address "${employerEmail}" is not valid.`,
          }),
        );
        isValid = false;
      }
    } else if (activeChildStep.childIndex === 2) {
      if (employerPhoneNumber === "") {
        dispatch(
          setHouseholdEmployerPhoneNumber({
            employerPhoneNumber: "Your phone number required",
          }),
        );
        isValid = false;
      }
    }

    if (isValid) {
      const currentParentStep = activeStep;
      const currentChildStep = activeChildStep.childIndex;

      if (currentChildStep < views[currentParentStep].children.length - 1) {
        setActiveChildStep((prevState) => ({
          ...prevState,
          childIndex: prevState.childIndex + 1,
        }));
      } else {
        if (currentParentStep < views.length - 1) {
          setActiveStep(currentParentStep + 1);
          setActiveChildStep({
            parentIndex: currentParentStep + 1,
            childIndex: 0,
            parentId: "",
            childId: "",
            isError: false,
            errorMsg: "",
          });
        }
      }

      setIsFirstStep(false); // Reset isFirstStep

      setIsLastStep(
        currentParentStep === views.length - 1 &&
          currentChildStep === views[currentParentStep].children.length - 1,
      ); // Checking if it's the last step
    }
  };

  // Function to handle moving back to the previous step
  const handleBack = () => {
    if (activeChildStep.childIndex > 0) {
      setActiveChildStep((prevState) => ({
        ...prevState,
        childIndex: prevState.childIndex - 1,
      })); // Updating active child step index
    } else {
      if (activeStep > 0) {
        setActiveStep(activeStep - 1); // Moving to the previous parent step
        const newParentIndex = activeStep - 1;
        setActiveChildStep({
          parentIndex: newParentIndex,
          childIndex: views[newParentIndex].children.length - 1,
          parentId: "",
          childId: "",
          isError: false,
          errorMsg: "",
        }); // Resetting active child step
      }
    }

    setIsLastStep(false); // Reset isLastStep
    setIsFirstStep(activeStep === 0 && activeChildStep.childIndex === 1);
  };

  useEffect(() => {
    // Check if the current activeStep is 0 and the childIndex has reached the end
    if (activeStep === 0 && activeChildStep.childIndex === views[activeStep].children.length) {
      // Set activeStep to increment current step by 1
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, activeChildStep.childIndex, views]);

  // Function to handle form submission
  const onSubmit = async () => {
    const form = {
      fullName: employerFullName,
      email: employerEmail,
      phone: employerPhoneNumber,
      nhimaFileUrl: employerNapsaUrl.fileURL,
      napsaFileUrl: employerNhimaUrl.fileURL,
      tpinCertificateFileUrl: employerTpinUrl.fileURL,
    };

    try {
      await toast.promise(
        async () => {
          const res = await SubmitDomesticTaxCollection(form);
        },
        {
          pending: "Submitting your information...",
          success: `Submitted your information successfully`,
          error: `Error saving your information`,
        },
      );
    } catch (error) {
      dispatch(setType({ type: "domestic_tax_collection_information_error" }));
    } finally {
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };

  return (
    <div className="bg-gray-15 h-[100vh] w-full dark:bg-gray-950">
      <Head>
        <title>Tax Details - ZigOps Payroll</title>
      </Head>
      <div className="lg-max:h-20 lg-max:pt-5 flex h-14 w-full justify-between bg-orange-50/50 p-5 pl-7 pr-7 pt-3 dark:bg-transparent">
        <div className="flex ">
          <img
            className="-ml-2 h-[40px] max-h-[40px] w-[40px] max-w-[40px]"
            src="/img/logo-ct-dark.png"
            alt="brand"
          />
          <Typography className=" ml-5 mt-2 font-bold text-gray-800 dark:text-gray-300">
            Submit your tax information
          </Typography>
        </div>
      </div>

      <div className="w-full overflow-hidden lg:px-12">
        <div className="flex flex-col items-center pt-2 xl-max-screen:p-10">
          <div className="w-[95%] p-10 md:-ml-44 md:w-[70%] lg:-ml-44 lg:w-[70%] xl-max-screen:w-[70%]">
            {/* Stepper component to display progress */}
            <Stepper activeStep={activeStep}>
              {/* Step 1 */}
              <Step onClick={() => setActiveStep(0)}>
                <div>1</div>
                <div
                  className={classNames(
                    "absolute hidden w-max bg-gray-150 pl-2 pr-2 text-center dark:bg-gray-950 md:block lg:block",
                    activeStep === 0 ? "left-12" : "left-10",
                  )}
                >
                  <Typography
                    variant="h6"
                    className={classNames(
                      "text-sm md:text-lg lg:text-lg",
                      activeStep === 0 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Basic Information
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={classNames(
                      "absolute -ml-12 mt-3 w-80 text-sm md:hidden lg:hidden",
                      activeStep === 0
                        ? "text-blue-500"
                        : "hidden text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Basic Information
                  </Typography>
                </div>
              </Step>
              {/* Step 2 */}
              <Step onClick={() => setActiveStep(1)}>
                <div>2</div>
                <div
                  className={classNames(
                    "absolute hidden w-max bg-gray-150 pl-2 pr-2 text-center dark:bg-gray-950 md:block lg:block",
                    activeStep === 1 ? "left-12" : "left-10",
                  )}
                >
                  <Typography
                    variant="h6"
                    className={classNames(
                      "text-sm md:text-lg lg:text-lg",
                      activeStep === 1 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload NAPSA
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={classNames(
                      "absolute -ml-12 mt-3 w-80 text-sm md:hidden lg:hidden",
                      activeStep === 1
                        ? "text-blue-500"
                        : "hidden text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload NAPSA
                  </Typography>
                </div>
              </Step>
              {/* Step 3 */}
              <Step onClick={() => setActiveStep(2)}>
                <div>3</div>
                <div
                  className={classNames(
                    "absolute hidden w-max bg-gray-150 pl-2 pr-2 text-center dark:bg-gray-950 md:block lg:block",
                    activeStep === 2 ? "left-12" : "left-10",
                  )}
                >
                  <Typography
                    variant="h6"
                    className={classNames(
                      "text-sm md:text-lg lg:text-lg",
                      activeStep === 2 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload NHIMA
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={classNames(
                      "absolute -ml-12 mt-3 w-80 text-sm md:hidden lg:hidden",
                      activeStep === 2
                        ? "text-blue-500"
                        : "hidden text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload NHIMA
                  </Typography>
                </div>
              </Step>
              {/* Step 4 */}
              <Step onClick={() => setActiveStep(3)}>
                <div>4</div>
                <div
                  className={classNames(
                    "absolute hidden w-max bg-gray-150 pl-2 pr-2 text-center dark:bg-gray-950 md:block lg:block",
                    activeStep === 3 ? "left-12" : "left-10",
                  )}
                >
                  <Typography
                    variant="h6"
                    className={classNames(
                      "text-sm md:text-lg lg:text-lg",
                      activeStep === 3 ? "text-blue-500" : "text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload TPIN Certificate
                  </Typography>
                </div>
                <div>
                  <Typography
                    className={classNames(
                      "absolute -ml-12 mt-3 w-80 text-sm md:hidden lg:hidden",
                      activeStep === 3
                        ? "text-blue-500"
                        : "hidden text-gray-800 dark:text-gray-150",
                    )}
                  >
                    Upload TPIN Certificate
                  </Typography>
                </div>
              </Step>
            </Stepper>
          </div>
          <div className="h-[57vh] w-[100%] overflow-hidden px-2 transition-all hover:overflow-auto md:w-[50%] md:p-5 md:px-10 lg:w-[50%] lg:px-2">
            {views[activeStep].children[activeChildStep.childIndex].view}
          </div>
          <div className=" absolute bottom-6 flex w-[100%] justify-between md:w-[50%] lg:w-[50%] xl-max-screen:bottom-14 ">
            <div className="flex">
              <Button variant="text" onClick={handleBack} disabled={isFirstStep}>
                Back
              </Button>
              {isLastStep ? (
                <div>
                  <Button disabled={isProcessing} className="ml-10" onClick={() => onSubmit()}>
                    Send information
                  </Button>
                </div>
              ) : (
                <Button
                  className={classNames("ml-10")}
                  onClick={handleNextStep}
                  disabled={isLastStep}
                >
                  Next
                </Button>
              )}
            </div>
            <div className="mr-5 text-gray-800  dark:text-gray-100">
              <span className="text-gray-600 dark:text-gray-500">Question </span>{" "}
              {activeChildStep.childIndex + 1} / {views[activeStep].children.length}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            `-mt-52 flex flex-wrap items-center justify-center gap-2 border-t border-t-gold-700 md:justify-between ${
              // @ts-ignore
              outerHeight > 900 ? "h-[50vh]" : "h-[30vh]"
            }`,
          )}
          style={{ backgroundImage: "url('/img/pastedimage-24d-400h.png')" }}
        ></div>
      </div>

      <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={7000}
        hideProgressBar={true}
      />
    </div>
  );
};

export default TaxInformation;

TaxInformation.getLayout = function PageLayout(page: any) {
  return <>{page}</>;
};
