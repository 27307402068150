import { useGetEmployeeByIdQuery } from "@/apis/EmployeesApiSlice";
import { RootState } from "@/redux/configureStore";
import { Button, IconButton, Switch, Typography } from "@zigops-material/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Functional component named Payroll
function Company() {
  // Set up state for DebitReminders using useState hook
  const { employeeId } = useSelector((state: RootState) => state.app_reducer.activeEmployee);
  const { data, isLoading, isSuccess } = useGetEmployeeByIdQuery(employeeId);

  return (
    <div className="w-full animate-fade-left p-5 animate-once">
      <div className="border-b border-b-gray-400 pb-4 dark:border-b-gray-750">Company</div>

      <div className="mt-2 h-[760px] w-full overflow-y-auto overflow-x-hidden pr-2">
        {/* Company payment account section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <h3 className="font-bold">Employer profile</h3>
            <p>Update or review your company’s payment information</p>
          </div>

          {/* Bank name */}
          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Full Name</h3>
            </div>
            <div>
              <p>
                {data?.firstName || ""} {data?.lastName || ""}
              </p>
            </div>
            <div>
              {/* Edit icon */}
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Phone Number</h3>
            </div>
            <div>
              <p>{data?.homeAddress?.mobileNumber || ""}</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        </section>

        {/* Employer address section */}
        <section className="mt-4 animate-fade-up rounded-md border border-gray-300 bg-white p-5 animate-delay-1000 animate-once dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <h3 className="font-bold">Address Information</h3>
            <p>Update or review your company’s address information</p>
          </div>

          {/* Physical address */}
          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Physical Address</h3>
            </div>
            <div>
              <p>{data?.homeAddress?.address || ""}</p>
            </div>
            <div>
              {/* Edit icon */}
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">City</h3>
            </div>
            <div>
              <p>{data?.homeAddress?.city || ""}</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Province</h3>
            </div>
            <div>
              <p>{data?.homeAddress?.state || ""}</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>

          <div className="mt-3 flex w-2/3 justify-between rounded-md border border-gray-300 bg-white p-2 dark:border-gray-750 dark:bg-gray-850 ">
            <div>
              <h3 className="text-lg">Postal Code</h3>
            </div>
            <div>
              <p>{data?.homeAddress?.street || ""}</p>
            </div>
            <div>
              <IconButton variant="text" size="sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Company;
