import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TriggerState {
    data_bus: any;
}

// Initialize the trigger state
const initialState: TriggerState = {
    data_bus: null,
};

export const payrollDataBusTriggerSlice = createSlice({
  name: "payrollDataBusTrigger",
  initialState,
  reducers: {
    setDataBus: (
      state: {
        data_bus: any;
      },
      action: PayloadAction<any>,
    ) => {
      state.data_bus = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDataBus } = payrollDataBusTriggerSlice.actions;

export default payrollDataBusTriggerSlice.reducer;
