import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface signalrConnectionState {
  connection: any;
}

// Initialize the notification state
const initialState: signalrConnectionState = {
  connection: null,
};

export const signalrConnection = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setSignalrConnection: (state: signalrConnectionState, action: PayloadAction<any>) => {
      state.connection = action.payload.connection;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSignalrConnection } = signalrConnection.actions;

export default signalrConnection.reducer;
