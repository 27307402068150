import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useStorage from "@/hooks/useStorage";
import { TENANT } from "@/constants/common.constants";

import { server } from "@/config/server";
import getHeaders from "./headers";

const { getItem } = useStorage();

const tenant_id = JSON.parse(getItem(TENANT));

export const OnBoardingApiSlice = createApi({
  reducerPath: "OnBoardingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["OnBoarding"],
  endpoints: (builder) => ({
    getUserTenants: builder.query<any, any>({
      query: (userId) => {
        return {
          url: `/usertenants`,
          method: "POST",
          body: {
            userId: userId,
          },
        };
      },
      providesTags: [{ type: "OnBoarding", id: "companies" }],
    }),
    getInvitedMembers: builder.query<any, any>({
      query: () => {
        return {
          url: `tenant/invitedmembers?tenantId=${tenant_id}&previousCursor=0&nextCursor=0&itemsPerPage=10&page=Next`,
          method: "GET",
        };
      },
      providesTags: [{ type: "OnBoarding", id: "invitedmembers" }],
    }),
    setInviteEmployee: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `/inviteuser`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: [{ type: "OnBoarding", id: "invitedmembers" }],
    }),
    getProjects: builder.query<any, any>({
      query: () => {
        return {
          url: `projects/${tenant_id}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "OnBoarding", id: "projects" }],
    }),
    createNewTenant: builder.mutation<any, any>({
      query: ({ ownerId, displayName }) => {
        return {
          url: `/tenants`,
          method: "POST",
          body: {
            ownerId: ownerId,
            displayName: displayName,
          },
        };
      },
    }),
  }),
});

export const {
  useGetUserTenantsQuery,
  useSetInviteEmployeeMutation,
  useGetInvitedMembersQuery,
  useGetProjectsQuery,
  useCreateNewTenantMutation,
} = OnBoardingApiSlice;
