import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";
import { ResponsePaginationCursor } from "@/types/Pagination";
import { Employee } from "./models/employee.model";

export const EmployeesApiSlice = createApi({
  reducerPath: "EmployeesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  refetchOnReconnect: true,
  tagTypes: ["Employees"],
  endpoints: (builder) => ({
    createDomesticEmployee: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/employees/domestic`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    getEmployees: builder.query<any, any>({
      query: ({ companyId, previousCursor, nextCursor, itemsPerPage, page }) => {
        return {
          url: `companies/${companyId}/employees?previousCursor=${previousCursor}&nextCursor=${nextCursor}&itemsPerPage=${itemsPerPage}&page=next`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    getEmployeeById: builder.query<any, string>({
      query: (employeeId) => {
        return {
          url: `employees/${employeeId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    getEmployeesByIds: builder.query<ResponsePaginationCursor<Employee>, any>({
      query: ({ employeeIds, nextCursor, previousCursor, itemsPerPage, page }) => {
        return {
          url: `employees/get-by-ids?nextCursor=${nextCursor}&previousCursor=${previousCursor}&itemsPerPage=${itemsPerPage}&page=${page}`,
          method: "POST",
          body: employeeIds
        }
      }
    }),
    addEmployee: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "addEmployee" }],
    }),
    updateEmployeeBasicInfo: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees/update-basic-info`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    updateEmployeePhysicalAddress: builder.mutation<any, any>({
      query: (employeeInfo) => {
        return {
          url: `employees/update-home-address`,
          method: "POST",
          body: employeeInfo,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    updateEmployeeHomeAddress: builder.mutation<any, any>({
      query: (homeInfo) => {
        return {
          url: `employees/home-address/${homeInfo.employeeId}`,
          method: "PUT",
          body: homeInfo,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),

    updateEmployeePensionDetails: builder.mutation<any, any>({
      query: (info) => {
        return {
          url: `employees/pension-details/${info.employeeId}`,
          method: "PUT",
          body: info,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),

    updateEmployeeTpinAndNRC: builder.mutation<any, any>({
      query: (info) => {
        return {
          url: `employees/tax-id/${info.employeeId}`,
          method: "PUT",
          body: info.data,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    updateEmployee: builder.mutation<any, Employee>({
      query: (employee: Employee) => {
        return {
          url: "employees",
          method: "PUT",
          body: employee
        }
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }]
    }),
    updateEmployeeBankPaymentDetails: builder.mutation<any, any>({
      query: (bankPaymentDetails) => {
        return {
          url: `employees/update-bank-payment-details`,
          method: "POST",
          body: bankPaymentDetails,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),
    updateEmployeeMobileMoneyPaymentDetails: builder.mutation<any, any>({
      query: (mobileMoneyPaymentDetails) => {
        return {
          url: `employees/update-mobile-money-payment-details`,
          method: "POST",
          body: mobileMoneyPaymentDetails,
        };
      },
      invalidatesTags: [{ type: "Employees", id: "employeeId" }],
    }),

    ///Adds a new employee
    createNewEmployee: builder.mutation<any, any>({
      query: (employeeDetails) => {
        return {
          url: `/employees`,
          method: "POST",
          body: employeeDetails,
        };
      },
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeByIdQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeBasicInfoMutation,
  useUpdateEmployeePhysicalAddressMutation,
  useCreateDomesticEmployeeMutation,
  useGetEmployeesByIdsQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeHomeAddressMutation,
  useUpdateEmployeePensionDetailsMutation,
  useUpdateEmployeeTpinAndNRCMutation,
  useUpdateEmployeeMutation,
  useUpdateEmployeeBankPaymentDetailsMutation,
  useUpdateEmployeeMobileMoneyPaymentDetailsMutation,
  useCreateNewEmployeeMutation,
} = EmployeesApiSlice;
