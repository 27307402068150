// Importing necessary modules and components from external libraries
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
  Checkbox,
  Badge,
  Dialog,
  DialogBody,
} from "@zigops-material/react"; // Importing UI components from a library
import {
  CubeTransparentIcon,
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  RocketLaunchIcon,
  Bars2Icon,
  BellIcon,
  Cog8ToothIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline"; // Importing icons from Heroicons library
import toast from "react-hot-toast";
import { logout } from "@/utils/authService"; // Importing a utility function for logging out
import { useMsal } from "@azure/msal-react"; // Importing MSAL (Microsoft Authentication Library) hook
import { userLoggedOut } from "@/redux/features/auth/user"; // Importing action for user logout
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import { removeCompany } from "@/redux/features/company"; // Importing Redux action for removing company info
import {
  getCompanyInfo,
  removeActiveCompany,
  removeCompanyInfo,
  saveCompany,
} from "@/redux/actions/companyActions"; // Importing Redux actions related to company information
import {
  COMPANY_INFO,
  CompanyInterface,
  CompanyTempInterface,
} from "@/constants/companysetup.constants"; // Importing constants related to company setup
import { RootState } from "@/redux/configureStore"; // Importing the root state of the Redux store
import { useRouter } from "next/router"; // Importing Next.js router
import useTokenExpiryNotifier from "@/hooks/useTokenExpiryNotifier";
import AddNewCompany from "@/pages/admin/add-company";

// Defining prop types for the Header component
interface Props {
  setMobileNav: Dispatch<SetStateAction<boolean>>;
  changeMobileNavToggleState: Dispatch<SetStateAction<boolean>>;
}

// Defining prop types for the menu items in the profile menu
interface menuProps {
  label: string;
  type: string;
  description: string | undefined;
  icon: any;
}

// ProfileMenu component for displaying user profile-related options
function ProfileMenu() {
  // State to manage the visibility of the menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to close the menu
  const closeMenu = () => setIsMenuOpen(false);

  // Extracting user information from Redux state
  const { firstName, lastName, email } = useSelector(
    (state: RootState) => state.app_reducer.activeEmployee,
  );

  // Using MSAL hook to get authentication instance and accounts
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch(); // Redux dispatch function

  // State to manage profile menu items
  const [profileMenuItems, setProfileMenuItems] = useState<menuProps[]>([]);

  // useEffect to set up initial profile menu items
  useEffect(() => {
    setProfileMenuItems([
      {
        label: `${firstName} ${lastName}`,
        type: "info",
        description: email,
        icon: Cog8ToothIcon,
      },
      {
        label: "Settings",
        type: "menu",
        description: "",
        icon: Cog8ToothIcon,
      },
      {
        label: "Sign Out",
        type: "sign_out",
        description: "",
        icon: ArrowRightOnRectangleIcon,
      },
    ]);
  }, []);

  // Function to handle user sign out
  const handleSignOut = async () => {
    if (accounts[0]) await logout(instance, accounts[0].homeAccountId);
    dispatch(userLoggedOut({}));
    dispatch(removeCompany({}));
    removeCompanyInfo(COMPANY_INFO, "all");
  };

  // Function to handle menu item clicks
  const handleMenuClick = (type: string) => {
    switch (type) {
      case "info":
        closeMenu();
        break;
      case "":
        closeMenu();
        break;
      case "sign_out":
        closeMenu();
        handleSignOut();
        break;
      default:
        break;
    }
  };

  // JSX for rendering the ProfileMenu component
  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 pl-0.5 pr-2 lg:ml-auto"
          >
            {/* User avatar */}
            <Avatar
              variant="circular"
              size="sm"
              alt={firstName}
              className="border border-blue-500 p-0.5"
              src="/img/20ww.jpg"
              onError={(e) => {
                e.currentTarget.src = "/img/default_avatar.png";
              }}
            />
            {/* Dropdown icon */}
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
            />
          </Button>
        </MenuHandler>

        {/* Menu items */}
        <MenuList className="w-70 p-1">
          {profileMenuItems.map(({ label, icon, type, description }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return type === "info" ? (
              // Special case for 'info' type menu item
              <div className="mb-2 border-none p-3">
                <div className="mb-3 pr-5">
                  <h5 className="font-medium">{label}</h5>
                  <div className="text-gray-400">{description}</div>
                </div>
                <div>
                  <Button fullWidth className="bg-amber-800">
                    Switch Profile
                  </Button>
                </div>
              </div>
            ) : (
              // Normal menu item
              <MenuItem
                key={label}
                onClick={() => handleMenuClick(type)}
                className={`flex items-center gap-2 rounded ${
                  isLastItem ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10" : ""
                }`}
              >
                {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? "red" : "inherit"}
                >
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}

// Menu items for the navigation list
const navListMenuItems = [
  {
    title: "@zigops-material/html",
    description: "Learn how to use @zigops-material/html, packed with rich components and widgets.",
  },
  {
    title: "@zigops-material/react",
    description: "Learn how to use @zigops-material/react, packed with rich components for React.",
  },
  {
    title: "Material Tailwind PRO",
    description: "A complete set of UI Elements for building faster websites in less time.",
  },
];

// NavListMenu component for rendering navigation list menu items
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Event triggers for showing/hiding the menu
  const triggers = {
    onMouseEnter: () => setIsMenuOpen(true),
    onMouseLeave: () => setIsMenuOpen(false),
  };

  // Rendering menu items
  const renderItems = navListMenuItems.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));

  // JSX for rendering NavListMenu component
  return (
    <React.Fragment>
      <Menu open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem
              {...triggers}
              className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full"
            >
              <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Pages
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        {/* Menu list */}
        <MenuList
          {...triggers}
          className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid"
        >
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <RocketLaunchIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">{renderItems}</ul>
        </MenuList>
      </Menu>
      {/* Menu item for small screens */}
      <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px]" /> Pages
      </MenuItem>
      {/* List of menu items for small screens */}
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">{renderItems}</ul>
    </React.Fragment>
  );
}

// Navigation list items
const navListItems = [
  {
    label: "Account",
    icon: UserCircleIcon,
  },
  {
    label: "Blocks",
    icon: CubeTransparentIcon,
  },
  {
    label: "Docs",
    icon: CodeBracketSquareIcon,
  },
];

// NavList component for rendering navigation list
function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {/* Rendering NavListMenu component */}
      <NavListMenu />
      {/* Rendering navigation list items */}
      {navListItems.map(({ label, icon }) => (
        <Typography
          key={label}
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })} {label}
          </MenuItem>
        </Typography>
      ))}
    </ul>
  );
}

// Header component for the application
export default function Header({ setMobileNav, changeMobileNavToggleState }: Props) {
  // State to manage the visibility of the navigation menu
  const [isNavOpen, setIsNavOpen] = useState(false);

  // State to manage the list of companies
  const [CompanyList, setCompanyList] = useState<CompanyTempInterface[]>([]);

  // State to manage the setting of the company
  const [isSettingCompany, setIsSettingCompany] = useState(false);
  const { type } = useSelector((state: RootState) => state.app_reducer.companyInfo);
  const [openSettings, setOpenSettings] = useState(null);
  const handleOpenSettings = () => setOpenSettings(!openSettings);

  // Redux state for the currently active company
  // Hooks
  const { idTokenClaims, accessToken } = useSelector((state: RootState) => state.app_reducer.user);
  const { CompanyId, DisplayName } = useSelector(
    (state: RootState) => state.app_reducer.companyInfo,
  );
  const { role } = useSelector((state: RootState) => state.app_reducer.activeEmployee);

  const { unreadCount } = useSelector((state: RootState) => state.app_reducer.notificationState);

  // msal hook
  const { instance, accounts } = useMsal();

  // Next.js router
  const router = useRouter();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Function to toggle the visibility of the navigation menu
  const toggleIsNavOpen = () => {
    setIsNavOpen((cur) => !cur);
    changeMobileNavToggleState((cur) => !cur);
  };

  // Function to handle fetching company information
  const handleCompanyInfo = async () => {
    const SavedCompanies = await getCompanyInfo(COMPANY_INFO);
    if (SavedCompanies !== "undefined")
      if (JSON.parse(SavedCompanies))
        // @ts-ignore
        setCompanyList(JSON.parse(SavedCompanies));
    console.log("SavedCompanies", SavedCompanies);
  };

  // Function to handle other company information
  const handleOtherCompanyInfo = async () => {
    toggleIsNavOpen();
    router.push("/admin/add-company");
    //removeActiveCompany(COMPANY_INFO, dispatch, () => {});
  };

  // Function to handle changing the active company
  const handleChangeActiveCompany = async (companyId: string) => {
    const index = CompanyList.findIndex((item: any) => item.CompanyId === companyId);
    const savedCompany = {
      type: CompanyList[index].type,
      DisplayName: CompanyList[index].displayName,
      CompanyId: companyId,
      TenantId: CompanyList[index].tenantId,
      Tier: CompanyList[index].Tier,
      TaxId: CompanyList[index].TaxId,
      RegistrationNumber: CompanyList[index].registrationNumber,
      Country: CompanyList[index].country,
      roles: CompanyList[index].roles,
      isCompanySet: true,
      isActive: true,
      rememberCompany: CompanyList[index].rememberCompany,
      City: CompanyList[index].city,
      Province: CompanyList[index].province,
      CompanyEmail: CompanyList[index].companyEmail,
      PhoneNumber: CompanyList[index].phoneNumber,
      PhysicalAddress: CompanyList[index].physicalAddress,
      PostalAddress: CompanyList[index].postalAddress,
    };

    await saveCompany(COMPANY_INFO, savedCompany, dispatch, () => {
      setIsSettingCompany(true);
    });
  };

  // useEffect to handle initial setup and window resize event
  useEffect(() => {
    handleCompanyInfo();

    window.addEventListener("resize", () => {
      window.innerWidth >= 960 && setIsNavOpen(false);
      window.innerWidth >= 960 && setMobileNav(false);
    });
  }, []);

  const handleNavigateToNotifications = () => {
    if (role === "admin") {
      router.push(`/household/notifications`);
    } else {
      router.push(`/employee/notifications`);
    }
  };

  /**
   * This hook checks if the B2C token is expired.
   */
  useTokenExpiryNotifier(
    { bearerToken: accessToken, accounts, instance },
    logout,
    dispatch,
    removeCompanyInfo,
    COMPANY_INFO,
  );

  // JSX for rendering the Header component
  return (
    <Navbar className="inset-0 z-10 h-max max-w-full rounded-none border-l-0 border-gray-350 bg-white p-2 shadow-none dark:border-gray-750 dark:bg-gray-850 dark:text-blue-gray-200">
      <div
        className="
      `relative flex items-center text-blue-gray-900 dark:text-blue-gray-200"
      >
        {/* Notification icon */}
        {unreadCount > 0 ? (
          <Badge className="mt-2" content={unreadCount}>
            <IconButton onClick={handleNavigateToNotifications} color="gray" variant="text">
              <BellIcon
                strokeWidth={1.5}
                className={`h-5 w-5 text-gray-600 transition-transform dark:text-blue-gray-200`}
              />
            </IconButton>
          </Badge>
        ) : (
          <IconButton onClick={handleNavigateToNotifications} color="gray" variant="text">
            <BellIcon
              strokeWidth={1.5}
              className={`h-5 w-5 text-gray-600 transition-transform dark:text-blue-gray-200`}
            />
          </IconButton>
        )}

        {/* Hamburger menu icon for small screens */}
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6 dark:text-blue-gray-200" />
        </IconButton>

        {/* Rendering user profile menu */}
        <ProfileMenu />

        {/* Company switch menu */}
        <Menu
          dismiss={{
            itemPress: false,
          }}
        >
          <MenuHandler>
            <Button variant="text" size="sm" className="flex capitalize dark:text-gray-100">
              <div>{DisplayName}</div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="ml-2 h-4 w-4 dark:text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </Button>
          </MenuHandler>
          <MenuList>
            {CompanyList.map((company) => (
              <MenuItem
                key={company.companyId}
                onClick={() => {
                  handleChangeActiveCompany(company.displayName);
                }}
                className="p-0"
              >
                <label htmlFor="item-1" className="flex cursor-pointer items-center gap-2 p-2">
                  <Checkbox
                    ripple={false}
                    checked={company.companyId === CompanyId}
                    id="item-1"
                    containerProps={{ className: "p-0" }}
                    className="hover:before:content-none"
                  />
                  {company.displayName}
                </label>
              </MenuItem>
            ))}
            <hr className="my-3 dark:border-gray-750" />
            <MenuItem onClick={() => handleOpenSettings()} className="p-2">
              Use different company
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <Dialog open={openSettings} size={"xl"} className="w-full" handler={handleOpenSettings}>
        <DialogBody className="rounded-md bg-gray-50 p-0 dark:bg-gray-950">
          {type === "Household" ? <AddNewCompany /> : <AddNewCompany />}
        </DialogBody>
      </Dialog>
      <MobileNav open={isNavOpen} className="overflow-scroll">
        <NavList />
      </MobileNav>
    </Navbar>
  );
}
