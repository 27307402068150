import React, { useEffect } from "react";
import { toast } from "react-toastify";

/**
 * A ZigOps hook that manages the lifecycle of a JWT token by scheduling checks based
 * on the token's expiry time directly from its claims. This approach reduces the need for frequent
 * periodic checks, optimizing performance and aligning with effective security practices.
 *
 * The hook sets up a single timeout that triggers at the exact time the token expires,
 * and optionally, it can also set a warning a few minutes before the expiration to notify the user.
 * This ensures that the user's session is managed securely and efficiently, with minimal disruption and overhead.
 *
 * @param {Object} idTokenClaims - Contains authentication information including the JWT token.
 * @param {Function} logout - The function to call to handle user logout. It should accept parameters specific to the logout process.
 * @param {Function} dispatch - Redux dispatch function, used to manage state changes related to authentication.
 * @param {Function} removeCompanyInfo - Function to clear company-related information from storage.
 * @param {string} COMPANY_INFO - Constant key to identify which pieces of company info to remove from storage.
 *
 * @example
 * // Typical usage inside a component
 * useTokenExpiryNotifier(idTokenClaims, logout, dispatch, removeCompanyInfo, "COMPANY_INFO_KEY");
 */

import { Dispatch } from "redux";

// types
interface IdTokenClaims {
  bearerToken?: string;
  accounts: Array<{ homeAccountId: string }>;
  instance?: any;
}

interface LogoutFunction {
  (instance: any, homeAccountId: string): Promise<void>;
}

interface RemoveCompanyInfoFunction {
  (infoKey: string, scope: string): void;
}

const useTokenExpiryNotifier = (
  idTokenClaims: IdTokenClaims,
  logout: LogoutFunction,
  dispatch: Dispatch<any>,
  removeCompanyInfo: RemoveCompanyInfoFunction,
  COMPANY_INFO: string,
) => {
  // Decode the JWT token and return the payload
  const decodeToken = (token) => {
    if (!token) {
      handleSignOut();
      return null;
    }
    try {
      const payload = token.split(".")[1];
      return payload ? JSON.parse(window.atob(payload)) : null;
    } catch (error) {
      handleSignOut();
      return null;
    }
  };

  // Function to handle user sign out
  const handleSignOut = async () => {
    if (idTokenClaims.accounts[0]) {
      await logout(idTokenClaims.instance, idTokenClaims.accounts[0].homeAccountId).catch(
        (error) => {
          console.error("Logout failed:", error);
        },
      );
    }
    dispatch({ type: "USER_LOGGED_OUT" });
    dispatch({ type: "REMOVE_COMPANY" });
    removeCompanyInfo(COMPANY_INFO, "all");
  };

  // Schedule token expiry check using the token's expiry timestamp
  const scheduleTokenExpiryCheck = (token) => {
    const decodedPayload = decodeToken(token);
    if (!decodedPayload) return;

    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = decodedPayload.exp - currentTime;

    // Check if there's a reasonable amount of time left; otherwise, it might be too late already
    if (timeUntilExpiry > 0) {
      setTimeout(() => {
        handleSignOut();
      }, timeUntilExpiry * 1000);
      
      // Schedule a warning 2 minutes before expiry
      if (timeUntilExpiry > 120) {
        setTimeout(() => {
          toast.warning(`Token will expire in 2 minutes. Please save your work!`, {
            position: "bottom-right",
          });
        }, (timeUntilExpiry - 120) * 1000);
      }
    } else {
      // Immediate action if the token is already expired or about to
      handleSignOut();
    }
  };

  useEffect(() => {
    if (idTokenClaims.bearerToken) {
      scheduleTokenExpiryCheck(idTokenClaims.bearerToken);
    }
  }, [idTokenClaims.bearerToken]); // Dependency on bearerToken to reset timer if token updates
};

export default useTokenExpiryNotifier;
