import { Button } from "@zigops-material/react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { COMPANY_INFO } from "@/constants/companysetup.constants";
import { removeCompanyInfo } from "@/redux/actions/companyActions";
import { userLoggedOut } from "@/redux/features/auth/user";
import { removeCompany } from "@/redux/features/company";

const SubscriptionComplete: React.FC = () => {
  const router = useRouter();
  const { planName } = useSelector(
    (state: RootState) => state.app_reducer.subscriptionCreationDataReducer,
  );

  const dispatch = useDispatch();

  // Handle Employee SignOut
  const handleSignOut = async () => {
    router.push("/logout");
    dispatch(userLoggedOut({}));
    dispatch(removeCompany({}));
    removeCompanyInfo(COMPANY_INFO, "all");
  };

  return (
    <>
      <div className="absolute top-0 mb-10 flex flex-col items-center justify-center pb-4">
        <img
          className="mb-5 h-[150px] w-[109.83px]"
          style={{ objectFit: "cover" }}
          src="/img/Main-Logo-Social.png"
          alt="Main Logo"
        />
        <div className="text-m3-sys-light-on-surface-variant mb-5 mt-5 w-full max-w-md text-center font-['Montserrat-SemiBold',_sans-serif] text-[28px] font-semibold leading-9">
          Yippee! You'll absolutely Love the {planName}!<br />
        </div>
      </div>
      <img
          className="absolute bottom-0 left-0 h-auto w-full border-t-[2px] border-gold-400 sm:h-32 md:h-48 lg:h-64 xl:h-80"
          style={{ objectFit: "cover" }}
          src="/img/gold-pattern.png"
          alt="Footer Image"
        />

      <div className="mt-5">
        <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-start gap-8 sm:gap-12">
          <div className="mt-10 mt-10 h-[400] w-[600px] w-full max-w-md">
            <img
              className="mt-5 h-[350px] w-[250px] object-cover sm:h-[400px] sm:w-[350px]"
              src="/img/happy_people.png"
              alt="Happy People"
            />
            <Button
              className="ml-5 mt-5 bg-blue-500"
              onClick={(e) => {
                e.preventDefault();
                handleSignOut();
              }}
            >
              Start Your Onboarding
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionComplete;
