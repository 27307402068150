import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { server } from "@/config/server";
import getHeaders from "./headers";

export const firstImpressionApiSlice = createApi({
  reducerPath: "firstImpressionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["FirstImpression"],
  endpoints: (builder) => ({
    getCompanyFirstImpression: builder.query<any, any>({
      query: (infor) => {
        return {
          url: `/firstImpression/company/${infor.tenantId}/${infor.tierName}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "FirstImpression", id: "FirstImpressions" }],
    }),
    getEmployeeFirstImpression: builder.query<any, any>({
      query: (infor) => {
        return {
          url: `/firstImpression/employee/${infor.tenantId}/${infor.tierName}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "FirstImpression", id: "FirstImpressions" }],
    }),

    markFirstImpressionTaskComplete: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/firstImpression/markComplete`,
          method: "POST",
          body: {
            tierDataCollectionSettingId: formData.tierSettingId,
            appliesToId: formData.Id,
          },
        };
      },
      invalidatesTags: [{ type: "FirstImpression", id: "FirstImpressions" }],
    }),
  }),
});

export const {
  useGetCompanyFirstImpressionQuery,
   useLazyGetCompanyFirstImpressionQuery,
  useGetEmployeeFirstImpressionQuery,
  useMarkFirstImpressionTaskCompleteMutation,

} = firstImpressionApiSlice;
