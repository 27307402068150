// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formDataState {
  EmployeeName: string;
  EmployeeEmail: string;
  EmployeePhoneNumber: string;
  EmployeePhysicalAddress: string;
  EmployeeJobTitle: string;
  EmployeeGrossIncome: number;
  EmployeeMobileMoneyNumber: string;
  EmployeeMaritalStatus: string;
  EmployeesTPIN: string;
  EmployeesNRC: string;
  EmployeesNAPSA: string;
  EmployeeNhimaMemberId: string;
  EmployeesNextOfKinName: string;
  EmployeesNextOfKinRelationship: string;
  EmployeesGender: string;
  EmployeesMobileMoneyProvider: string;
  EmployeesNextOfKinTitle: string;
  EmployeesNextOfKinContactDetails: string;
  EmployeeStartDate: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  EmployeeName: "",
  EmployeeEmail: "",
  EmployeePhoneNumber: "",
  EmployeePhysicalAddress: "",
  EmployeeJobTitle: "",
  EmployeeGrossIncome: 0,
  EmployeeMobileMoneyNumber: "",
  EmployeesTPIN: "",
  EmployeesNRC: "",
  EmployeesNAPSA: "",
  EmployeeNhimaMemberId: "",
  EmployeesNextOfKinName: "",
  EmployeesGender: "",
  EmployeesMobileMoneyProvider: "",
  EmployeesNextOfKinRelationship: "",
  EmployeesNextOfKinContactDetails: "",
  EmployeeMaritalStatus: "",
  EmployeesNextOfKinTitle: "",
  EmployeeStartDate: ""
};

// Creating a Redux slice for managing Employee form data
export const EmployeeFormDataSlice = createSlice({
  name: "Household-employee-onboarding-form-data",
  initialState,
  reducers: {
    // Reducer to set Employee name
    setHouseholdEmployeeName: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeName = action.payload.EmployeeName;
    },

    // Reducer to set Employee name
    setHouseholdEmployeesNextOfKinContactDetails: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesNextOfKinContactDetails = action.payload.EmployeesNextOfKinContactDetails;
    },
    // Reducer to set Employee email
    setHouseholdEmployeeEmail: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeEmail = action.payload.EmployeeEmail;
    },
    // Reducer to set Employee phone number
    setHouseholdEmployeePhoneNumber: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeePhoneNumber = action.payload.EmployeePhoneNumber;
    },

    // Reducer to set Employee physical address
    setHouseholdEmployeePhysicalAddress: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeePhysicalAddress = action.payload.EmployeePhysicalAddress;
    },

    // Reducer to set EmployeesGender
    setHouseholdEmployeesGender: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesGender = action.payload.EmployeesGender;
    },

    // Reducer to set Employee JobTitle
    setHouseholdEmployeeJobTitle: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeJobTitle = action.payload.EmployeeJobTitle;
    },

    // Reducer to set Employee GrossIncome
    setHouseholdEmployeeGrossIncome: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeGrossIncome = action.payload.EmployeeGrossIncome;
    },

    // Reducer to set Employee Mobile Money Number
    setHouseholdEmployeeMobileMoneyNumber: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeMobileMoneyNumber = action.payload.EmployeeMobileMoneyNumber;
    },

    // Reducer to set EmployeesTPIN
    setHouseholdEmployeesTPIN: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesTPIN = action.payload.EmployeesTPIN;
    },

    // Reducer to set EmployeesNRC
    setHouseholdEmployeesNRC: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNRC = action.payload.EmployeesNRC;
    },

    // Reducer to set EmployeesNAPSA
    setHouseholdEmployeesNAPSA: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNAPSA = action.payload.EmployeesNAPSA;
    },

    setHouseholdEmployeeNhimaMemberId: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeNhimaMemberId = action.payload.EmployeeNhimaMemberId;
    },

    // Reducer to set EmployeesNextOfKing
    setHouseholdEmployeesNextOfKinName: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNextOfKinName = action.payload.EmployeesNextOfKinName;
    },

    // Reducer to set Employee EmployeesMobileMoneyProvider
    setHouseholdEmployeesMobileMoneyProvider: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesMobileMoneyProvider = action.payload.EmployeesMobileMoneyProvider;
    },

    // Reducer to set EmployeesNextOfKinRelationship
    setHouseholdEmployeesNextOfKinRelationship: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesNextOfKinRelationship = action.payload.EmployeesNextOfKinRelationship;
    },

    // Reducer to set EmployeeMaritalStatus
    setHouseholdEmployeeMaritalStatus: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeMaritalStatus = action.payload.EmployeeMaritalStatus;
    },

    // Reducer to set EmployeesNextOfKinTitle
    setHouseholdEmployeesNextOfKinTitle: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNextOfKinTitle = action.payload.EmployeesNextOfKinTitle;
    },
    // Reducer to set EmployeeStartDate
    setHouseholdEmployeeStartDate: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeStartDate = action.payload.EmployeeStartDate;
    },

    // Reducer to set reset the form data state
    resetEmployeeForm: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeName = "";
      state.EmployeeStartDate = "";
      state.EmployeesNextOfKinTitle = "";
      state.EmployeeMaritalStatus = "";
      state.EmployeesNextOfKinRelationship = "";
      state.EmployeesMobileMoneyProvider = "";
      state.EmployeesNextOfKinName = "";
      state.EmployeesNAPSA = "";
      state.EmployeesNRC = "";
      state.EmployeeNhimaMemberId = "";
      state.EmployeesTPIN = "";
      state.EmployeeMobileMoneyNumber = "";
      state.EmployeeGrossIncome = 0;
      state.EmployeeJobTitle = "";
      state.EmployeesGender = "";
      state.EmployeePhysicalAddress = "";
      state.EmployeePhoneNumber = "";
      state.EmployeeEmail = "";
      state.EmployeesNextOfKinContactDetails = "";
    },
  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployeeName,
  setHouseholdEmployeeEmail,
  setHouseholdEmployeePhoneNumber,
  setHouseholdEmployeePhysicalAddress,
  setHouseholdEmployeeJobTitle,
  setHouseholdEmployeeGrossIncome,
  setHouseholdEmployeeMobileMoneyNumber,
  setHouseholdEmployeesTPIN,
  setHouseholdEmployeesNRC,
  setHouseholdEmployeesNAPSA,
  setHouseholdEmployeeNhimaMemberId,
  setHouseholdEmployeesNextOfKinName,
  setHouseholdEmployeeMaritalStatus,
  setHouseholdEmployeesGender,
  setHouseholdEmployeesMobileMoneyProvider,
  setHouseholdEmployeesNextOfKinRelationship,
  setHouseholdEmployeesNextOfKinTitle,
  setHouseholdEmployeeStartDate,
  setHouseholdEmployeesNextOfKinContactDetails,
  resetEmployeeForm,
} = EmployeeFormDataSlice.actions;

export default EmployeeFormDataSlice.reducer;
