import { v4 as uuid } from "uuid";
import { AUTH_TOKEN_KEY, CORRELATION_ID, TENANT } from "@/constants/common.constants";
import useStorage from "@/hooks/useStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const { getItem } = useStorage();
const correlationIdFromStorage = getItem(CORRELATION_ID);

const correlationId = correlationIdFromStorage ? correlationIdFromStorage : uuid();
const auth_key = getItem(AUTH_TOKEN_KEY);
const tenant_id = JSON.parse(getItem(TENANT));

export interface tokenHeadersState {
  token: string;
  tenantId: string;
  correlationId: string;
}

// Initialize the headers state
const initialState: tokenHeadersState = {
  token: auth_key,
  tenantId: tenant_id,
  correlationId: correlationId,
};

export const headerSlice = createSlice({
  name: "headers",
  initialState,
  reducers: {
    /**
     *
     * @param state
     * @param action
     * sets the state of the headers
     */
    setHeaders: (state: tokenHeadersState, action: PayloadAction<any>) => {
      state.token = action.payload.accessToken;
      state.tenantId = action.payload.tenantId;
      state.correlationId = action.payload.correlationId;
    },
    setTenantId: (state: tokenHeadersState, action: PayloadAction<any>) => {
      state.tenantId = action.payload.tenantId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeaders, setTenantId } = headerSlice.actions;

export default headerSlice.reducer;

