import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { ResponsePaginationCursor } from "@/types/Pagination";
import { Payslip } from "@/types/Payroll";

export const payslipsApiSlice = createApi({
  reducerPath: "payslipsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: getHeaders,
  }),
  tagTypes: ["payslips"],
  endpoints: (builder) => ({
    getPaySlipsByEmployeeIdAndPayPeriod: builder.query<ResponsePaginationCursor<Payslip>, any>({
      query: ({
        employeeId,
        startOfPayPeriod,
        endOfPayPeriod,
        previousCursor,
        nextCursor,
        itemsPerPage,
        page
      }) => {
        return {
          url: startOfPayPeriod && endOfPayPeriod ? `payslips?employeeId=${employeeId}&startOfPayPeriod=${startOfPayPeriod}&endOfPayPeriod=${endOfPayPeriod}&previousCursor=${previousCursor}&nextCursor=${nextCursor}&itemsPerPage=${itemsPerPage}&page=Next` :
            `payslips?employeeId=${employeeId}&previousCursor=${previousCursor}&nextCursor=${nextCursor}&itemsPerPage=${itemsPerPage}&page=${page}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "payslips", id: "payslipss" }],
    }),
    getPayslipByPayRunEmployeeCompensationId: builder.query<Payslip, string>({
      query: (payRunEmployeeCompensationId) => {
        return {
          url: `pay-run-employee-compensations/${payRunEmployeeCompensationId}/payslip`,
          method: "GET"
        }
      }
    })
  }),
});

export const {
  useGetPaySlipsByEmployeeIdAndPayPeriodQuery,
  useLazyGetPaySlipsByEmployeeIdAndPayPeriodQuery,
  useGetPayslipByPayRunEmployeeCompensationIdQuery
} = payslipsApiSlice;
