// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formErrorDataState {
  employerFullNameError: string;
  employerPhysicalAddressError: string;
  employerPhoneNumberError: string;
  
}

// Initialize the initial state of the form data
const initialState: formErrorDataState = {
  employerFullNameError: "",
  employerPhysicalAddressError: "",
  employerPhoneNumberError: "",
};

export const EmployerTaxDetailsErrorDataSlice = createSlice({
  name: "Household-collect-home-address-details",
  initialState,
  reducers: {
    setHouseholdEmployerFullNameError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerFullNameError = action.payload.employerFullNameError;
    },
    setHouseholdEmployerPhysicalAddressError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerPhysicalAddressError = action.payload.employerPhysicalAddressError;
    },
    setHouseholdEmployerPhoneNumberError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerPhoneNumberError = action.payload.employerPhoneNumberError;
    },
  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployerFullNameError,
  setHouseholdEmployerPhysicalAddressError,
  setHouseholdEmployerPhoneNumberError,
} = EmployerTaxDetailsErrorDataSlice.actions;

export default EmployerTaxDetailsErrorDataSlice.reducer;
