import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface constructorTags {
  label: string;
  id: string;
}

export interface constructList {
  Contractor: constructorTags[];
  Candidate: constructorTags[];
  Employee: constructorTags[];
}

const constructor_array = [
  { id: "Independent-Contractor", label: "Independent Contractor" },
  { id: "Freelancer", label: "Freelancer" },
  { id: "Consultant", label: "Consultant" },
  { id: "Temporary Contractor", label: "Temporary Contractor" },
  { id: "Freelancer", label: "Freelancer" },
  { id: "Seasonal-Contractor", label: "Seasonal Contractor" },
  { id: "Retiree-Contractor", label: "Retiree Contractor" },
  { id: "Vendor-Contractor", label: "Vendor Contractor" },
  { id: "Offshore-Contractor", label: "Offshore Contractor" },
  { id: "Part-time-Contractor", label: "Part-time Contractor" },
];
const candidate_array = [
  { id: "Intern", label: "Intern" },
  { id: "Fixed-Term", label: "Fixed-Term" },
  { id: "Permanent", label: "Permanent" },
  { id: "Casual-Employment", label: "Casual Employment" },
];
const employee_array = [
  { id: "Intern", label: "Intern" },
  { id: "Fixed-Term", label: "Fixed-Term" },
  { id: "Permanent", label: "Permanent" },
  { id: "Casual-Employment", label: "Casual Employment" },
];

const initialState: constructList = {
  Contractor: constructor_array,
  Candidate: candidate_array,
  Employee: employee_array,
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    ConstructList: (
      state: {
        Contractor: constructorTags[];
        Candidate: constructorTags[];
        Employee: constructorTags[];
      },
      action: PayloadAction<any>,
    ) => {},
    setConstructList: (
      state: {
        Contractor: constructorTags[];
        Candidate: constructorTags[];
        Employee: constructorTags[];
      },
      action: PayloadAction<any>,
    ) => {
      switch (action.type) {
        case "contractor":
          state.Contractor = action.payload.contractor;
          break;

        case "contractor":
          state.Candidate = action.payload.candidate;
          break;

        case "employee":
          state.Employee = action.payload.employee;
          break;

        case "all":
          state.Contractor = action.payload.contractor;
          state.Candidate = action.payload.candidate;
          state.Employee = action.payload.employee;
          break;

        default:
          break;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { ConstructList, setConstructList } = contractsSlice.actions;

export default contractsSlice.reducer;
