import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { buildPaginationQueryString } from "@/utils/pagination";
import { ResponsePaginationCursor } from "@/types/Pagination";
import { Notification } from "@/types/Notification";

export const NotificationsApiSlice = createApi({
    reducerPath: "NotificationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["Notifications"],
    endpoints: (builder) => ({
        getNotificationsByTypeAndEmployeeId: builder.query<ResponsePaginationCursor<Notification>, any>({
            query: ({ notificationType, employeeId, previousCursor, nextCursor, itemsPerPage, page }) => {
                const queryParams = buildPaginationQueryString({
                    nextCursor: nextCursor,
                    previousCursor: previousCursor,
                    itemsPerPage: itemsPerPage,
                    page: page
                });

                return {
                    url: `notifications?notificationType=${notificationType}&employeeId=${employeeId}&${queryParams}`,
                    method: "GET"
                };
            },
            providesTags: [{ type: "Notifications", id: "notifications" }],
        }),
        updateNotificationReadStatus: builder.mutation<any, any>({
            query: ({ notificationId, newReadStatus }) => {
                return {
                    url: `notifications/${notificationId}/read-status`,
                    method: "PATCH",
                    body: newReadStatus
                }
            }
        }),
        hasUnreadNotifications: builder.query<any, string>({
            query: (employeeId) => {
                return {
                    url: `employees/${employeeId}/notifications/has-unread`,
                    method: "GET"
                }
            }
        }),
        getUnreadCount: builder.query<any, string>({
            query: (employeeId) => {
                return {
                    url: `employees/${employeeId}/notifications/unread/count`,
                    method: "GET"
                }
            }
        })
    }),
});

export const {
    useGetNotificationsByTypeAndEmployeeIdQuery,
    useLazyGetNotificationsByTypeAndEmployeeIdQuery,
    useUpdateNotificationReadStatusMutation,
    useLazyHasUnreadNotificationsQuery,
    useHasUnreadNotificationsQuery,
    useLazyGetUnreadCountQuery,
    useGetUnreadCountQuery
} = NotificationsApiSlice;
