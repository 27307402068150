import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface formDataState {
  payrollGroups: any[];
}

// Initialize the theme state
const initialState: formDataState = {
  payrollGroups: [],
};

export const PayrollGroupsSlice = createSlice({
  name: "payroll-groups",
  initialState,
  reducers: {
    setPayrollGroups: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollGroups = action.payload.payrollGroups;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPayrollGroups } = PayrollGroupsSlice.actions;

export default PayrollGroupsSlice.reducer;
