import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface formDataState {
  payrollNameError: string;
  payrollPayDayError: string;
  payrollInitialPaymentDateError: string;
  howOftenToPayEmployeesError: string;
  automaticallyApproveWhenDueError: string;
  deductionNotificationError: string;
  approvers_listError: string;
  whoToPayError: string;
  employees_listError: string;
}

// Initialize the theme state
const initialState: formDataState = {
  payrollNameError: "",
  payrollPayDayError: "",
  payrollInitialPaymentDateError: "",
  howOftenToPayEmployeesError: "",
  automaticallyApproveWhenDueError: "",
  deductionNotificationError: "",
  approvers_listError: "",
  whoToPayError: "",
  employees_listError: "",
};

export const payrollFormErrorsSlice = createSlice({
  name: "payroll-onboarding-form-errors",
  initialState,
  reducers: {
    setPayrollNameError: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollNameError = action.payload.payrollNameError;
    },
    setPayrollPayDayError: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollPayDayError = action.payload.payrollPayDayError;
    },
    setPayrollInitialPaymentDateError: (state: formDataState, action: PayloadAction<any>) => {
      state.payrollInitialPaymentDateError = action.payload.payrollInitialPaymentDateError;
    },
    setHowOftenToPayEmployeesError: (state: formDataState, action: PayloadAction<any>) => {
      state.howOftenToPayEmployeesError = action.payload.howOftenToPayEmployeesError;
    },
    setAutomaticallyApproveWhenDueError: (state: formDataState, action: PayloadAction<any>) => {
      state.automaticallyApproveWhenDueError = action.payload.automaticallyApproveWhenDueError;
    },
    setDeductionNotificationError: (state: formDataState, action: PayloadAction<any>) => {
      state.deductionNotificationError = action.payload.deductionNotificationError;
    },
    setApproversError: (state: formDataState, action: PayloadAction<any>) => {
      state.approvers_listError = action.payload.approvers_listError;
    },
    setWhoToPayError: (state: formDataState, action: PayloadAction<any>) => {
      state.whoToPayError = action.payload.whoToPayError;
    },
    setSelectedEmployeesError: (state: formDataState, action: PayloadAction<any>) => {
      state.employees_listError = action.payload.employees_listError;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPayrollNameError,
  setPayrollPayDayError,
  setHowOftenToPayEmployeesError,
  setApproversError,
  setAutomaticallyApproveWhenDueError,
  setDeductionNotificationError,
  setPayrollInitialPaymentDateError,
  setSelectedEmployeesError,
  setWhoToPayError
} = payrollFormErrorsSlice.actions;

export default payrollFormErrorsSlice.reducer;
