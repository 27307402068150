import { PhysicalAddress } from "./employee.constants";
import { DropdownPicker } from "../types/User";

export const COUNTRY_KENYA = "Kenya";
export const COUNTRY_ZAMBIA = "Zambia";
export const COUNTRY_SOUTH_AFRICA = "South Africa";
export const COUNTRY_TANZANIA = "Tanzania";
export const COUNTRY_GHANA = "Ghana";
export const COUNTRY_EGYPT = "Egypt";
export const COUNTRY_NIGERIA = "Nigeria";
export const COMPANY = "company";

export const KENYA_COUNTRY_CODE = "KE";
export const EGYPT_COUNTRY_CODE = "EG";
export const ZAMBIA_COUNTRY_CODE = "ZM";
export const GHANA_COUNTRY_CODE = "GH";
export const TANZANIA_COUNTRY_CODE = "TZ";
export const NIGERIA_COUNTRY_CODE = "NG";
export const SOUTH_AFRICA_COUNTRY_CODE = "ZA";

export const FULL_TIME_EMPLOYEE_TYPES = "fullTime";
export const PART_TIME_EMPLOYEE_TYPES = "partTime";
export const CONTRACT_EMPLOYEE_TYPES = "contract";

export const OKRA_FUNDING_SOURCE_SUPPORTED_COUNTRIES = [COUNTRY_NIGERIA];

export const ZAZU_FUNDING_SOURCE_SUPPORTED_COUNTRIES = [COUNTRY_ZAMBIA];

export const TRANSFERWISE_FUNDING_SOURCE_SUPPORTED_COUNTRIES = [
  COUNTRY_KENYA,
  COUNTRY_GHANA,
  COUNTRY_SOUTH_AFRICA,
  COUNTRY_EGYPT,
  COUNTRY_TANZANIA,
];

export const ZAZU_FUNDING_SOURCE = "Zazu";
export const OKRA_FUNDING_SOURCE = "Okra";
export const TRANSFERWISE_FUNDING_SOURCE = "TransferWise";

export const WAGE_TYPES: DropdownPicker[] = [
  { id: "monthly", value: "Monthly" },
  { id: "hourly", value: "Hourly" },
  { id: "weekly", value: "Weekly" },
];

export const EMPLOYEE_STATUS_TYPES: DropdownPicker[] = [
  { id: "Active", value: "Active" },
  { id: "Inactive", value: "Inactive" },
  { id: "UnpaidLeave", value: "UnpaidLeave" },
  { id: "MarkedForDeletion", value: "MarkedForDeletion" },
  { id: "Deleted", value: "Deleted" },
];

export const EMPLOYEE_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  UNPAIDLEAVE: "UnPaidLeave",
  MARKEDFORDELETION: "MarkedForDeletion",
  DELETED: "Deleted",
};

export const EMPLOYEE_STATUS_COLORS = {
  ACTIVE: "#1BC943",
  INACTIVE: "#DCDCDC",
  UNPAIDLEAVE: "#BDB76B",
  MARKEDFORDELETION: "#B22222",
  DELETED: "#FF000D",
};

export const GENDER_TYPES: DropdownPicker[] = [
  { id: "Male", value: "Male" },
  { id: "Female", value: "Female" },
  { id: "Non-binary", value: "Non-binary" },
  { id: "Other", value: "Other" },
];

export const PAYMENT_METHOD_TYPES: DropdownPicker[] = [
  { id: "Bank transfer", value: "Bank transfer" },
];

export const PROVIDER_NAME_TYPES_ZAMBIA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];
export const PROVIDER_NAME_TYPES_NIGERIA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];

export const PROVIDER_NAME_TYPES_KENYA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "MPESA", value: "MPESA" },
];

export const PROVIDER_NAME_TYPES_GHANA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];

export const PROVIDER_NAME_TYPES_EGYPT: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];

export const PROVIDER_NAME_TYPES_TANZANIA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];

export const PROVIDER_NAME_TYPES_SOUTH_AFRICA: DropdownPicker[] = [
  { id: "MTN", value: "MTN" },
  { id: "AIRTEL", value: "AIRTEL" },
  { id: "ZAMTEL", value: "ZAMTEL" },
];

export const WORK_LOCATIONS: DropdownPicker[] = [
  { id: "Egypt", value: "Egypt" },
  { id: "Ghana", value: "Ghana" },
  { id: "Kenya", value: "Kenya" },
  { id: "Nigeria", value: "Nigeria" },
  { id: "Tanzania", value: "Tanzania" },
  { id: "SouthAfrica", value: "South Africa" },
  { id: "Zambia", value: "Zambia" },
];
export const BANK_TYPES_ZAMBIA: DropdownPicker[] = [
  { id: "FNB", value: "FNB" },
  { id: "Zanaco", value: "Zanaco" },
  { id: "ABSA", value: "ABSA" },
];

export interface CompanyInterface {
  type: string;
  DisplayName: string;
  CompanyId: string;
  TenantId: string;
  Tier: string;
  roles: any[];
  TaxId: string;
  RegistrationNumber: string;
  Country: string;
  isCompanySet: boolean;
  isActive: boolean;
  rememberCompany: boolean;
  City: string;
  Province: string;
  CompanyEmail: string;
  PhoneNumber: string;
  PhysicalAddress: string;
  PostalAddress: string;
}

export interface CompanyTempInterface {
  type: string;
  displayName: string;
  companyId: string;
  tenantId: string;
  Tier: string;
  roles: any[];
  TaxId: string;
  registrationNumber: string;
  country: string;
  isCompanySet: boolean;
  isActive: boolean;
  rememberCompany: boolean;
  city: string;
  province: string;
  companyEmail: string;
  phoneNumber: string;
  physicalAddress: string;
  postalAddress: string;
}

export const COMPANY_INFO = "companyInfo";

export enum CURRENCY_BY_COUNTRY {
  COUNTRY_EGYPT = "EGP",
  COUNTRY_SOUTH_AFRICA = "ZAR",
  COUNTRY_KENYA = "KES",
  COUNTRY_GHANA = "GHS",
  COUNTRY_ZAMBIA = "ZMW",
  COUNTRY_NIGERIA = "NGN",
  COUNTRY_TANZANIA = "TZS",
}

export type SubscriptionType = {
  chargebeeSubscriptionId: number;
  subscriptionId: string;
  tenantId: string;
  planId: string;
  billingPeriod: string;
  billingPeriodUnit: string;
  startDate: number;
};

export type FundingSource = {
  tenantId: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  swiftCode: string;
  sortCode: string;
  routingNumber: string;
  iban: string;
  branchName: string;
  branchCode: string;
  address: string;
  accountType: string;
};

export type IndustryType = {
  companyIndustryTypeId: number;
  tenantId: string;
  industry: string;
};

export type PaymentFrequency = {
  companyPaymentFrequencyId: number;
  tenantId: string;
  frequency: string;
};

export type EmployeeType = {
  companyEmployeeTypeId: number;
  tenantId: string;
  employeeType: string;
};

export interface CompanyUpdateModel {
  companyId: string;
  tenantId: string;
  displayName: string;
  tier: string;
  subscription: SubscriptionType;
  configurations: Record<string, any>; // assuming it's a dynamic object
  fundingSource: FundingSource;
  industryTypes: IndustryType[];
  paymentFrequencies: PaymentFrequency[];
  taxId: string;
  preferredFundingSource: string;
  dateCreated: string; // string (ISO date format)
  registrationNumber: string;
  country: string;
  type: string;
  companyEmail: string;
  phoneNumber: string;
  physicalAddress: string;
  city: string;
  province: string;
  postalAddress: string;
  employeeTypes: EmployeeType[];
}
