import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CreateLeaveRequestFormErrors {
    startDateError?: string;
    endDateError?: string;
}

const initialState: CreateLeaveRequestFormErrors = {
    startDateError: "",
    endDateError: ""
};

export const createLeaveRequestFormErrorsReducer = createSlice({
    name: "create-leave-request-form-errors",
    initialState: initialState,
    reducers: {
        setStartDateError: (state: CreateLeaveRequestFormErrors, action: PayloadAction<CreateLeaveRequestFormErrors>) => {
            state.startDateError = action.payload.startDateError;
        },
        setEndDateError: (state: CreateLeaveRequestFormErrors, action: PayloadAction<CreateLeaveRequestFormErrors>) => {
            state.endDateError = action.payload.endDateError
        }
    }
});

export const { setStartDateError, setEndDateError } = createLeaveRequestFormErrorsReducer.actions;
export default createLeaveRequestFormErrorsReducer.reducer;
