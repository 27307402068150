import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TriggerState {
  type: string;
}

// Initialize the trigger state
const initialState: TriggerState = {
    type: "",
};

export const stringTriggerSlice = createSlice({
  name: "stringTrigger",
  initialState,
  reducers: {
    setType: (
      state: {
        type: string;
      },
      action: PayloadAction<any>,
    ) => {
      state.type = action.payload.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setType } = stringTriggerSlice.actions;

export default stringTriggerSlice.reducer;
