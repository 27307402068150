import React, { useEffect, useState, useCallback, use } from "react";
import { useRouter } from "next/router";
import Button from "@/zigops/zigops-material-react/src/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { Input, Typography } from "@zigops-material/react";
import { useCreateNewTenantMutation } from "@/apis/OnBoardingSlice";
import { useAddCompanyMutation } from "@/apis/companyApiSlice";
import { useCreateNewEmployeeMutation } from "@/apis/EmployeesApiSlice";
import { toast } from "react-toastify";
import { Employee } from "@/apis/models/employee.model";
import user from "@/redux/features/user";

const AddNewCompany: React.FC = () => {
  const [companyName, setCompanyName] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false); // State to track if it's the last step
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [selectedCompanyType, setSelectedCompanyType] = useState<string>("");
  const router = useRouter();

  const activeEmployee = useSelector((state: RootState) => state.app_reducer.activeEmployee);

  const [
    createCompanyGroupMutate,
    {
      data: companyGroupData,
      isLoading: isLoadingCompanyGroup,
      isSuccess: isSuccessCompanyGroup,
      isError: isErrorCompanyGroup,
      error: errorCompanyGroup,
    },
  ] = useCreateNewTenantMutation();

  const [
    createCompanyMutate,
    {
      data: companyData,
      isLoading: isLoadingCompany,
      isSuccess: isSuccessCompany,
      isError: isErrorCompany,
      error: errorCompany,
    },
  ] = useAddCompanyMutation();

  const [
    createEmployeeMutate,
    {
      data: employeeData,
      isLoading: isLoadingEmployee,
      isSuccess: isSuccessEmployee,
      isError: isErrorEmployee,
      error: errorEmployee,
    },
  ] = useCreateNewEmployeeMutation();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectCompanyType = (event) => {
    setSelectedCompanyType(event.target.value);
  };

  const handleChangeCompanyName = (event: { target: { value: any } }) => {
    setCompanyName(event.target.value);
  };

  const handleCreateCompanyGroup = async () => {
    setIsProcessing(true);
    try {
      await toast.promise(
        async () => {
          const res: any = await createCompanyGroupMutate({
            ownerId: activeEmployee.userId,
            displayName: companyName,
          });
          if (Object.keys(res).length === 0) {
            // The response is empty
            if (isErrorCompanyGroup) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          } else {
            // @ts-ignore
            if (res.error) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          }
          //router.push("/auth/logout");
        },
        {
          pending: "Saving your information...",
          success: `Your profile has been updated successfully`,
          error: `Error saving your information`,
        },
      );

      //dispatch(setType({ type: "basic_information_succ" }));

      // Proceed with other actions or logic here
    } catch (error) {
      //dispatch(setType({ type: "physical_address_error" }));
      console.log("Error: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //Create company in database
  const handleCreateCompany = async (newTenantId: string) => {
    setIsProcessing(true);
    const companyDetails = {
      tenantId: newTenantId,
      displayName: companyName,
      country: selectedOption,
      type: selectedCompanyType,
    };

    try {
      await toast.promise(
        async () => {
          const res = await createCompanyMutate(companyDetails);
          if (Object.keys(res).length === 0) {
            // The response is empty
            if (isErrorCompany) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          } else {
            // @ts-ignore
            if (res.error) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          }
        },
        {
          pending: "Saving your information...",
          success: `Your profile has been updated successfully`,
          error: `Error saving your information`,
        },
      );

      //dispatch(setType({ type: "basic_information_succ" }));

      // Proceed with other actions or logic here
    } catch (error) {
      //dispatch(setType({ type: "physical_address_error" }));
      console.log("Error: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  //Create company employee
  const handleCreateCompanyEmployee = async (newTenantId: string, newTenantName: string) => {
    setIsProcessing(true);
    const companyEmployee: Employee = {
      userId: activeEmployee.userId,
      tenantId: newTenantId,
      tenantName: newTenantName,
      status: activeEmployee.status,
      employeeId: "",
      email: activeEmployee.email,
      firstName: activeEmployee.firstName,
      lastName: activeEmployee.lastName,
      role: activeEmployee.role,
      maritalStatus: activeEmployee.maritalStatus,
      statusAsOf: activeEmployee.statusAsOf,
      dateOfBirth: activeEmployee.dateOfBirth,
      dateOfHire: activeEmployee.dateOfHire,
      dateUpdated: activeEmployee.dateUpdated,
      gender: activeEmployee.gender,
      personalId: activeEmployee.personalId,
      taxId: activeEmployee.taxId,
      paymentMethod: activeEmployee.paymentMethod,
      avatar: activeEmployee.avatar,
      currency: activeEmployee.currency,
      healthInsuranceId: activeEmployee.healthInsuranceId,
      phoneNumber: activeEmployee.phoneNumber,
      homeAddress: activeEmployee.homeAddress,
      pensionDetails: activeEmployee.pensionDetails,
      emergencyContactDetails: activeEmployee.emergencyContactDetails,
      compensationDetails: activeEmployee.compensationDetails,
      bankDetails: activeEmployee.bankDetails,
      mobileMoneyPaymentDetails: activeEmployee.mobileMoneyPaymentDetails,
    };

    try {
      await toast.promise(
        async () => {
          const res = await createEmployeeMutate(companyEmployee);
          if (Object.keys(res).length === 0) {
            // The response is empty
            if (isErrorEmployee) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          } else {
            // @ts-ignore
            if (res.error) {
              //dispatch(setType({ type: "physical_address_error" }));
              // @ts-ignore
              throw new Error(res.error);
            }
          }
        },
        {
          pending: "Saving your information...",
          success: `Your profile has been updated successfully`,
          error: `Error saving your information`,
        },
      );

      //dispatch(setType({ type: "basic_information_succ" }));
      router.push("/auth/logout");

      // Proceed with other actions or logic here
    } catch (error) {
      //dispatch(setType({ type: "physical_address_error" }));
      console.log("Error: ", error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isSuccessCompanyGroup) {
      handleCreateCompany(companyGroupData?.id);
    }
  }, [companyGroupData, isSuccessCompanyGroup]);

  useEffect(() => {
    if (isSuccessCompany) {
      handleCreateCompanyEmployee(companyGroupData?.id, companyName);
    }
  }, [companyData, isSuccessCompany]);

  useEffect(() => {
    if (companyName && selectedOption && selectedCompanyType) {
      // Trigger after company group is created successfully
      //handleCreateCompanyGroup();
    }
  }, [companyName, selectedOption]);

  return (
    <div className={"relative h-[888px] overflow-hidden bg-[#ffffff]"}>
      <img
        className="absolute bottom-0 left-0 h-auto w-full border-t-[2px] border-gold-400 sm:h-32 md:h-48 lg:h-64 xl:h-80"
        style={{ objectFit: "cover" }}
        src="/img/gold-pattern.png"
        alt="Footer Image"
      />
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-start gap-8 sm:gap-12">
        <div className="mt-10 w-[700px] max-w-md">
          <div className="rounded-md bg-white p-8 shadow-lg">
            <div className="space-y-6">
              {/* Input field for company name */}
              <div className="flex justify-between">
                <Input
                  className="w-full text-gray-500 dark:text-gray-100"
                  value={companyName}
                  name="companyName"
                  onChange={handleChangeCompanyName}
                  variant="outlined"
                  type="text"
                  label="Company name"
                />
              </div>

              {/* Select dropdown for Tax Domicile */}
              <div className="mt-5 flex justify-between">
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  className="w-full rounded-lg border border-gray-300 p-3 text-gray-500"
                >
                  <option value="" disabled>
                    Tax Domicile
                  </option>
                  <option value="Zambia">Zambia</option>
                  <option value="option2">Tanzania</option>
                  <option value="option3">South Africa</option>
                  <option value="option3">Egypt</option>
                  <option value="option3">Kenya</option>
                </select>
              </div>

              {/* Select dropdown for Tax Domicile */}
              <div className="mt-5 flex justify-between">
                <select
                  id="dropdown"
                  value={selectedCompanyType}
                  onChange={handleSelectCompanyType}
                  className="w-full rounded-lg border border-gray-300 p-3 text-gray-500"
                >
                  <option value="" disabled>
                    Company Type
                  </option>
                  <option value="Company">Company</option>
                  <option value="Household">House Hold</option>
                </select>
              </div>

              {/* Button */}
              <Button
                className="mt-5 w-full p-3"
                onClick={handleCreateCompanyGroup}
                disabled={
                  isLoadingEmployee || isLoadingCompany || isLoadingCompanyGroup || isFormValid
                } // Disable if form is invalid or processing
              >
                {isLoadingEmployee || isLoadingCompany || isLoadingCompanyGroup
                  ? "Processing..."
                  : "Create new company"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCompany;
