import { useGetPayrollDetailsQuery } from "@/apis/payrollApiSlice";
import Accordion from "@/components/Accordion";
import { SpinnerLoader } from "@/components/SpinnerLoader";
import { RootState } from "@/redux/configureStore";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const items = [
  {
    title: "Atlas Mara",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_ATLAS_MARA_BANK,
      branchName: "Lusaka Corporate",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_ATLAS_MARA_BANK,
    },
  },
  {
    title: "Barclays",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_BARCLAYS_BANK,
      branchName: "Lusaka Business Centre",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_BARCLAYS_BANK,
    },
  },
  {
    title: "First National Bank",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_FNB_BANK,
      branchName: "COMMERCIAL",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_FNB_BANK,
    },
  },
  {
    title: "Stanbic Bank",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_STANBIC_BANK,
      branchName: "Lusaka",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_STANBIC_BANK,
    },
  },
  {
    title: "Zanaco",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_ZANACO_BANK,
      branchName: "Cairo Business Centre",
      branchCode:process.env.REACT_APP_TILT_BRANCH_CODE_ZANACO_BANK,
    },
  },
  {
    title: "ECO Bank",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_ECO_BANK,
      branchName: "Thabo Mbeki",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_ECO_BANK,
    },
  },
  {
    title: "ZICB",
    content: {
      accountNumber: process.env.REACT_APP_TILT_ACCOUNT_NUMBER_ZICB_BANK,
      branchName: "Lusaka Business Centre",
      branchCode: process.env.REACT_APP_TILT_BRANCH_CODE_ZICB_BANK,
    },
  },
];

const AccountDetailsView = () => {
  const { tenantId } = useSelector((state: RootState) => state.app_reducer.headers);
  const [totalPayroll, setTotalPayroll] = useState<number>(0);

  return (
    <div className="col-span-2 mt-5 h-[500px] w-full animate-fade-up overflow-y-auto p-5">
      <div className="border-m3-sys-light-inverse-on-surface relative flex flex-col items-start justify-start gap-3 border border-solid p-6">
        <div
          className="text-left font-['Montserrat-Medium',_sans-serif] text-[22px] font-medium leading-6 text-[#000000]"
          style={{ letterSpacing: "0.15px" }}
        >
          Select your preferred account details to fund your account
        </div>
        <div
          className="text-left font-['Montserrat-Regular',_sans-serif] text-[15px] font-normal leading-5 text-[#000000]"
          style={{ letterSpacing: "0.25px" }}
        >
          For easy transactions, you can fund your payroll by sending the funds to any bank on this
          list.
        </div>
        <div
          className="text-left font-['-',_sans-serif] text-[15px] font-normal leading-5 text-[#1e1e1e]"
          style={{ letterSpacing: "0.25px" }}
        >
          <span className="font-size font-bold text-red-500">PLEASE NOTE:</span>
          <span className="font-bold text-red-500">
            Failure to follow funding instruction may result in delayed payroll processing.
          </span>
        </div>
        <Accordion items={items} />
      </div>
    </div>
  );
};

export default AccountDetailsView;
