import React, { ReactNode, useEffect, useRef, useState } from "react";
import { LAUNCH_DARKLY_CLIENT_ID } from "@/constants/common.constants";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { MantineProvider } from "@mantine/core";
import { useRouter } from "next/router";
import Sidenav from "./sidenav";
import Header from "./header";
import Login from "@/pages/auth/login";
import { RootState } from "@/redux/configureStore";
import { useDispatch, useSelector } from "react-redux";
import SelectCompany from "@/pages/auth/select-company";
import useStorage from "@/hooks/useStorage";
import { useColorMode } from "@/hooks/useColorMode";
import { changeTheme } from "@/redux/features/theme";
import LoadingBar from "react-top-loading-bar";
import EmployeeSidebar from "./employeeSidebar";
import NotificationView from "../Notifications";
import UserFileUpload from "@/pages/user-information-upload";
import SubscriptionView from "@/pages/auth/subscription";
import PlanList from "@/pages/plans";

interface RootLayoutProps {
  children: ReactNode;
}

const RootLayout = ({ children }: RootLayoutProps) => {
  const [mobileNav, setMobileNav] = useState(false);
  const [alwaysOpen, setAlwaysOpen] = useState(false);
  const [mobileNavToggleState, setMobileNavToggleState] = useState(false);
  const { isLoggedIn } = useSelector((state: RootState) => state.app_reducer.user);
  const { theme } = useSelector((state: RootState) => state.app_reducer.app_theme);
  const { isCompanySet } = useSelector((state: RootState) => state.app_reducer.companyInfo);
  const { role } = useSelector((state: RootState) => state.app_reducer.activeEmployee);
  const { hasSubscription } = useSelector(
    (state: RootState) => state.app_reducer.companySubscriptionStateReducer,
  );

  const ref = useRef(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const { getItem } = useStorage();
  const { colorTheme } = useColorMode(getItem("theme"));

  // Adding event listeners to update loading bar on route changes
  useEffect(() => {
    const handleStart = () => {
      //@ts-ignore
      ref.current.continuousStart();
    };
    const handleComplete = () => {
      //@ts-ignore
      ref.current.complete();
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  // Applying theme changes based on user interaction
  useEffect(() => {
    dispatch(changeTheme({ theme: colorTheme }));
  }, [alwaysOpen]);

  // Rendering the appropriate components based on user roles and authentication status
  if (isLoggedIn) {
    if (isCompanySet) {
      if (hasSubscription) {
        return (
          // LDProvider for LaunchDarkly feature flag management
          <LDProvider clientSideID={LAUNCH_DARKLY_CLIENT_ID!} options={{ streaming: true }}>
            <LoadingBar color="#FF6C37" height={3} ref={ref} />
            <MantineProvider
              theme={{
                colorScheme: theme === "light" ? "light" : "dark",
                primaryColor: "blue",
              }}
            >
              <NotificationView />
              <div className=" h-full min-h-screen w-full overflow-hidden overflow-y-auto bg-gray-200 dark:bg-gray-950  dark:text-white">
                {role === "admin" && (
                  <>
                    {router.pathname === "/" ? (
                      // Displaying the Login component when on the root path
                      <Login />
                    ) : (
                      // Displaying the Sidenav, Header, and children components for admin users
                      <div className="flex min-h-screen w-full overflow-hidden">
                        <div className={`transition-all`}>
                          <Sidenav
                            alwaysOpen={alwaysOpen}
                            set_alwaysOpen={setAlwaysOpen}
                            mobileNav={mobileNav}
                            mobileNavState={mobileNavToggleState}
                            setMobileNavStateChange={setMobileNavToggleState}
                          />
                        </div>
                        <div className="max-h-screen w-full overflow-hidden">
                          <Header
                            setMobileNav={setMobileNav}
                            changeMobileNavToggleState={setMobileNavToggleState}
                          />
                          <main className="">{children}</main>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {role === "member" && (
                  <>
                    {router.pathname === "/" ? (
                      // Displaying the Login component when on the root path`
                      <Login />
                    ) : (
                      // Displaying the EmployeeSidebar, Header, and children components for member users
                      <div className="flex min-h-screen w-full overflow-hidden">
                        <div className={`transition-all`}>
                          <EmployeeSidebar
                            alwaysOpen={alwaysOpen}
                            set_alwaysOpen={setAlwaysOpen}
                            mobileNav={mobileNav}
                            mobileNavState={mobileNavToggleState}
                            setMobileNavStateChange={setMobileNavToggleState}
                          />
                        </div>
                        <div className="max-h-screen w-full overflow-hidden">
                          <Header
                            setMobileNav={setMobileNav}
                            changeMobileNavToggleState={setMobileNavToggleState}
                          />
                          <main className="">{children}</main>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </MantineProvider>
          </LDProvider>
        );
      } else {
        return (
          <div className="h-[100vh] bg-gray-150 dark:bg-gray-950 dark:text-white">
            <LoadingBar color="#FF6C37" ref={ref} />
            <PlanList />
          </div>
        );
      }
    } else {
      // Displaying the SelectCompany component when company is not set
      return (
        <div className="h-[100vh] bg-gray-150 dark:bg-gray-950 dark:text-white">
          <LoadingBar color="#FF6C37" ref={ref} />
          <SelectCompany />
        </div>
      );
    }
  }

  // Displaying user information upload screen for household
  if (router.pathname.includes("user-information-upload"))
    return (
      <div className="bg-gray-150 dark:bg-dark-950 dark:text-white">
        <UserFileUpload />
      </div>
    );
  // Displaying the Login component for users who are not logged in
  else
    return (
      <div className="bg-gray-150 dark:bg-dark-950 dark:text-white">
        <LoadingBar color="#FF6C37" ref={ref} />
        <Login />
      </div>
    );
};

export default RootLayout;
