import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface notificationTriggerState {
  id: string;
  notificationId: string;
  scopeType: "Role" | "Tenant" | "Group" | "User";
  scope: string;
  type: string;
  tenantId: string;
  summarizedMessage: string;
  message: string;
  markdownMessage: string;
  resourceUrl: string;
  createdAt: string;
}

// Initialize the notification state
const initialState: notificationTriggerState = {
  id: "",
  notificationId: "",
  scopeType: "Role",
  scope: "",
  type: "",
  tenantId: "",
  summarizedMessage: "",
  message: "",
  markdownMessage: "",
  resourceUrl: "",
  createdAt: "",
};

export const triggerNotification = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    /**
     *
     * @param state (optional) use this only if you want to change the state of the notification trigger
     * @param action used to pass the payload to the callback function
     * sends the notification to the app notification handler
     */
    Notify: (state: notificationTriggerState, action: PayloadAction<any>) => {
      state.id = action.payload.id;
      state.summarizedMessage = action.payload.summarizedMessage;
      state.message = action.payload.message;
      state.markdownMessage = action.payload.markdownMessage
      state.createdAt = action.payload.createdAt;
      state.notificationId = action.payload.notificationId;
      state.scope = action.payload.scope;
      state.resourceUrl = action.payload.resourceUrl;
      state.scopeType = action.payload.scopeType;
      state.tenantId = action.payload.tenantId;
      state.type = action.payload.type;
    },
    restTrigger: (state: notificationTriggerState, action: PayloadAction<any>) => {
      state.id = "";
      state.summarizedMessage = "";
      state.message = "";
      state.markdownMessage = "";
      state.createdAt = "";
      state.notificationId = "";
      state.scope = "";
      state.resourceUrl = "";
      state.scopeType = "Role";
      state.tenantId = "";
      state.type = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { Notify, restTrigger } = triggerNotification.actions;

export default triggerNotification.reducer;
