// Importing necessary modules and types
import { PayTypeConstants } from "@/constants/common.constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface Team {
  departmentId: string;
}
// Defining the shape of the form data state
interface formDataState {
  InvitedEmployeeName: string;
  InvitedEmployeeEmail: string;
  InvitedEmployeeTitle: string;
  InvitedEmploymentPayType: {
    type: string;
    label: string;
  };
  InvitedEmployeeStartDate: any;
  InvitedEmployeeTeam: Team[];
  InvitedEmploymentType: {
    type: string;
    label: string;
  };
  InvitedEmployeeFixedAmount: number;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  InvitedEmployeeName: "",
  InvitedEmployeeEmail: "",
  InvitedEmployeeTitle: "",
  InvitedEmploymentPayType: {
    type: PayTypeConstants.FixedRate.type,
    label: PayTypeConstants.FixedRate.label,
  },
  InvitedEmployeeStartDate: "",
  InvitedEmployeeTeam: [],
  InvitedEmploymentType: {
    type: "",
    label: "",
  },
  InvitedEmployeeFixedAmount: 0,
};

// Creating a Redux slice for managing Employee form data
export const EmployeeFormDataSlice = createSlice({
  name: "Invited-employee-onboarding-form-data",
  initialState,
  reducers: {
    // Reducer to set Employee name
    setInvitedEmployeeName: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeName = action.payload.InvitedEmployeeName;
    },
    // Reducer to set Employee email
    setInvitedEmployeeEmail: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeEmail = action.payload.InvitedEmployeeEmail;
    },
    // Reducer to set Employee email
    setInvitedEmployeeTitle: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeTitle = action.payload.InvitedEmployeeTitle;
    },
    // Reducer to set employee start date
    setInvitedEmployeeStartDate: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeStartDate = action.payload.InvitedEmployeeStartDate;
    },
    // Reducer to set employee's team
    setInvitedEmployeeTeam: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeTeam = action.payload.InvitedEmployeeTeam;
    },
    // Reducer to set employee employment pay type
    setInvitedEmploymentPayType: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmploymentPayType = action.payload.InvitedEmploymentPayType;
    },
    // Reducer to set employee employment type
    setInvitedEmploymentType: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmploymentType = action.payload.InvitedEmploymentType;
    },
    // Reducer to set employee fixed amount 
    setInvitedEmploymentFixedAmount: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeFixedAmount = action.payload.InvitedEmployeeFixedAmount;
    },
    // Reducer to set reset the form data state
    reSetForm: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeName = "";
      state.InvitedEmployeeEmail = "";
      state.InvitedEmployeeTitle = "";
      state.InvitedEmploymentPayType = {
        type: "",
        label: "",
      };
      state.InvitedEmployeeStartDate = "";
      state.InvitedEmployeeTeam = [];
      state.InvitedEmploymentType = {
        type: "",
        label: "",
      };
      state.InvitedEmployeeFixedAmount = 0;
    },
  },
});

// Exporting action creators and reducer
export const {
  setInvitedEmployeeName,
  setInvitedEmployeeEmail,
  setInvitedEmployeeTitle,
  setInvitedEmploymentPayType,
  setInvitedEmployeeStartDate,
  setInvitedEmployeeTeam,
  setInvitedEmploymentType,
  setInvitedEmploymentFixedAmount,
  reSetForm,
} = EmployeeFormDataSlice.actions;

export default EmployeeFormDataSlice.reducer;
