import { RootState } from "@/redux/configureStore";
import { setHouseholdEmployerFullName } from "@/redux/features/household/household-employer-tax-details";
import { setHouseholdEmployerFullNameError } from "@/redux/features/household/household-employer-tax-details-errors";
import { Input, Typography } from "@zigops-material/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const EmployerFullName = () => {
  const { employerFullName } = useSelector(
    (state: RootState) => state.app_reducer.employerTaxDetailsData,
  );

  const { employerFullNameError } = useSelector(
    (state: RootState) => state.app_reducer.employerTaxDetailsErrorData,
  );

  const dispatch = useDispatch();

  const onHandleChange = (event: { target: { value: any } }) => {
    dispatch(setHouseholdEmployerFullName({ employerFullName: event.target.value }));
    dispatch(setHouseholdEmployerFullNameError({ employerFullNameError: "" }));
  };

  return (
    <div className="grid w-full p-5 ">
      <div className="col-span-2 h-[340px] w-full animate-fade-up overflow-y-auto rounded-md border border-gray-300 bg-white p-5 dark:border-gray-750 dark:bg-gray-850 xl-max-screen:h-[480px]">
        <div className="mt-10">
          <Typography className="text-2xl text-gray-500">Enter your full name</Typography>
        </div>
        <div className="mt-6 w-[100%] md:w-[50%] lg:w-[50%]">
          <Input
            className="text-gray-500 dark:text-gray-100"
            value={employerFullName}
            name="employerFullName"
            onChange={onHandleChange}
            variant="standard"
            label="Your full name "
          />
          <div className="mt-2 text-red-300">{employerFullNameError}</div>
        </div>
      </div>
    </div>
  );
};

export default EmployerFullName;
