import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { server } from "@/config/server";
import getHeaders from "./headers";
import { TiltInstitution, TiltInstitutionResponse } from "@/types/payment-provider/Tilt";

export const tiltApiSlice = createApi({
    reducerPath: "tiltPayApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders,
    }),
    tagTypes: ["tiltPay"],
    endpoints: (builder) => ({
        getAvailableInstitutions: builder.query<TiltInstitutionResponse, any>({
            query: ({ country = "zm" }) => {
                return {
                    url: `tilt/resources/list-available-institutions/${country}`,
                    method: "GET",
                };
            },
            providesTags: [{ type: "tiltPay", id: "institutions" }]
        }),
    })
});

export const {
    useGetAvailableInstitutionsQuery
} = tiltApiSlice;