// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface SelectionTypes {
  type: string;
  label: string;
}

interface CountryTypes {
  flags: { png: string; svg: string };
  label: string;
  value: string;
}

interface employeeBasicData {
  dateOfBirth: any;
  gender: SelectionTypes;
  country: CountryTypes;
  maritalStatus: SelectionTypes;
  personalIdentityNumber: string;
}

// Initialize the initial state of the form data
const initialState: employeeBasicData = {
  dateOfBirth: "",
  gender: {
    type: "",
    label: "",
  },
  country: {
    flags: { png: "", svg: "" },
    label: "",
    value: "",
  },
  maritalStatus: {
    type: "",
    label: "",
  },
  personalIdentityNumber: "",
};

export const employeeBasicFormDataReducer = createSlice({
  name: "employee-basic-form-data",
  initialState,
  reducers: {
    setEmployeeDateOfBirth: (state: employeeBasicData, action: PayloadAction<any>) => {
      state.dateOfBirth = action.payload.dateOfBirth;
    },
    setEmployeeGender: (state: employeeBasicData, action: PayloadAction<any>) => {
      state.gender = action.payload.gender;
    },
    setEmployeeCountry: (state: employeeBasicData, action: PayloadAction<any>) => {
      state.country = action.payload.country;
    },
    setEmployeeMaritalStatus: (state: employeeBasicData, action: PayloadAction<any>) => {
      state.maritalStatus = action.payload.maritalStatus;
    },
    setPersonalIdentityNumber: (state: employeeBasicData, action: PayloadAction<any>) => {
      state.personalIdentityNumber = action.payload.personalIdentityNumber;
    },
  },
});

// Exporting action creators and reducer
export const {
  setEmployeeDateOfBirth,
  setEmployeeGender,
  setEmployeeCountry,
  setEmployeeMaritalStatus,
  setPersonalIdentityNumber,
} = employeeBasicFormDataReducer.actions;

export default employeeBasicFormDataReducer.reducer;
