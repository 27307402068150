import { DatesRangeValue } from "@mantine/dates";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LeaveType } from "@/types/Leave";

interface CreateLeaveRequestFormData {
    startDate?: string;
    endDate?: string;
    dateRange?: DatesRangeValue | null,
    note?: string;
    leaveType?: LeaveType;
}

const initialState: CreateLeaveRequestFormData = {
    startDate: "",
    endDate: "",
    dateRange: [null, null],
    note: "",
    leaveType: null
};

export const createLeaveRequestFormReducer = createSlice({
    name: "create-leave-request-form-data",
    initialState: initialState,
    reducers: {
        setStartDate: (state: CreateLeaveRequestFormData, action: PayloadAction<CreateLeaveRequestFormData>) => {
            state.startDate = action.payload.startDate
        },
        setEndDate: (state: CreateLeaveRequestFormData, action: PayloadAction<CreateLeaveRequestFormData>) => {
            state.endDate = action.payload.endDate;
        },
        setDateRange: (state: CreateLeaveRequestFormData, action: PayloadAction<CreateLeaveRequestFormData>) => {
            state.dateRange = action.payload.dateRange
        },
        setNote: (state: CreateLeaveRequestFormData, action: PayloadAction<CreateLeaveRequestFormData>) => {
            state.note = action.payload.note
        },
        setLeaveType: (state: CreateLeaveRequestFormData, action: PayloadAction<CreateLeaveRequestFormData>) => {
            state.leaveType = action.payload.leaveType
        }
    }
});

export const { setStartDate, setEndDate, setDateRange, setNote, setLeaveType } = createLeaveRequestFormReducer.actions;
export default createLeaveRequestFormReducer.reducer;