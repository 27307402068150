import { UserClaims } from "@/types/Claims";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState extends UserClaims {
  isLoggedIn: boolean;
  accessToken: string;
}

const initialState: UserState = {
  isLoggedIn: false,
  accessToken: "",
  environment: "",
  homeAccountId: "",
  idTokenClaims: {
    acr: "",
    aud: "",
    auth_time: 0,
    email: "",
    exp: 0,
    family_name: "",
    given_name: "",
    iat: 0,
    isForgotPassword: false,
    iss: "",
    nbf: 0,
    nonce: "",
    sub: "",
    tid: "",
    ver: "",
    appTenantId: "",
    bearerToken: "",
  },
  name: "",
  nativeAccountId: "",
  localAccountId: "",
  tenantId: "",
  username: "",
};

export const userAuthSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedIn: (state: UserState, action: PayloadAction<any>) => {
      state.environment = action.payload.environment;
      state.homeAccountId = action.payload.homeAccountId;
      state.idTokenClaims = action.payload.idTokenClaims; 
      state.name = action.payload.name;
      state.nativeAccountId = action.payload.nativeAccountId;
      state.username = action.payload.username;
      state.accessToken = action.payload.accessToken;
      state.tenantId = action.payload.tenantId;
      state.localAccountId = action.payload.localAccountId;
      state.isLoggedIn = true;
    },
    userLoggedOut: (state: UserState, action: PayloadAction<any>) => {
      state.environment = "";
      state.homeAccountId = "";
      state.idTokenClaims = {
        acr: "",
        aud: "",
        auth_time: 0,
        email: "",
        exp: 0,
        family_name: "",
        given_name: "",
        iat: 0,
        isForgotPassword: false,
        iss: "",
        nbf: 0,
        nonce: "",
        sub: "",
        tid: "",
        ver: "",
        appTenantId: "",
        bearerToken: "",
      };
      state.name = "";
      state.nativeAccountId = "";
      state.tenantId = "";
      state.localAccountId = "";
      state.username = "";
      state.accessToken = "";
      state.isLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userLoggedIn, userLoggedOut } = userAuthSlice.actions;

export default userAuthSlice.reducer;
