// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface SelectionTypes {
  type: string;
  label: string;
}

interface CountryTypes {
  flags: { png: string; svg: string };
  label: string;
  value: string;
}

interface StateTypes {
  label: string;
  value: string;
}

interface employeePhysicalAddressData {
  countryAddress: CountryTypes;
  state: StateTypes;
  physicalAddress: string;
  postalCode: string;
  employeePhoneNumber: string;
  city: string;
}

// Initialize the initial state of the form data
const initialState: employeePhysicalAddressData = {
  countryAddress: {
    flags: { png: "", svg: "" },
    label: "",
    value: "",
  },
  state: {
    label: "",
    value: "",
  },
  physicalAddress: "",
  postalCode: "",
  employeePhoneNumber: '',
  city: ''
};

export const employeePhysicalAddressFormDataReducer = createSlice({
  name: "employee-physical-address-form-data",
  initialState,
  reducers: {
    setEmployeeCountryAddress: (state: employeePhysicalAddressData, action: PayloadAction<any>) => {
      state.countryAddress = action.payload.countryAddress;
    },
    setEmployeeStateAddress: (state: employeePhysicalAddressData, action: PayloadAction<any>) => {
      state.state = action.payload.state;
    },
    setEmployeePhysicalAddress: (
      state: employeePhysicalAddressData,
      action: PayloadAction<any>,
    ) => {
      state.physicalAddress = action.payload.physicalAddress;
    },
    setEmployeePostalCode: (state: employeePhysicalAddressData, action: PayloadAction<any>) => {
      state.postalCode = action.payload.postalCode;
    },
    setEmployeePhoneNumber: (state: employeePhysicalAddressData, action: PayloadAction<any>) => {
      state.employeePhoneNumber = action.payload.employeePhoneNumber;
    },
    setEmployeeCity: (state: employeePhysicalAddressData, action: PayloadAction<any>) => {
      state.city = action.payload.city;
    },
  },
});

// Exporting action creators and reducer
export const {
  setEmployeeCountryAddress,
  setEmployeeStateAddress,
  setEmployeePhysicalAddress,
  setEmployeePostalCode,
  setEmployeePhoneNumber,
  setEmployeeCity,
} = employeePhysicalAddressFormDataReducer.actions;

export default employeePhysicalAddressFormDataReducer.reducer;
