import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = typeof window !== "undefined" && window.navigator.userAgent;
// const msie = ua.indexOf("MSIE ");
// const msie11 = ua.indexOf("Trident/");
// const msedge = ua.indexOf("Edge/");
// const firefox = ua.indexOf("Firefox");
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    editProfile: "B2C_1_ProfileEditPolicy",
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_SIGN_IN_FIRST_POLICY,
      invite: process.env.REACT_APP_INVITATION_POLICY,
    },
    editProfile: {
      authority: "",
    },
  },
  authorityDomain: process.env.REACT_B2C_AUTHORITY_BASE_DOMAIN,

  
};

// Config object to be passed to Msal on creation

export const policies = {
  signInFirst: process.env.REACT_APP_SIGN_IN_FIRST_POLICY,
  signUp: process.env.REACT_APP_SIGN_UP_POLICY,
  invite: process.env.REACT_APP_INVITATION_POLICY,
};

// Config object to be passed to Msal on creation
export const msalInvitationConfig = (email: string, jwt: string) => {
  const extraParams = {
    client_assertion: jwt,
  };
  return {
    scopes: ["openid"],
    auth: {
      clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
      clientSecret: process.env.AZURE_AD_B2C_CLIENT_SECRET,
      prompt: "select_account",
      authority: policies.invite,
      loginHint: email,
      knownAuthorities: [b2cPolicies.authorityDomain],
      extraQueryParameters: extraParams,
      redirectUri: `${process.env.REACT_APP_REDIRECT_URL}`,
    },
    system: {
      loggerOptions: {
        loggerCallback(loglevel: any, message: any, containsPii: any) {
          // console.log(message);
        },
        piiLoggingEnabled: false,
        logLevel: 0,
      },
    },
  };
};

export const msalConfig = {
  scopes: ["openid"],
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    clientSecret: process.env.AZURE_AD_B2C_CLIENT_SECRET,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
  },
  system: {
    loggerOptions: {
      loggerCallback(loglevel: any, message: any, containsPii: any) {
        // console.log(message);
      },
      piiLoggingEnabled: false,
      logLevel: 0,
    },
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: [
    "https://zigopstaging.b2clogin.com/zigopstaging.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNUP_SIGNIN",
  ],
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: [
    "https://zigopstaging.b2clogin.com/zigopstaging.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNUP_SIGNIN",
  ],
  uri: "https://msidlabb2c.onmicrosoft.com/msidlabb2capi",
};
