// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
export interface ISubscriptionState {
  planCost: string;
  subscriptionId: string;
  planPriceId: string;
  status: string;
  dateCreated: string;
}

// Initialize the initial state of the form data
const initialState: ISubscriptionState = {
  planCost: "",
  subscriptionId: "",
  planPriceId: "",
  status: "",
  dateCreated: "",
};

// Creating a Redux slice for managing Employee form data
export const TenantSubscriptionDetailsDataSlice = createSlice({
  name: "Tenant-subscription-details-data",
  initialState,
  reducers: {
    // Reducer to set Plan Id
    setTenantSubscriptionDetails: (state: ISubscriptionState, action: PayloadAction<ISubscriptionState>) => {
      state.planCost = action.payload.planCost;
      state.subscriptionId = action.payload.subscriptionId;
      state.planPriceId = action.payload.planPriceId;
      state.status = action.payload.status;
      state.dateCreated = action.payload.dateCreated;
    },
  },
});

// Exporting action creators and reducer
export const { setTenantSubscriptionDetails } = TenantSubscriptionDetailsDataSlice.actions;

export default TenantSubscriptionDetailsDataSlice.reducer;
