import React, { useEffect, useState, useCallback, use } from "react";
import { useRouter } from "next/router";
import { useGetPlanDetailsQuery } from "@/apis/plansApiSlice";
import { useCreateSubscriptionCustomerMutation, useCreateSubscriptionMutation, useCreateTempPaymentMutation, useGetTenantAccessContextQuery } from "@/apis/subscriptionApiSlice";
import Button from "@/zigops/zigops-material-react/src/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import { Typography } from "@zigops-material/react";
import {
  setSubcriptionCreationPlanId,
  setSubsriptionCreationPlanName,
  setSubsriptionCreationPlanPriceId,
} from "@/redux/features/subscription/subscription-creation-data";
import { HTTP_STATUS_CODE_409 } from "@/constants/common.constants";
import { SpinnerLoader } from "@/components/SpinnerLoader";
import SubscriptionComplete from "./subscriptionComplete";

const PlanDetail = () => {
  const [isSubCreated, setIsSubCreated] = useState<boolean>(false);
  const [isSuccessCreatingCustomer, setIsSuccessCreatingCustomer] = useState<boolean>(false);
  const [isCompanySubscribedToPlan, setIsCompanySubscribedToPlan] = useState<boolean>(true);

  const router = useRouter();
  const { id } = router.query;

  const dispatch = useDispatch();
  const { TenantId, DisplayName: companyName, type } = useSelector((state: RootState) => state.app_reducer.companyInfo);

  const {
    isLoading: isLoadingTenantAccessContext,
    data: tenantAccessContext,
    isSuccess: isSuccessLoadingTenantAccessContext
  } = useGetTenantAccessContextQuery(TenantId);

  const {
    data: plan,
    isLoading: isLoadingPlan,
    isSuccess: isSuccessLoadingPlan
  } = useGetPlanDetailsQuery({ planId: id, currency: "USD" }, { skip: !!tenantAccessContext && isCompanySubscribedToPlan });

  const [
    createSubscriptionMutation,
    { isLoading: isLoadingSubscription, isSuccess: isSuccessSubscription },
  ] = useCreateSubscriptionMutation();

  const [
    createTempPaymentMutation,
    { isSuccess: isSuccessTempPayment, isLoading: isLoadingTempPayment },
  ] = useCreateTempPaymentMutation();

  const [
    createSubscriptionCustomerMutation,
    { isLoading: isCreatingCustomer }
  ] = useCreateSubscriptionCustomerMutation();

  //Create customer
  const createCustomer = async () => {
    try {
      const response = await createSubscriptionCustomerMutation(TenantId).unwrap();
      console.log("plan customer",plan)
      setIsSuccessCreatingCustomer(true);
      handleTempPayment();
    } catch (error) {
      if (error?.status === HTTP_STATUS_CODE_409) {
        setIsSuccessCreatingCustomer(true); // Customer already exists
        handleTempPayment();
      } else {
        setIsSuccessCreatingCustomer(false);
      }
    }
  };

  const setSubscriptionData = useCallback(() => {
    dispatch(setSubcriptionCreationPlanId({ planId: id }));
    dispatch(setSubsriptionCreationPlanPriceId({ planPriceId: plan?.planPrice?.planPriceId }));
    dispatch(setSubsriptionCreationPlanName({ planName: plan?.plan?.name }));
  }, [dispatch, id, plan]);

  useEffect(() => {
    if (isSuccessLoadingTenantAccessContext) {
      if (isCompanySubscribed(id?.toString() || "", tenantAccessContext)) {
        if (type === "Company") {
          router.push("/admin/FirstImpression");
        } else {
          router.push("/household/first-impression");
        }
        setIsCompanySubscribedToPlan(true);
      } else {
        setIsCompanySubscribedToPlan(false);
      }
    }
  }, [tenantAccessContext, isSuccessLoadingTenantAccessContext, id, type, router, plan]);

 const isCompanySubscribed = useCallback((planId: string, tenantAccessContext: any) => {
    return tenantAccessContext.status === "Success" &&
      tenantAccessContext.message === "Subscription found" &&
      tenantAccessContext.data.planId === planId;
  }, []);
  //Create temp payment details
  const handleTempPayment = async () => {
      try {
        const tempPaymentResponse = await createTempPaymentMutation(TenantId).unwrap();
        if (tempPaymentResponse) {
          try {
            await createSubscriptionMutation({
              tenantId: TenantId,
              planId: plan?.plan?.planId,
              planPriceId: plan?.planPrice?.planPriceId,
            });
            //router.push("/success"); // Redirect to a success page
          } catch (subscriptionError) {
            showErrorMessage(
              "Failed to create your subscription. Please try again or contact support."
            );
          }
        } else {
          showWarningMessage(
            "Temporary payment was unsuccessful. Please check your payment details and try again."
          );
        }
      } catch (tempPaymentError) {
        showErrorMessage(
          "Failed to process your payment. Please check your connection and try again."
        );
        // Optional: Offer to retry
        setRetryAction(() => handleTempPayment());
      }
    };
    
    // Example function to display user-friendly error messages
    const showErrorMessage = (message: string) => {
      // Display error message to the user
      console.log("Error: ",message); // Simplified for example; use a modal or toast in a real app
    };
    
    // Example function to display warning messages
    const showWarningMessage = (message: string) => {
      // Display warning to the user
      console.log("Warning",message); // Simplified for example; use a modal or toast in a real app
    };
    
    // Optional retry function setup
    const setRetryAction = (retryAction: () => void) => {
      // Provide users an option to retry the action
      if (confirm("Would you like to try again?")) {
        retryAction();
      }
    };

  useEffect((

  ) => { },[]);

  useEffect(() => {
    if (isSuccessCreatingCustomer) {
      setSubscriptionData();
      setIsSubCreated(true);
    }
  }, [isSuccessCreatingCustomer, setSubscriptionData]);

  return (
    <>
      {(isLoadingPlan || isCreatingCustomer || isLoadingTenantAccessContext) && !isSuccessLoadingPlan ? (
        <SpinnerLoader text="Loading, please wait..." />
      ) : isSubCreated ? (
        <SubscriptionComplete />
      ) : !isCompanySubscribedToPlan ? (
        <>
          <div className="absolute top-0 mb-10 flex flex-col items-center justify-center">
            <img
              className="mb-5 h-[150px] w-[109.83px]"
              style={{ objectFit: "cover" }}
              src="/img/Main-Logo-Social.png"
              alt="Main Logo"
            />
            <Typography className="mb-8 pb-4 text-center text-xl font-semibold">
              Account Information
            </Typography>
          </div>

          <img
            className="absolute bottom-0 left-0 h-auto w-full border-t-[2px] border-gold-400 sm:h-32 md:h-48 lg:h-64 xl:h-80"
            style={{ objectFit: "cover" }}
            src="/img/gold-pattern.png"
            alt="Footer Image"
          />
          <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-start gap-8 sm:gap-12">
            <div className="mt-10 w-[700px] max-w-md">
              <div className="rounded-md bg-white p-8 shadow-lg">
                <div className="space-y-6">
                  <div className="flex justify-between">
                    <Typography className="font-semibold">Your Plan</Typography>
                    <Typography
                      className="cursor-pointer text-sm text-primary"
                      onClick={() => router.push("auth/subscription")}
                    >
                      Edit
                    </Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography className="text-gray-600">For</Typography>
                  </div>
                  <div className="flex justify-between">
                    <Typography className="font-semibold">{companyName}</Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography className="text-gray-600">Plan</Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography className="font-semibold">{plan?.plan?.name}</Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography className="text-gray-600">Billing</Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography className="font-semibold">Monthly</Typography>
                    <Typography
                      className="cursor-pointer text-xs text-primary underline"
                      onClick={() => { }}
                    >
                      Switch to annual and save $250/yr
                    </Typography>
                  </div>

                  <div className="my-4 border-t border-gray-300"></div>

                  <div className="flex justify-between">
                    <Typography className="font-semibold">Due today</Typography>
                    <Typography className="font-semibold">
                      ${plan?.planPrice?.planPrice} {plan?.planPrice?.currency}
                    </Typography>
                    <div className="text-m3-sys-light-inverse-surface relative mb-10 text-left font-['Montserrat-Regular',_sans-serif] text-xs font-normal leading-6">
                      + applicable tax
                    </div>
                  </div>
                  <Button
                    className="w-full p-3"
                    onClick={createCustomer}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Typography className="text-center text-xl">You are already subscribed to this plan.</Typography>
      )}
    </>
  );
};

export default PlanDetail;
