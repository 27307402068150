// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit
import { setIn } from "formik";

// Defining the shape of the form data state
interface formDataState {
  InvitedEmployeeNameError: string;
  InvitedEmployeeEmailError: string;
  InvitedEmployeeTitleError: string;
  InvitedEmployeeStartDateError: string;
  EmployeePhysicalAddressError: string;
  InvitedEmployeeFixedAmountError: string;

}

// Initialize the initial state of the form data
const initialState: formDataState = {
  InvitedEmployeeNameError: "",
  InvitedEmployeeEmailError: "",
  InvitedEmployeeTitleError: "",
  InvitedEmployeeStartDateError: "",
  EmployeePhysicalAddressError: "",
  InvitedEmployeeFixedAmountError: "",
};

// Creating a Redux slice for managing Employee form data
export const EmployeeErrorsFormDataSlice = createSlice({
  name: "Invited-Errors-employee-form-data",
  initialState,
  reducers: {
    // Reducer to set Employee name error
    setInvitedEmployeeNameError: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeNameError = action.payload.InvitedEmployeeNameError;
    },
    // Reducer to set Employee email error
    setInvitedEmployeeEmailError: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeEmailError = action.payload.InvitedEmployeeEmailError;
    },
    // Reducer to set Employee title error
    setInvitedEmployeeTitleError: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeTitleError = action.payload.InvitedEmployeeTitleError;
    },
    // Reducer to set StartDate error
    setInvitedEmployeeStartDateError: (state: formDataState, action: PayloadAction<any>) => {
      state.InvitedEmployeeStartDateError = action.payload.InvitedEmployeeStartDateError;
    },
    // Reducer to set Employee Phone
    setInvitedEmployeePhysicalAddressError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeePhysicalAddressError = action.payload.EmployeePhysicalAddressError;
    },

    setInvitedEmployeeFixedAmountError: (state: formDataState, action: PayloadAction<any>) => { 
      state.InvitedEmployeeFixedAmountError = action.payload.InvitedEmployeeFixedAmountError;
    } 
  },
});

// Exporting action creators and reducer
export const {
  setInvitedEmployeeNameError,
  setInvitedEmployeeEmailError,
  setInvitedEmployeeTitleError,
  setInvitedEmployeeStartDateError,
  setInvitedEmployeePhysicalAddressError,
  setInvitedEmployeeFixedAmountError,
} = EmployeeErrorsFormDataSlice.actions;

export default EmployeeErrorsFormDataSlice.reducer;
