import React from "react";

const BillingHistory = () => {
  return (
    <div
      className={
        "flex flex-col gap-9 items-start justify-start relative "
      }
    >
      <div className="flex flex-col gap-[27px] items-start justify-start shrink-0 relative">
        <div className="flex flex-row gap-[312px] items-center justify-start shrink-0 relative">
          <div className="flex flex-col gap-4 items-start justify-start shrink-0 relative">
            <div className="p-[5px] flex flex-row gap-5 items-center justify-start self-stretch shrink-0 relative">
              <div className="text-m3-sys-light-on-surface-variant text-left font-['Montserrat-SemiBold',_sans-serif] text-[28px] leading-9 font-semibold relative">
                Billing History{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={"shrink-0 h-14 relative "}>
      <div className="flex flex-row gap-2 items-center justify-start absolute left-0 top-px">

      </div>
      <div className="rounded border-solid border-gray-300 border flex flex-row gap-0 items-center justify-start absolute left-[calc(50%_-_-216px)] top-3 overflow-hidden">
        <div className="bg-white pt-1.5 pr-3 pb-1.5 pl-3 flex flex-col gap-0 items-center justify-center self-stretch shrink-0 relative">

        </div>
        <div className="bg-white border-solid border-gray-300 border pt-1.5 pr-3 pb-1.5 pl-3 flex flex-col gap-0 items-center justify-center self-stretch shrink-0 relative">
          <div
            className="text-gray-500 text-center font-m3-label-small-font-family text-m3-label-small-font-size leading-m3-label-small-line-height font-m3-label-small-font-weight relative flex items-center justify-center"
            style={{
              letterSpacing: "var(--m3-label-small-letter-spacing, 0.5px)",
            }}
          >
            1-50 of 100{" "}
          </div>
        </div>
        <div className="bg-white border-solid border-gray-300 border pt-1.5 pr-3 pb-1.5 pl-3 flex flex-col gap-0 items-center justify-center self-stretch shrink-0 relative">
         
        </div>
      </div>
    </div>

      </div>
      <div className="flex flex-col gap-[15px] items-start justify-start shrink-0 relative">
        <div className="shrink-0 w-[637.7px] h-4 static">
          <div
            className="text-m3-ref-neutral-neutral30 text-left font-m3-label-medium-font-family text-m3-label-medium-font-size leading-m3-label-medium-line-height font-m3-label-medium-font-weight absolute left-0 top-0 w-[56.23px]"
            style={{
              letterSpacing: "var(--m3-label-medium-letter-spacing, 0.5px)",
            }}
          >
            Invoice{" "}
          </div>
          <div
            className="text-m3-ref-neutral-neutral30 text-left font-m3-label-medium-font-family text-m3-label-medium-font-size leading-m3-label-medium-line-height font-m3-label-medium-font-weight absolute left-[296px] top-0"
            style={{
              letterSpacing: "var(--m3-label-medium-letter-spacing, 0.5px)",
            }}
          >
            Billing Date{" "}
          </div>
          <div
            className="text-m3-ref-neutral-neutral30 text-left font-m3-label-medium-font-family text-m3-label-medium-font-size leading-m3-label-medium-line-height font-m3-label-medium-font-weight absolute left-[453px] top-0"
            style={{
              letterSpacing: "var(--m3-label-medium-letter-spacing, 0.5px)",
            }}
          >
            Amount{" "}
          </div>
          <div
            className="text-m3-ref-neutral-neutral30 text-left font-m3-label-medium-font-family text-m3-label-medium-font-size leading-m3-label-medium-line-height font-m3-label-medium-font-weight absolute left-[603px] top-0 w-[34.7px]"
            style={{
              letterSpacing: "var(--m3-label-medium-letter-spacing, 0.5px)",
            }}
          >
            Plan{" "}
          </div>
        </div>
        <div className="p-[5px] flex flex-col gap-[5px] items-start justify-start shrink-0 h-[460px] relative">

        </div>
      </div>
    </div>
  );

};

export default BillingHistory;
