import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/configureStore";
import {
  useGetSubscriptionsQuery,
  useLazyGetSubscriptionDetailQuery,
} from "@/apis/subscriptionApiSlice";
import BillingHistory from "./BillingHistory";
import { format } from "date-fns";
import UpdateCardDetails from "@/pages/auth/subscription/updateCardDetails";
import { Button } from "@zigops-material/react";

const BillingsAndSubscription = () => {
  const [openBillingHistory, setOpenBillingHistory] = useState<boolean>(false);
  const [subscriptionReady, setSubscriptionReady] = useState<boolean>(false);
  const [isPlanReady, setIsPlanReady] = useState<boolean>(false);
  const [updateCardDetails, setUpdateCardDetails] = useState<boolean>(false);

  const { DisplayName } = useSelector((state: RootState) => state.app_reducer.companyInfo);

  const { tenantId } = useSelector((state: RootState) => state.app_reducer.headers);
  const { data, isError, isLoading, isSuccess } = useGetSubscriptionsQuery(tenantId);

  const { description, name, planAppId, planId } = useSelector(
    (state: RootState) => state.app_reducer.tenantPlanDetailsData,
  );

  const { dateCreated, planCost, subscriptionId, planPriceId, status } = useSelector(
    (state: RootState) => state.app_reducer.tenantSubscriptionDetailsData,
  );

  const handleUpdateCardDetails = () => {
    setUpdateCardDetails(true);
  };

  return (
    <>
      {openBillingHistory ? (
        <BillingHistory />
      ) : updateCardDetails ? (
        <UpdateCardDetails />
      ) : (
        <>
          <div className="w-full animate-fade-left p-5 animate-once">
            <div
              className={
                "text-m3-sys-light-on-surface-variant relative text-left font-['Montserrat-SemiBold',_sans-serif] text-[28px] font-semibold leading-9 "
              }
            >
              Subscription{" "}
            </div>
          </div>
          <div
            className={"relative flex h-[856px] w-full flex-row items-start justify-start "}
            style={{ overflowY: "auto" }}
          >
            <div className="relative flex w-[50%] shrink-0 flex-col items-start justify-start gap-5 p-[5px]">
              <div className="relative flex w-[365px] shrink-0 flex-col items-start justify-start gap-[5px] pb-[5px] pt-[5px]">
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Account Name{" "}
                    </div>
                  </div>
                  <div className="relative flex w-full shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                      style={{
                        letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                      }}
                    >
                      {DisplayName}{" "}
                    </div>
                  </div>
                </div>
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Account I.D{" "}
                    </div>
                  </div>
                  <div className="relative flex w-full shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                      style={{
                        letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                      }}
                    >
                      {tenantId}{" "}
                    </div>
                  </div>
                </div>
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Plan{" "}
                    </div>
                  </div>
                  <div className="relative flex w-full shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                      style={{
                        letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                      }}
                    >
                      {name}
                    </div>
                  </div>
                </div>
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Subscription Status{" "}
                    </div>
                  </div>
                  <div className="relative flex w-full shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                      style={{
                        letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                      }}
                    >
                      {status == "Running" ? <>Active</> : <>Inactive</>}{" "}
                    </div>
                  </div>
                </div>
                <div className="relative flex w-[365px] shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Registration Date{" "}
                    </div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-start justify-start gap-0 self-stretch">
                    <div className="relative flex w-full shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                      <div
                        className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative w-full text-left"
                        style={{
                          letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                        }}
                      >
                        {format(new Date(dateCreated), "d MMMM yyyy")}
                        {""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative flex w-[365px] shrink-0 flex-col items-start justify-start gap-0">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[2px]">
                    <div
                      className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Billing{" "}
                    </div>
                  </div>
                  <div className="relative flex shrink-0 flex-row items-start justify-start gap-0 self-stretch">
                    <div className="relative flex w-[279px] shrink-0 flex-row items-center justify-start gap-5 p-[2px]">
                      <div
                        className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                        style={{
                          letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                        }}
                      >
                        Monthly{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex h-[400px] w-[365px] shrink-0 flex-col items-start justify-start gap-[25px] pb-[2px] pt-[2px]">
                <div className="relative flex shrink-0 flex-col items-start justify-start gap-[5px]">
                  <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 pb-[5px] pt-[5px]">
                    <div
                      className="text-m3-sys-light-inverse-surface relative text-left font-['Montserrat-Bold',_sans-serif] text-base font-bold leading-6"
                      style={{ letterSpacing: "0.15px" }}
                    >
                      Payment Information{" "}
                    </div>
                  </div>
                  <div className="relative flex shrink-0 flex-col items-start justify-start gap-[7px]">
                    <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                      <div
                        className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-sm font-medium leading-6"
                        style={{ letterSpacing: "0.15px" }}
                      >
                        Card Info{" "}
                      </div>
                    </div>
                    <div className="relative flex w-[279px] shrink-0 flex-row items-center justify-between p-[5px]">
                      <div className="relative flex w-[213px] shrink-0 flex-row items-center justify-start gap-5">
                        <div className="bg-m3-white border-m3-ref-neutral-neutral70 relative h-[25px] w-[50px] shrink-0 rounded-sm border-[0.5px] border-solid">
                          <img
                            className="absolute left-0 top-0 h-[25px] w-[50px] overflow-hidden"
                            style={{ objectFit: "cover" }}
                            src="frame-10000052530.png"
                          />
                        </div>
                        <div
                          className="text-m3-ref-neutral-neutral30 font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight relative text-left"
                          style={{
                            letterSpacing: "var(--m3-title-medium-letter-spacing, 0.15px)",
                          }}
                        >
                          **** **** **** 1234{" "}
                        </div>
                      </div>
                    </div>
                    <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                      <div
                        className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-xs font-medium leading-6"
                        style={{ letterSpacing: "0.15px" }}
                      >
                        Expiry 10/2024{" "}
                      </div>
                    </div>
                    <div className="relative flex shrink-0 flex-row items-center justify-start gap-5 p-[5px]">
                      <div
                        className="text-m3-ref-neutral-neutral50 relative text-left font-['Montserrat-Medium',_sans-serif] text-xs font-medium leading-6"
                        style={{ letterSpacing: "0.15px" }}
                      >
                        {/* New Payment Method{" "} */}
                        <Button onClick={handleUpdateCardDetails}>New Payment Method</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  "relative flex flex-col items-center justify-center gap-2 overflow-hidden rounded "
                }
              >
                <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 pb-2.5 pl-3 pr-3 pt-2.5">
                  <div
                    className="text-m3-sys-light-primary relative ml-10 flex items-center justify-center text-center font-['Montserrat-Medium',_sans-serif] text-base font-medium leading-5"
                    style={{ letterSpacing: "0.1px", textDecoration: "underline" }}
                    onClick={() => setOpenBillingHistory(true)}
                  >
                    View Billing History{" "}
                  </div>
                </div>
              </div>
              <div className="flex justify-center border-m3-sys-light-surface-variant relative ml-2 h-[161px] w-[400px] shrink-0 items-center overflow-hidden border border-solid bg-[#ffffff]">
              <div className="flex flex-col items-center justify-center gap-5">
                <div
                  className="text-m3-sys-light-inverse-surface relative text-left font-['Montserrat-Bold',_sans-serif] text-base font-bold leading-6"
                  style={{ letterSpacing: "0.15px" }}
                >
                  Manage Subscription
                </div>
                <div className="border-m3-sys-light-primary relative flex flex-col items-center justify-center gap-2 overflow-hidden rounded border border-solid">
                  <div className="relative flex shrink-0 flex-row items-center justify-center gap-2 pb-2.5 pl-6 pr-6 pt-2.5">
                    <div
                      className="text-m3-sys-light-primary relative flex items-center justify-center text-center font-['Montserrat-Medium',_sans-serif] text-base font-medium leading-5"
                      style={{ letterSpacing: "0.1px"}}
                    >
                     <Button>Change Plan</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BillingsAndSubscription;
