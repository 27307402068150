import { Radio, Typography } from "@zigops-material/react";
import React from "react";
import { useDispatch } from "react-redux";

const FundingSourceOptionView = () => {
  const dispatch = useDispatch(); // Getting the dispatch function from Redux

  // Function to handle changes in the employee email input field
  const onHandleChange = (event: { target: { value: any } }) => {
    //   dispatch(setHouseholdEmployeesNextOfKinTitle({ EmployeesNextOfKinTitle: event.target.value })); // Dispatching action to set employee email
    //   dispatch(setHouseholdEmployeeJobTitleError({ EmployeesNextOfKinTitleError: "" })); // Dispatching action to clear EmployeesNextOfKinTitleError error
  };
  return (
    <div>
        {/* Left column for entering EmployeesNextOfKinTitle */}
        <div className="col-span-2 mt-5 h-[470px] w-full animate-fade-up overflow-y-auto p-5 dark:border-gray-750 dark:bg-gray-850 xl-max-screen:h-[580px]">
          <div className="mt-10">
            <Typography className="text-2xl text-gray-500">Select your funding source</Typography>
          </div>
          <div className="mt-6 w-[50%]">
            <div className="">
              <Radio
                id="ripple-on"
                onChange={onHandleChange}
                name="type"
                value="MobileMoney"
                label="Mobile money."
                ripple={true}
              />
              <Radio
                id="ripple-off"
                onChange={onHandleChange}
                name="type"
                value="Bank"
                label="Banking"
                ripple={false}
              />
            </div>
            <div className="text-red-300">{""}</div>
          </div>
        </div>
      </div>
  );
};

export default FundingSourceOptionView;
