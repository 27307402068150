import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
// @ts-ignore
import immutableTransform from "redux-persist-transform-immutable";

import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";
import rootReducer from "./reducers";
import { apiSlice } from "@/apis/apiSlice";
import { companyApiSlice } from "@/apis/companyApiSlice";
import { holidayApiSlice } from "@/apis/holidayApiSlice";
import { setupListeners } from '@reduxjs/toolkit/query'

import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import { OnBoardingApiSlice } from "@/apis/OnBoardingSlice";
import { EmployeesApiSlice } from "@/apis/EmployeesApiSlice";
import { PayrollApiSlice } from "@/apis/payrollApiSlice";
import { PayrunsApiSlice } from "@/apis/payrunApiSlice";
import { payslipsApiSlice } from "@/apis/payslipApiSlice";
import { domesticTaxCollectionApiSlice } from "@/apis/uploadApiSlice";
import { subscriptionApiSlice } from "@/apis/subscriptionApiSlice";
import { firstImpressionApiSlice } from "@/apis/firstimpressionApiSlice";
import { leaveManagementApiSlice } from "@/apis/leaveRequestsApiSlice";
import { PlansApiSlice } from "@/apis/plansApiSlice";
import { leaveTypesApiSlice } from "@/apis/leaveTypesApiSlice";
import { NotificationsApiSlice } from "@/apis/notificationsApiSlice";
import { tiltApiSlice } from "@/apis/tiltApiSlice";


const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
  key: "root",
  transforms: [immutableTransform()],
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    app_reducer: persistedReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [companyApiSlice.reducerPath]: companyApiSlice.reducer,
    [holidayApiSlice.reducerPath]: holidayApiSlice.reducer,
    [OnBoardingApiSlice.reducerPath]: OnBoardingApiSlice.reducer,
    [EmployeesApiSlice.reducerPath]: EmployeesApiSlice.reducer,
    [PayrollApiSlice.reducerPath]: PayrollApiSlice.reducer,
    [PayrunsApiSlice.reducerPath]: PayrunsApiSlice.reducer,
    [payslipsApiSlice.reducerPath]: payslipsApiSlice.reducer,
    [domesticTaxCollectionApiSlice.reducerPath]: domesticTaxCollectionApiSlice.reducer,
    [subscriptionApiSlice.reducerPath]: subscriptionApiSlice.reducer,
    [firstImpressionApiSlice.reducerPath]: firstImpressionApiSlice.reducer,
    [leaveManagementApiSlice.reducerPath]: leaveManagementApiSlice.reducer,
    [PlansApiSlice.reducerPath]: PlansApiSlice.reducer,
    [leaveTypesApiSlice.reducerPath]: leaveTypesApiSlice.reducer,
    [NotificationsApiSlice.reducerPath]: NotificationsApiSlice.reducer,
    [tiltApiSlice.reducerPath]: tiltApiSlice.reducer
  },
  // apply additional middleware to the state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat([unauthenticatedMiddleware])
      .concat(apiSlice.middleware)
      .concat(companyApiSlice.middleware)
      .concat(holidayApiSlice.middleware)
      .concat(EmployeesApiSlice.middleware)
      .concat(OnBoardingApiSlice.middleware)
      .concat(PayrollApiSlice.middleware)
      .concat(PayrunsApiSlice.middleware)
      .concat(domesticTaxCollectionApiSlice.middleware)
      .concat(payslipsApiSlice.middleware)
      .concat(subscriptionApiSlice.middleware)
      .concat(firstImpressionApiSlice.middleware)
      .concat(leaveManagementApiSlice.middleware)
      .concat(PlansApiSlice.middleware)
      .concat(leaveTypesApiSlice.middleware)
      .concat(NotificationsApiSlice.middleware)
      .concat(tiltApiSlice.middleware)
});

// enable listener behavior for the store
setupListeners(store.dispatch)

// Configure the presistor middleware by passing the store
export const persistor = persistStore(store);

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof combinedReducer>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
