type StorageType = "session" | "local";
type UseStorageReturnValue = {
  getItem: (key: string, type?: StorageType) => string;
  setItem: (key: string, value: any, type?: StorageType) => boolean;
  removeItem: (key: string, type?: StorageType) => void;
};

const useStorage = (): UseStorageReturnValue => {
  const storageType = (type?: StorageType): "localStorage" | "sessionStorage" =>
    `${type ?? "session"}Storage`;

  const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

  const getItem = (key: string, type?: StorageType): any => {
    try {
      return isBrowser ? window.localStorage.getItem(key) : null;
    } catch (error) {}
  };

  const setItem = (key: string, value: any, type?: StorageType): boolean => {
    if (isBrowser) {
      // window[storageType(type)].setItem(key, value);'
      window.localStorage.setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key: string, type?: StorageType): void => {
    window.localStorage.removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export default useStorage;
