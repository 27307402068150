// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formErrorDataState {
  employerTPINError: string;
  employerNRCError: string;
  employerNAPSAError: string;
  
}

// Initialize the initial state of the form data
const initialState: formErrorDataState = {
  employerTPINError: "",
  employerNRCError: "",
  employerNAPSAError: "",
};

export const EmployerTaxFormDetailsErrorDataSlice = createSlice({
  name: "Household-collect-home-address-details",
  initialState,
  reducers: {
    setHouseholdEmployerTPINError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerTPINError = action.payload.employerTPINError;
    },
    setHouseholdEmployerNRCError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerNRCError = action.payload.employerNRCError;
    },
    setHouseholdEmployerNAPSAError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerNAPSAError = action.payload.employerNAPSAError;
    },
  },
});

// Exporting action creators and reducer
export const {
setHouseholdEmployerNRCError,
setHouseholdEmployerNAPSAError,
setHouseholdEmployerTPINError
} = EmployerTaxFormDetailsErrorDataSlice.actions;

export default EmployerTaxFormDetailsErrorDataSlice.reducer;
