import { server } from "@/config/server";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeaders from "./headers";
import { CreateLeaveRequestDto, LeaveDays, LeaveType } from "@/types/Leave";

export const leaveManagementApiSlice = createApi({
    reducerPath: "leaveManagementApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders
    }),
    tagTypes: ["LeaveRequests"],
    endpoints: (builder) => ({
        createLeaveRequest: builder.mutation<any, CreateLeaveRequestDto>({
            query: (leaveRequest: CreateLeaveRequestDto) => {
                return {
                    url: "leave-requests",
                    method: "POST",
                    body: leaveRequest
                };
            }
        }),
        getLeaveDaysByEmployeeIdAndLeaveTypeIdAndBusinessYear: builder.query<LeaveDays, any>({
            query: (params) => {
                return {
                    url: `leave-days?employeeId=${params.employeeId}&leaveTypeId=${params.leaveTypeId}&businessYear=${params.businessYear}`,
                    method: "GET"
                }
            }
        }),
        getLeaveTypeById: builder.query<LeaveType, number>({
            query: (leaveTypeId) => {
                return {
                    url: `leave-type/${leaveTypeId}`,
                    method: "GET"
                }
            }
        })
    })
})

export const {
    useCreateLeaveRequestMutation,
    useGetLeaveDaysByEmployeeIdAndLeaveTypeIdAndBusinessYearQuery,
    useGetLeaveTypeByIdQuery
} = leaveManagementApiSlice