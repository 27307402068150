import React, { useCallback, useEffect, useState } from "react";

export const useColorMode = (themeColor: string) => {
  const [theme, setTheme] = useState(themeColor);
  const colorTheme = theme === "dark" ? "light" : "dark";

  const applyTheme = useCallback((themeColor: string) => setTheme(themeColor), []);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
  }, [theme, colorTheme]);

  return { colorTheme, applyTheme };
};
