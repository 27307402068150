// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface fileUrl {
  file: any,
  fileURL: string,
}

interface formDataState {
  employerFullName: string;
  employerEmail: string;
  employerTpinUrl: fileUrl;
  employerNapsaUrl: fileUrl;
  employerNhimaUrl: fileUrl;
  employerPhoneNumber: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  employerFullName: "",
  employerEmail: "",
  employerPhoneNumber: "",
  employerTpinUrl: {
    file: null,
    fileURL: '',
  },
  employerNapsaUrl: {
    file: null,
    fileURL: '',
  },
  employerNhimaUrl: {
    file: null,
    fileURL: '',
  },
};

export const EmployerTaxDetailsDataSlice = createSlice({
  name: "Household-collect-tax-details",
  initialState,
  reducers: {
    setHouseholdEmployerFullName: (state: formDataState, action: PayloadAction<any>) => {
      state.employerFullName = action.payload.employerFullName;
    },
    setHouseholdEmployerEmail: (state: formDataState, action: PayloadAction<any>) => {
      state.employerEmail = action.payload.employerEmail;
    },
    setHouseholdEmployerPhoneNumber: (state: formDataState, action: PayloadAction<any>) => {
      state.employerPhoneNumber = action.payload.employerPhoneNumber;
    },

    setHouseholdEmployerTpinUrl: (state: formDataState, action: PayloadAction<any>) => {
      state.employerTpinUrl = action.payload.employerTpinUrl;
    },

    setHouseholdEmployerNapsaUrl: (state: formDataState, action: PayloadAction<any>) => {
      state.employerNapsaUrl = action.payload.employerNapsaUrl;
    },

    setHouseholdEmployerNhimaUrl: (state: formDataState, action: PayloadAction<any>) => {
      state.employerNhimaUrl = action.payload.employerNhimaUrl;
    },

    // Reducer to set reset the form data state
    reSetForm: (state: formDataState, action: PayloadAction<any>) => {
      state.employerFullName = "";
    },
  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployerFullName,
  setHouseholdEmployerEmail,
  setHouseholdEmployerPhoneNumber,
  setHouseholdEmployerTpinUrl,
  setHouseholdEmployerNapsaUrl,
  setHouseholdEmployerNhimaUrl,
  reSetForm,
} = EmployerTaxDetailsDataSlice.actions;

export default EmployerTaxDetailsDataSlice.reducer;
