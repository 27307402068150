import { v4 as uuid } from "uuid";
// Assuming useStorage returns { getItem, setItem }
import { RootState } from "@/redux/configureStore";

// Create a utility function for headers
// @ts-ignore
const getHeaders = (headers, { getState }) => {
  const headerInfo = (getState() as RootState).app_reducer.headers;

  headers.set("Authorization", `Bearer ${headerInfo.token}`);
  headers.set("x-correlation-id", headerInfo.correlationId ? headerInfo.correlationId : uuid());
  headers.set("Tenant", headerInfo.tenantId);
  headers.set("Content-Type", "application/json");
  headers.set("cache", "no-store");
  headers.set("next", { revalidate: 60 });
  headers.set("Cache-Control", "no-store, no-cache, must-revalidate, max-age=0");
  headers.set("Cache-Control", "post-check=0, pre-check=0", false);
  headers.set("Pragma", "no-cache");
  return headers;
};

export default getHeaders;
