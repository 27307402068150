// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface formDataState {
  employerTPIN: string;
  employerNRC: string;
  employerNAPSA: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  employerTPIN: "",
  employerNRC: "",
  employerNAPSA: "",
};

export const HouseHoldEmployerTaxFormDataSlice = createSlice({
  name: "Household-collect-tax-form-details",
  initialState,
  reducers: {
    setHouseholdEmployerTPIN: (state: formDataState, action: PayloadAction<any>) => {
      state.employerTPIN = action.payload.employerTPIN;
    },
    setHouseholdEmployerNRC: (state: formDataState, action: PayloadAction<any>) => {
      state.employerNRC = action.payload.employerNRC;
    },
    setHouseholdEmployerNAPSA: (state: formDataState, action: PayloadAction<any>) => {
      state.employerNAPSA = action.payload.employerNAPSA;
    },
  },
});

// Exporting action creators and reducer
export const { setHouseholdEmployerNAPSA, setHouseholdEmployerNRC, setHouseholdEmployerTPIN } =
  HouseHoldEmployerTaxFormDataSlice.actions;

export default HouseHoldEmployerTaxFormDataSlice.reducer;
