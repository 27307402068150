import { Button, Typography, List, ListItem } from "@zigops-material/react";
import React, { useState } from "react";
import Company from "./Company";
import BillingsAndSubscription from "./BillingsAndSubscription";
import Payroll from "./Payroll";
import Taxation from "./Taxation";
import PayrollFunding from "./FundingPayroll/index";

function RenderView(ViewId: number) {
  switch (ViewId) {
    case 1:
      return <Company />;
    case 2:
      return <BillingsAndSubscription />;
    case 3:
      return <Payroll />;
    case 4:
      return <Taxation />;
    case 5:
      return <PayrollFunding/>;
    default:
      break;
  }
}

function HouseHoldCompanySettings() {
  const [selected, setSelected] = useState(1);
  const setSelectedItem = (value) => setSelected(value);

  return (
    <div>
      <div className="lg-max:pt-5 flex h-20 w-full justify-between bg-orange-50/50 p-5 pl-7 pr-7 pt-3 dark:bg-transparent dark:text-gray-100">
        <div className="mt-2">
          <Typography className="text-2xl font-bold text-gray-800 dark:text-gray-300">
            Company Settings
          </Typography>
        </div>
      </div>
      <div className="flex h-[85vh] pl-4">
        <div className="col-span-2 mt-5 h-[95%] w-[300px] animate-fade-up overflow-y-auto rounded-md border border-gray-300 bg-white p-5 dark:border-gray-750 dark:bg-gray-850 ">
          <div>
            <Typography className="text-lg ">Company Name</Typography>
          </div>
          <div>
            <List>
              <ListItem selected={selected === 1} onClick={() => setSelectedItem(1)}>
                Company
              </ListItem>
              <ListItem selected={selected === 4} onClick={() => setSelectedItem(2)}>
                Billings and subscription
              </ListItem>
              <ListItem selected={selected === 5} onClick={() => setSelectedItem(3)}>
                Payroll
              </ListItem>
              <ListItem selected={selected === 8} onClick={() => setSelectedItem(4)}>
                Taxation
              </ListItem>
              <ListItem selected={selected === 8} onClick={() => setSelectedItem(5)}>
                Funding my Payroll
              </ListItem>
            </List>
          </div>
        </div>
        <div className="w-[70%]">{RenderView(selected)}</div>
      </div>
    </div>
  );
}

export default HouseHoldCompanySettings;
