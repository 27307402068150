// services/authService.ts
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { loginRequest, msalConfig, msalInvitationConfig, policies } from "./authConfig";

const jwt = false;

// @ts-ignore
export const msalInstance = jwt
// @ts-ignore
? new PublicClientApplication(msalInvitationConfig("email", "jwt"))
// @ts-ignore
  : new PublicClientApplication(msalConfig);

export const login = async () => {
  try {
    const loginResponse = await msalInstance.loginRedirect(loginRequest);

    // @ts-ignore
    return loginResponse.account;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const logout = async (instance: any, homeAccountId: string) => {
  try {
    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
    };
    instance.logoutRedirect(logoutRequest);

    return null;
  } catch (error) {
    console.error("Logout error:", error);
    throw error;
  }
};

export const getToken = async (): Promise<string | null> => {
  try {
    const response = await msalInstance.acquireTokenSilent(loginRequest);
    return response.accessToken;
  } catch (error) {
    console.error("Token retrieval error:", error);
    return null;
  }
};
