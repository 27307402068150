// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formErrorDataState {
  employerFullNameError: string;
  employerEmailError: string;
  employerTpinUrl_Error: string;
  employerNapsaUrlError: string;
  employerNhimaUrllError: string;
  employerPhoneNumberError: string;
  
}

// Initialize the initial state of the form data
const initialState: formErrorDataState = {
  employerFullNameError: "",
  employerEmailError: "",
  employerPhoneNumberError: "",
  employerTpinUrl_Error: "",
  employerNapsaUrlError: "",
  employerNhimaUrllError: "",
};

export const EmployerTaxDetailsErrorDataSlice = createSlice({
  name: "Household-collect-tax-details",
  initialState,
  reducers: {
    setHouseholdEmployerFullNameError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerFullNameError = action.payload.employerFullNameError;
    },
    setHouseholdEmployerEmailError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerEmailError = action.payload.employerEmailError;
    },
    setHouseholdEmployerPhoneNumberError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerPhoneNumberError = action.payload.employerPhoneNumberError;
    },

    setHouseholdEmployerTpinUrlError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerTpinUrl_Error = action.payload.employerTpinUrl_Error;
    },

    setHouseholdEmployerNapsaUrlError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerNapsaUrlError = action.payload.employerNapsaUrlError;
    },

    setHouseholdEmployerNhimaUrllError: (state: formErrorDataState, action: PayloadAction<any>) => {
      state.employerNhimaUrllError = action.payload.employerNhimaUrllError;
    },

  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployerFullNameError,
  setHouseholdEmployerEmailError,
  setHouseholdEmployerPhoneNumberError,
  setHouseholdEmployerTpinUrlError,
  setHouseholdEmployerNapsaUrlError,
  setHouseholdEmployerNhimaUrllError,
} = EmployerTaxDetailsErrorDataSlice.actions;

export default EmployerTaxDetailsErrorDataSlice.reducer;
