import { RequestPaginationCursor } from "@/types/Pagination"

/**
 * Builds a query parameter string for pagination.
 * 
 * @param cursor the request pagination cursor from which to build the parameter query string
 * @returns a query parameter string built from the passed request pagination cursor
 */
export const buildPaginationQueryString = (cursor: RequestPaginationCursor): string => {
    return `nextCursor=${cursor.nextCursor}&previousCursor=${cursor.previousCursor}&itemsPerPage=${cursor.itemsPerPage}&page=${cursor.page}`;
}

export const extractPaginationQueryParams = (url: string): Map<string, any> => {
    const queryParams = url.split("?")[1].split("&");
    const paginationQueryParams = new Map<string, any>();

    for (const param of queryParams) {
        const paramComponents = param.split("=");
        const paramName = paramComponents[0];
        const paramValue = paramComponents[1];

        if (isPaginationQueryParam(paramName)) {
            paginationQueryParams.set(paramName, paramValue);
        }
    }

    return paginationQueryParams;
}

const isPaginationQueryParam = (paramName: string): boolean => {
    return paramName === "nextCursor" || paramName === "previousCursor" || paramName === "page" || paramName === "itemsPerPage";
}