/**
 * File containing constants pertaining to payroll.
 */

/********************************************************************************
 * GENERAL PAYROLL CONSTANTS.
 * This section contains the general constants pertaining to payroll.
 ********************************************************************************/

/**
 * Defines the status of a payroll that is awaiting approval.
 */
export const STATUS_AWAITING_APPROVAL = "AwaitingApproval";

/**
 * Defines the status of a payroll that is rejected.
 */
export const STATUS_REJECTED = "Rejected";

/**
 * Defines an approved status.
 */
export const STATUS_APPROVED = "Approved";

/**
 * Defines a failed status.
 */
export const STATUS_FAILED = "Failed";

/**
 * Defines a success status.
 */
export const STATUS_SUCCESS = "Success";

/**
 * Defines a status for an employee that is having incomplete bank information.
 */
export const STATUS_MISSING_BANK_INFO = "MissingBankInfo";

/**
 * Defines a partially complete status.
 */
export const STATUS_PARTIALLY_COMPLETE = "PartiallyComplete";

/**
 * Defines a retrying status.
 */
export const STATUS_RETRYING = "Retrying";

/**
 * Defines a complete status.
 */
export const STATUS_COMPLETE = "Complete";

/**
 * Defines a processing status.
 */
export const STATUS_PROCESSING = "Processing";

/**
 * Defines an awaiting processing status.
 */
export const STATUS_AWAITING_PROCESSING = "AwaitingProcessing";

/**
 * Defines a successful status.
 */
export const STATUS_SUCCESSFUL = "Successful";

/**
 * Defines a pending status.
 */
export const STATUS_PENDING = "Pending";

/**
 * Defines a partially successful status;
 */
export const STATUS_PARTIALLY_SUCCESSFUL = "Partially Successful";

/**
 * Defines a awaiting payment processing status
 */
export const STATUS_AWAITING_PAYMENT_PROCESSING = "AwaitingPaymentProcessing";

/**
 * Defines a initiated payment processing status
 */
export const STATUS_INITIATED_PAYMENT_PROCESSING = "InitiatedPaymentProcessing";

/**
 * Defines a reinitiated payment processing status
 */
export const STATUS_REINITIATED_PAYMENT_PROCESSING = "ReinitiatedPaymentProcessing";

/**
 * Defines a funded status.
 */
export const STATUS_FUNDED = "FUNDED";

/*******************************************************************************
 * PAYROLL APPROVAL CHOICES CONSTANTS
 * This section contains constants representing payroll approval choices.
 * *****************************************************************************/

/**
 * Defines a rejection approval choice.
 */
export const APPROVAL_CHOICE_REJECT = "Reject";

/**
 * Defines an approve approval choice.
 */
export const APPROVAL_CHOICE_APPROVE = "Approve";

/**
 * Defines a 'no choice' approval choice.
 */
export const APPROVAL_CHOICE_NONE = "None";

/**
 * Defines a notification for successful update.
 */
export const PAYROLL_APPROVE_SUCCESSFULLY_UPDATED = "Successfully updated payroll state";

/**
 * Defines a notification for an error update.
 */
export const PAYROLL_APPROVE_ERROR = "Error setting payroll state ";

/**
 * Defines a displayed message showing the process of approving.
 */
export const PAYROLL_APPROVE_APPROVING_MESSAGE = "Approving payroll...";

/**
 * Defines a displayed message showing the process of rejecting.
 */
export const PAYROLL_APPROVE_REJECTING_MESSAGE = "Rejecting payroll...";

/**
 * Defines a displayed message showing the process of none.
 */
export const PAYROLL_APPROVE_NONE_MESSAGE = "Setting payroll to pending...";

/**
 * Defines a reducer type for payroll error .
 */
export const PAYROLL_APPROVE_REDUCER_TYPE_ERROR = "payroll_approve_error";

/********************************************************************************
 * PAYROLL EMPLOYEE SEARCH FILTER CONSTANTS.
 * This section contains the constants pertaining to search filters available
 * when performing employee searches in payroll.
 ********************************************************************************/

/**
 * Defines the employment type search filter
 */
export const EMPLOYMENT_TYPE_FILTER = "Employment type";
/**
 * Defines the first name search filter.
 */
export const FIRST_NAME_FILTER = "First name";
/**
 * Defines the last name search filter.
 */
export const LAST_NAME_FILTER = "Last name";

/********************************************************************************
 * PAYROLL PAYMENT FREQUENCY CONSTANTS.
 * This section contains the constants pertaining to the frequency of payroll.
 ********************************************************************************/

/**
 * Defines a one-time payment frequency.
 */
export const ONE_TIME_PAYMENT_FREQUENCY = "One-time";

/**
 * Defines a monthly payment frequency.
 */
export const MONTHLY_PAYMENT_FREQUENCY = "Monthly";

/**
 * Defines a bi-weekly payment frequency.
 */
export const BIWEEKLY_PAYMENT_FREQUENCY = "Bi-weekly";

/**
 * Defines a hourly payment frequency.
 */
export const HOURLY_PAYMENT_FREQUENCY = "Hourly";

/**
 * Defines an array list of payment types.
 */
export const PAYMENT_TYPES = [
  {
    type: "every_week",
    label: "Every week",
  },
  {
    type: "twice_per_month",
    label: "Twice per month",
  },
  {
    type: "monthly",
    label: "Monthly",
  },
  {
    type: "quarterly",
    label: "Quarterly",
  },
];


/********************************************************************************
 * HOUSEHOLD PAYROLL CONSTANTS.
 * This section contains the constants pertaining to the household payroll.
 ********************************************************************************/

/**
 * Defines a hourly payment frequency.
 */
export const HOUSEHOLD_PAYROLL_INITIALIZATION_FAILED_MESSAGE = "Error failed to initialize payroll";