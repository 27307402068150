// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface formDataState {
  employerFullName: string;
  employerPhysicalAddress: string;
  employerPhoneNumber: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  employerFullName: "",
  employerPhysicalAddress: "",
  employerPhoneNumber: "",
};

export const HouseHoldEmployerDataSlice = createSlice({
  name: "Household-collect-home-address-details",
  initialState,
  reducers: {
    setHouseholdEmployerFullName: (state: formDataState, action: PayloadAction<any>) => {
      state.employerFullName = action.payload.employerFullName;
    },
    setHouseholdEmployerPhysicalAddress: (state: formDataState, action: PayloadAction<any>) => {
      state.employerPhysicalAddress = action.payload.employerPhysicalAddress;
    },
    setHouseholdEmployerPhoneNumber: (state: formDataState, action: PayloadAction<any>) => {
      state.employerPhoneNumber = action.payload.employerPhoneNumber;
    },
  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployerFullName,
  setHouseholdEmployerPhysicalAddress,
  setHouseholdEmployerPhoneNumber,
} = HouseHoldEmployerDataSlice.actions;

export default HouseHoldEmployerDataSlice.reducer;
