import { Spinner, Typography } from "@zigops-material/react";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { EventType, InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import useStorage from "@/hooks/useStorage";
import { AUTH_TOKEN_KEY } from "@/constants/common.constants";
import { msalInstance } from "@/utils/authService";
import { userLoggedIn } from "@/redux/features/auth/user";
import { useDispatch } from "react-redux";
import Head from "next/head";
import { setHeaders } from "@/redux/features/headerSlice";

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const Login = () => {
  const { instance, accounts } = useMsal();
  const router = useRouter();
  const [headersSet, setheadersSet] = useState(false);

  const { id } = router.query;

  const { setItem } = useStorage();
  const dispatch = useDispatch();

  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    GetToken();
  }, [accounts]);

  const getEmployeeFromDb = (userData: any) => {
    setTimeout(() => {
      router.push("/auth/select-company");
    }, 1000);
  };

  const GetToken = async () => {
    const request = {
      scopes: ["openid"],
      account: accounts[0],
    };

    await instance
      .acquireTokenSilent(request)
      .then((response) => {
        dispatch(
          userLoggedIn({
            accessToken: response.idToken,
            environment: accounts[0].environment,
            homeAccountId: accounts[0].homeAccountId,
            name: accounts[0].name,
            nativeAccountId: accounts[0].nativeAccountId, 
            username: accounts[0].username,
            tenantId: accounts[0].tenantId,
            idTokenClaims: accounts[0].idTokenClaims,
            localAccountId: accounts[0].localAccountId,
          }),
        );
        setItem(AUTH_TOKEN_KEY, response.idToken);
        
        dispatch(
          setHeaders({
            tenantId: accounts[0].tenantId,
            accessToken: response.idToken,
          }),
        );

        getEmployeeFromDb(response);
      })
      .catch((error) => {
        // console.log(error);
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          try {
            if (instance)
              instance.acquireTokenRedirect(request).then((response) => {
                dispatch(
                  userLoggedIn({
                    // @ts-ignore
                    accessToken: response.idToken,
                    environment: accounts[0].environment,
                    homeAccountId: accounts[0].homeAccountId,
                    name: accounts[0].name,
                    nativeAccountId: accounts[0].nativeAccountId,
                    username: accounts[0].username,
                    tenantId: accounts[0].tenantId,
                    idTokenClaims: accounts[0].idTokenClaims,
                    localAccountId: accounts[0].localAccountId,
                  }),
                );
                // @ts-ignore
                setItem(AUTH_TOKEN_KEY, response.idToken);
                dispatch(
                  setHeaders({
                    tenantId: accounts[0].tenantId,
                    // @ts-ignore
                    accessToken: response.idToken,
                  }),
                );
                getEmployeeFromDb(response);
              });
          } catch (error) {}
        }
      });
  };

  return (
    <div className="flex min-h-screen w-full place-content-center justify-center">
      <Head>
        <title>Loading- ZigOps Payroll</title>
      </Head>
      <div className="m-auto min-h-min w-2/4 place-content-center text-center">
        <Spinner className="m-auto mb-0 h-8 w-8" color="amber" />
        <Typography>Loading please wait...</Typography>
      </div>
    </div>
  );
};

export default Login;

Login.getLayout = function PageLayout(page: any) {
  return (
    <>
      <Head>
        <title>Login- ZigOps Payroll</title>
      </Head>
      {page}
    </>
  );
};
