import { Company } from './../../../types/Company';
// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formDataState {
  CompanyPhoneNumber: string;
  CompanyPhysicalAddress: string;
  CompanyProvince: string;
  CompanyCity: string;
  CompanyPostalAddress: string;
  CompanyTaxId: string;
  CompanyIndustry: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
CompanyCity: "",
CompanyPhoneNumber: "",
CompanyPhysicalAddress: "",
CompanyPostalAddress: "",
CompanyProvince: "",
CompanyTaxId: "",
CompanyIndustry: "",
};

// Creating a Redux slice for managing company form data
export const TribeCompanyFormDataSlice = createSlice({
  name: "Tribe-plus-company-profile-form-data",
  initialState,
  reducers: {
    // Reducer to set Phone number
    setCompanyPhoneNumber: (state: formDataState, action: PayloadAction<any>) => {
      state.CompanyPhoneNumber = action.payload.CompanyPhoneNumber;
    },
    // Reducer to set Postal address
    setCompanyPostalAddress: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyPostalAddress = action.payload.CompanyPostalAddress;
    },
    // Reducer to set Province
    setCompanyProvince: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyProvince = action.payload.CompanyProvince;
    },
    // Reducer to set City
    setCompanyCity: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyCity = action.payload.CompanyCity;
    },
    // Reducer to set Physical address
    setCompanyPhysicalAddress: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyPhysicalAddress = action.payload.CompanyPhysicalAddress;
    },
    // Reducer to set Tax ID
    setCompanyTaxId: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyTaxId = action.payload.CompanyTaxId;
    },

    // Reducer to set Industry
    setCompanyIndustry: (state: formDataState, action: PayloadAction<any>) => {
        state.CompanyIndustry = action.payload.CompanyIndustry; // Setting the industry in the Redux store          
    }
  },
});

// Exporting action creators and reducer
export const {
setCompanyCity,
setCompanyPhoneNumber,
setCompanyPhysicalAddress,
setCompanyPostalAddress,
setCompanyProvince,
setCompanyTaxId,
setCompanyIndustry
} = TribeCompanyFormDataSlice.actions;

export default TribeCompanyFormDataSlice.reducer;
