import { Employee } from "@/apis/models/employee.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPayroll{
    payrollId:string;
};
const initialState: IPayroll = {
    payrollId: "",
};

export const activePayrollSlice = createSlice({
  name: "activePayroll",
  initialState,
  reducers: {
    setActivePayroll: (state: IPayroll, action: PayloadAction<any>) => {
      state.payrollId = action.payload.payrollId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActivePayroll } = activePayrollSlice.actions;

export default activePayrollSlice.reducer;
