// components/Accordion.js

import { useState } from "react";
const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log("Text copied to clipboard:", text);
      // Optionally, you can set state or show a notification that the text has been copied
    })
    .catch((error) => {
      console.error("Error copying text to clipboard:", error);
      // Handle error, perhaps show an error message to the user
    });
};

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [copied, setCopied] = useState(false);

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    setCopied(true); // Optionally, set state to indicate that the text has been copied
  };

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const accountName = "Zoona Transactions Zambia Limited";
  const referenceNumber = process.env.REACT_APP_ZIG_OPS_MERCHANT_ACCOUNT_NUMBER;

  return (
    <div className="w-full">
      <div className="flex h-full w-full flex-col overflow-auto">
        {items.map((item, index) => (
          <div key={index} className="mb-4 overflow-auto font-bold">
            <button
              className="flex w-full justify-between bg-[#ffffff] px-4 py-2 text-left"
              onClick={() => toggleAccordion(index)}
            >
              <span>{item.title}</span>
              <span
                className={`p-1 text-blue-500 ${
                  openIndex === index ? "text-blue-500" : "text-blue-500"
                }`}
              >
                {openIndex === index ? "-" : "+"}
              </span>
            </button>
            {openIndex === index && (
              <div className="flex flex-col gap-3 overflow-auto bg-white p-4">
                <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden border border-solid bg-[#ffffff] pb-3 pl-6 pr-6 pt-3">
                  <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative w-[200px] text-left">
                    Account name
                  </div>
                  <div className="text-m3-sys-light-outline font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left text-left">
                    {accountName}
                    {""}
                  </div>
                  <div></div>
                </div>
                <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden border border-solid bg-[#ffffff] pb-3 pl-6 pr-6 pt-3">
                  <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative w-[200px] text-left">
                    Branch name
                  </div>
                  <div className="text-m3-sys-light-outline  font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left text-left">
                    {item.content.branchName}
                  </div>
                  <div></div>
                </div>
                <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden border border-solid bg-[#ffffff] pb-3 pl-6 pr-6 pt-3">
                  <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative w-[200px] text-left">
                    Account number
                  </div>
                  <div className="text-m3-sys-light-outline font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left">
                    {item.content.accountNumber}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => handleCopy(item.content.accountNumber)}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2m-4 0H9a2 2 0 00-2 2v12a2 2 0 002 2h2m4 0v-2m0 0V7m0 2h2m-2 0H9m2 0v2m0-2h2"
                    />
                  </svg>
                </div>
                <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden border border-solid bg-[#ffffff] pb-3 pl-6 pr-6 pt-3">
                  <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative w-[200px] text-left">
                    Branch code
                  </div>
                  <div className="text-m3-sys-light-outline font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left">
                    {item.content.branchCode}
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 "
                      fill="none"
                      viewBox="0 0 24 24"
                      onClick={() => handleCopy(item.content.branchCode)}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2m-4 0H9a2 2 0 00-2 2v12a2 2 0 002 2h2m4 0v-2m0 0V7m0 2h2m-2 0H9m2 0v2m0-2h2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="border-m3-sys-light-inverse-on-surface relative flex shrink-0 flex-row items-start justify-between overflow-hidden border border-solid bg-[#ffffff] pb-3 pl-6 pr-6 pt-3">
                  <div className="text-m3-sys-light-inverse-surface font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative w-[200px] text-left">
                    Reference number
                  </div>
                  <div className="text-m3-sys-light-outline font-m3-body-large-font-family text-m3-body-large-font-size leading-m3-body-large-line-height font-m3-body-large-font-weight relative text-left">
                    {referenceNumber}
                    {""}
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 "
                      fill="none"
                      viewBox="0 0 24 24"
                      onClick={() => handleCopy(referenceNumber)}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2m-4 0H9a2 2 0 00-2 2v12a2 2 0 002 2h2m4 0v-2m0 0V7m0 2h2m-2 0H9m2 0v2m0-2h2"
                      />
                    </svg>
                  </div>
                </div>
                {/* Add similar divs for other content */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
