import React from "react";

const FundingSourceCompleteView = () => {
  return (
    <div>
      <div className={"relative flex flex-col items-center justify-start gap-[9px] ml-10 pt-10 "}>
        <div className="relative flex shrink-0 flex-col items-center justify-start gap-[15px]">
          <div
            className="relative text-left font-['Montserrat-Medium',_sans-serif] text-[22px] font-medium leading-6 text-[#000000]"
            style={{ letterSpacing: "0.15px" }}
          >
            Congratulations!{" "}
          </div>
          <div
            className="relative text-left font-['Montserrat-Medium',_sans-serif] text-base font-medium leading-6"
            style={{ letterSpacing: "0.15px" }}
          >
            <span>
              <span className="send-your-proof-of-payment-to-pop-zigops-com-to-complete-your-funding-process-span">
                Send your proof of payment to
              </span>
              <span className="send-your-proof-of-payment-to-pop-zigops-com-to-complete-your-funding-process-span2">
                {" "}
                pop@zigops.com
              </span>
              <span className="send-your-proof-of-payment-to-pop-zigops-com-to-complete-your-funding-process-span3">
                {" "}
                to complete your funding process
              </span>
            </span>{" "}
          </div>
        </div>
        <img
          className="relative h-[257px] w-[313px] shrink-0 overflow-hidden"
          style={{ objectFit: "cover" }}
          src="/img/happy_people.png"
        />
      </div>
    </div>
  );
};

export default FundingSourceCompleteView;
