import React, { useEffect, useState } from "react";
import Button from "@/zigops/zigops-material-react/src/components/Button";
import { useRouter } from "next/navigation";
import PlanDetail from "./[id]";
import { useGetFeaturesQuery, useListPlansQuery } from "@/apis/plansApiSlice";
import { Spinner, Typography } from "@zigops-material/react";
import { pl } from "date-fns/locale";

const PlanList: React.FC = () => {
  const router = useRouter();
  const [details, setDetails] = useState<boolean>(false);

  const {
    data: plans,
    isLoading: isLoadingPlans
  } = useListPlansQuery("USD");

  const {
    data: features,
    isLoading: isLoadingFeatures
  } = useGetFeaturesQuery({});

  const handleDetails = (plan: any) => {
    router.push(`/plans/${plan?.plan?.planId}`);
    setDetails(true);
  };

  useEffect(() => { 
  } , [plans]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      {isLoadingFeatures || isLoadingPlans ? (
        <div className="flex flex-col items-center">
          <Spinner className="mb-2 h-8 w-8" color="amber" />
          <Typography>Loading please wait...</Typography>
        </div>
      ) : details ? (
        <PlanDetail />
      ) : (
        <>
          <div className="absolute top-0 mb-10 flex w-full flex-col items-center justify-center pb-4">
            <img
              className="mb-3 h-[150px] w-[109.83px]"
              style={{ objectFit: "cover" }}
              src="/img/Main-Logo-Social.png"
              alt="Main Logo"
            />
            <div className="text-m3-sys-light-on-surface-variant mb-5 mt-5 w-full max-w-md text-center font-['Montserrat-SemiBold',_sans-serif] text-[28px] font-semibold leading-9">
              Find the right plan for your needs
            </div>
          </div>

          <img
            className="absolute bottom-0 left-0 h-auto w-full border-t-[2px] border-gold-400 sm:h-32 md:h-48 lg:h-64 xl:h-80"
            style={{ objectFit: "cover" }}
            src="/img/gold-pattern.png"
            alt="Footer Image"
          />
          <div
            className="mt-20 flex h-[538px] w-[70%] flex-col sm:left-[230px] sm:top-[253px] p-8 m-5"
          >
            <div className="relative flex h-[202px] w-full flex-row items-start justify-start gap-0 ">
              <div className="bg-m3-sys-light-background border-m3-sys-light-inverse-on-surface relative flex w-[220px] shrink-0 flex-row items-center justify-center gap-2.5 self-stretch border border-solid pb-2.5 pl-[15px] pr-5 pt-2.5">
                <div className="text-m3-sys-light-inverse-surface relative text-left font-['Montserrat-Bold',_sans-serif] text-base font-bold leading-6">
                  Feature
                </div>
              </div>
              {plans?.map((plan, index) => (
                <div
                  key={plan.id}
                  className="bg-m3-sys-light-surface-container-low border-m3-sys-light-surface-bright flex flex-1 flex-row items-center justify-center gap-2.5 border-b border-solid bg-gray-200 pb-2.5 pl-3 pr-3 pt-2.5"
                >
                  <div className="flex flex-col items-center justify-center gap-2.5">
                    <div className="text-m3-sys-light-inverse-surface font-['Montserrat-Bold',_sans-serif] text-base font-bold leading-6">
                      {plan?.plan?.name}
                    </div>
                    <div
                      className="text-m3-sys-light-surface-tint font-['Montserrat-MediumItalic',_sans-serif] text-sm font-medium leading-6"
                      style={{ fontStyle: "italic" }}
                    >
                      {plan?.plan?.description}
                    </div>
                    <div className="text-m3-sys-light-inverse-surface">
                      <span>
                        <span className="k-50-transaction-span">
                          {plan?.planPrice?.currency}
                          {plan?.planPrice?.planPrice}
                        </span>
                        <span className="k-50-transaction-span2"> / transaction</span>
                      </span>
                    </div>
                    <Button onClick={() => handleDetails(plan)}>Select</Button>
                  </div>
                </div>
              ))}
            </div>
            {features?.map((feature) => (
              <>
                <div className="relative flex h-[60px] flex-row items-start justify-start bg-white">
                  <div className="border-m3-sys-light-inverse-on-surface flex w-[220px] flex-row items-center justify-between border border-solid pb-2.5 pl-[15px] pr-5 pt-2.5">
                    <div className="flex w-[200px] flex-row items-center justify-between">
                      <div className="text-m3-sys-light-inverse-surface font-m3-title-medium-font-family text-m3-title-medium-font-size leading-m3-title-medium-line-height font-m3-title-medium-font-weight text-left">
                        {feature?.title}
                      </div>
                      <svg
                        className="h-[18px] w-[18px] overflow-visible"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                            stroke="#767680"
                            strokeWidth="1.11183"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 6V9"
                            stroke="#767680"
                            strokeWidth="1.48244"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 12H9.00773"
                            stroke="#767680"
                            strokeWidth="1.85305"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  {plans?.map((plan) => {
                    var isAttached: boolean = false;
                    plan?.planFeatures.map((planFeature) => {
                      if (planFeature.featureId == feature.featureId) {
                        isAttached = true;
                      }
                    });
                    return (
                      <>
                        {isAttached ? (
                          <>
                            <div className="border-m3-sys-light-inverse-on-surface relative flex flex-1 flex-row items-center justify-center gap-2.5 border border-b border-l border-t border-solid pb-2.5 pl-[15px] pr-5 pt-2.5">
                              <svg
                                className="h-6 w-6"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.5 16.4L6.5 12.4L7.9 11L10.5 13.6L17.1 7L18.5 8.4L10.5 16.4Z"
                                  fill="#1B1B1F"
                                />
                              </svg>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="border-m3-sys-light-inverse-on-surface relative flex flex-1 flex-row items-center justify-center gap-2.5 border border-b border-l border-t border-solid pb-2.5 pl-[15px] pr-5 pt-2.5">
                              <svg
                                className="relative h-6 w-6 shrink-0 overflow-visible"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.17383 13.3555V11.3555H17.1738V13.3555H7.17383Z"
                                  fill="#1B1B1F"
                                />
                              </svg>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PlanList;
