// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
interface formDataState {
  EmployeeNameError: string;
  EmployeeEmailError: string;
  EmployeePhoneNumberError: string;
  EmployeePhysicalAddressError: string;
  EmployeeJobTitleError: string;
  EmployeeGrossIncomeError: number;
  EmployeeMobileMoneyNumberError: string;
  EmployeeMaritalStatusError: string;
  EmployeesTPINError: string;
  EmployeesNRCError: string;
  EmployeesNAPSAError: string;
  EmployeeNhimaMemberIdError: string;
  EmployeesNextOfKinNameError: string;
  EmployeesNextOfKinRelationshipError: string;
  EmployeesGenderError: string;
  EmployeesMobileMoneyProviderError: string;
  EmployeesNextOfKinTitleError: string;
  EmployeesNextOfKinContactDetailsError: string;
  EmployeeStartDateError: string;
}

// Initialize the initial state of the form data
const initialState: formDataState = {
  EmployeeNameError: "",
  EmployeeEmailError: "",
  EmployeePhoneNumberError: "",
  EmployeePhysicalAddressError: "",
  EmployeesGenderError: "",
  EmployeesMobileMoneyProviderError: "",
  EmployeesNextOfKinRelationshipError: "",
  EmployeeGrossIncomeError: 0,
  EmployeeJobTitleError: "",
  EmployeeMaritalStatusError: "",
  EmployeeMobileMoneyNumberError: "",
  EmployeesNAPSAError: "",
  EmployeeNhimaMemberIdError: "",
  EmployeesNextOfKinContactDetailsError: "",
  EmployeesNextOfKinNameError: "",
  EmployeesNextOfKinTitleError: "",
  EmployeesNRCError: "",
  EmployeesTPINError: "",
  EmployeeStartDateError: "",
};

// Creating a Redux slice for managing Employee form data
export const EmployeeErrorsFormDataSlice = createSlice({
  name: "Household-Errors-employee-form-data",
  initialState,
  reducers: {
    // Reducer to set Employee name
    setHouseholdEmployeeNameError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeNameError = action.payload.EmployeeNameError;
    },
    // Reducer to set EmployeesNextOfKinTitleError
    setHouseholdEmployeesNextOfKinTitleError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesNextOfKinTitleError = action.payload.EmployeesNextOfKinTitleError;
    },
    // Reducer to set EmployeesNextOfKinNameError
    setHouseholdEmployeesNextOfKinNameError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNextOfKinNameError = action.payload.EmployeesNextOfKinNameError;
    },
    // Reducer to set EmployeeMaritalStatusError
    setHouseholdEmployeeMaritalStatusError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeMaritalStatusError = action.payload.EmployeeMaritalStatusError;
    },

    // Reducer to set Employee email
    setHouseholdEmployeeEmailError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeEmailError = action.payload.EmployeeEmailError;
    },
    // Reducer to set EmployeeMobileMoneyNumberError
    setHouseholdEmployeeMobileMoneyNumberError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeeMobileMoneyNumberError = action.payload.EmployeeMobileMoneyNumberError;
    },

    // Reducer to set Employee Phone
    setHouseholdEmployeePhoneNumberError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeePhoneNumberError = action.payload.EmployeePhoneNumberError;
    },
    // Reducer to set Employee Phone
    setHouseholdEmployeePhysicalAddressError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeePhysicalAddressError = action.payload.EmployeePhysicalAddressError;
    },
    // Reducer to set EmployeesGenderError
    setHouseholdEmployeesGenderError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesGenderError = action.payload.EmployeesGenderError;
    },

    // Reducer to set EmployeesMobileMoneyProviderError
    setHouseholdEmployeesMobileMoneyProviderError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesMobileMoneyProviderError = action.payload.EmployeesMobileMoneyProviderError;
    },

    // Reducer to set EmployeesNextOfKinRelationshipError
    setHouseholdEmployeesNextOfKinRelationshipError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesNextOfKinRelationshipError =
        action.payload.EmployeesNextOfKinRelationshipError;
    },

    // Reducer to set EmployeesNextOfKinNameError
    setHouseholdEmployeeStartDateError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeStartDateError = action.payload.EmployeeStartDateError;
    },

    // Reducer to set EmployeeGrossIncomeError
    setHouseholdEmployeeGrossIncomeError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeGrossIncomeError = action.payload.EmployeeGrossIncomeError;
    },

    // Reducer to set EmployeeJobTitleError
    setHouseholdEmployeeJobTitleError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeJobTitleError = action.payload.EmployeeJobTitleError;
    },

    // Reducer to set EmployeesNRCError
    setHouseholdEmployeesNRCError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNRCError = action.payload.EmployeesNRCError;
    },
    // Reducer to set EmployeesNAPSAError
    setHouseholdEmployeesNAPSAError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesNAPSAError = action.payload.EmployeesNAPSAError;
    },

    // Reducer to set EmployeesTPINError
    setHouseholdEmployeesTPINError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeesTPINError = action.payload.EmployeesTPINError;
    },

    setHouseholdEmployeeNhimaMemberIdError: (state: formDataState, action: PayloadAction<any>) => {
      state.EmployeeNhimaMemberIdError = action.payload.EmployeeNhimaMemberIdError;
    },

    // Reducer to set EmployeesNextOfKinContactDetailsError
    setHouseholdEmployeesNextOfKinContactDetailsError: (
      state: formDataState,
      action: PayloadAction<any>,
    ) => {
      state.EmployeesNextOfKinContactDetailsError =
        action.payload.EmployeesNextOfKinContactDetailsError;
    },
  },
});

// Exporting action creators and reducer
export const {
  setHouseholdEmployeeNameError,
  setHouseholdEmployeeEmailError,
  setHouseholdEmployeePhoneNumberError,
  setHouseholdEmployeePhysicalAddressError,
  setHouseholdEmployeesGenderError,
  setHouseholdEmployeesMobileMoneyProviderError,
  setHouseholdEmployeesNextOfKinRelationshipError,
  setHouseholdEmployeesNextOfKinNameError,
  setHouseholdEmployeeStartDateError,
  setHouseholdEmployeeGrossIncomeError,
  setHouseholdEmployeeJobTitleError,
  setHouseholdEmployeesNextOfKinContactDetailsError,
  setHouseholdEmployeesNRCError,
  setHouseholdEmployeesNAPSAError,
  setHouseholdEmployeesTPINError,
  setHouseholdEmployeeNhimaMemberIdError,
  setHouseholdEmployeeMaritalStatusError,
  setHouseholdEmployeeMobileMoneyNumberError,
  setHouseholdEmployeesNextOfKinTitleError,
  
} = EmployeeErrorsFormDataSlice.actions;

export default EmployeeErrorsFormDataSlice.reducer;
