// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

interface employeeBasicErrors {
    dateOfBirthError: string;
    genderError: string;
}

// Initialize the initial state of the form data
const initialState: employeeBasicErrors = {
    dateOfBirthError: "",
    genderError: "",
};

export const employeeBasicFormErrorsReducer = createSlice({
  name: "employee-basic-form-errors",
  initialState,
  reducers: {
    setEmployeeDateOfBirthError: (state: employeeBasicErrors, action: PayloadAction<any>) => {
      state.dateOfBirthError = action.payload.dateOfBirthError;
    },
    setGenderError: (state: employeeBasicErrors, action: PayloadAction<any>) => {
      state.genderError = action.payload.genderError;
    },
  },
});

// Exporting action creators and reducer
export const { setEmployeeDateOfBirthError, setGenderError } = employeeBasicFormErrorsReducer.actions;

export default employeeBasicFormErrorsReducer.reducer;
