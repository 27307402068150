// Importing necessary modules and types
import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Defining the shape of the form data state
export interface IPlanFeature {
    featureId: string;
    title: string;
    attachmentId: string;
    comment: string;
    description: string;
}

export interface IPlanFeatureState {
    planFeatures: IPlanFeature[];
}

// Initialize the initial state of the form data
const initialState: IPlanFeatureState = {
    planFeatures: [],
};

// Creating a Redux slice for managing Employee form data
export const TenantPlanFeaturesDetailsDataSlice = createSlice({
  name: "Tenant-plan-feature-details-data",
  initialState,
  reducers: {
    // Reducer to set Plan Id
    setTenantPlanFeaturesDetails: (state: IPlanFeatureState, action: PayloadAction<IPlanFeatureState>) => {
        state.planFeatures = action.payload.planFeatures;
    },
  },
});

// Exporting action creators and reducer
export const { setTenantPlanFeaturesDetails } = TenantPlanFeaturesDetailsDataSlice.actions;

export default TenantPlanFeaturesDetailsDataSlice.reducer;
