import { RootState } from "@/redux/configureStore";
import { setHouseholdEmployerPhoneNumber } from "@/redux/features/household/household-employer-tax-details";
import { setHouseholdEmployerPhoneNumberError } from "@/redux/features/household/household-employer-tax-details-errors";
import { Typography } from "@zigops-material/react";
import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";

function EmployerPhoneNumber() {
  const { employerPhoneNumber } = useSelector(
    (state: RootState) => state.app_reducer.employerTaxDetailsData,
  );

  const { employerPhoneNumberError } = useSelector(
    (state: RootState) => state.app_reducer.employerTaxDetailsErrorData,
  );

  const dispatch = useDispatch();

  const onHandleChange = (phone: any) => {
    dispatch(setHouseholdEmployerPhoneNumber({ employerPhoneNumber: phone }));
    dispatch(setHouseholdEmployerPhoneNumberError({ employerPhoneNumberError: "" }));
  };

  return (
    <div className="grid h-full w-full p-5 ">
      <div className="col-span-2 h-[340px] w-full animate-fade-up overflow-y-auto rounded-md border border-gray-300 bg-white p-5 dark:border-gray-750 dark:bg-gray-850 xl-max-screen:h-[480px]">
        <div className="mt-10">
          <Typography className="text-2xl text-gray-500">Enter your phone number.</Typography>
        </div>
        <div className="mt-6 w-[100%] md:w-[50%] lg:w-[50%]">
          <PhoneInput
            className="w-full"
            inputClassName="w-full"
            defaultCountry="zm"
            value={employerPhoneNumber}
            onChange={(phone) => onHandleChange(phone)}
          />
          <div className="mt-2 text-red-300">{employerPhoneNumberError}</div>
        </div>
      </div>
    </div>
  );
}

export default EmployerPhoneNumber;
