import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "@/redux/features/auth/user";
import themeReducer from "@/redux/features/theme";
import triggerReducer from "@/redux/actions/stringTrigger";
import contractsReducer from "@/redux/features/employees/contractType";
import companyReducer from "@/redux/features/company";
import leavePoliciesReducer from "@/redux/features/leave-policies";
import leaveMonthCalendarReducer from "@/redux/features/leave-policies/leave-calendar";
import holidaysReducer from "@/redux/features/holiday/holidays";
import employeesReducer from "@/redux/features/company/employees";
import headersReducer from "@/redux/features/headerSlice";
import activeEmployeeDetailsReducer from "@/redux/features/user/activeUser";
import payrollFormDataReducer from "@/redux/features/payroll/payroll-onboarding-form-data";
import payrollFormErrorReducer from "@/redux/features/payroll/payroll-form-errors";
import payrollGroupsReducer from "./features/payroll/payroll-groups";
import payrollDataBusReducer from "./features/payroll/payroll-data-bus";
import householdEmployeeFormDataReducer from "./features/household/household-employee-formdata";
import householdEmployeeFormDataErrorsReducer from "./features/household/household-employee-formdata-errors";
import invitedEmployeesReducer from "./features/employees/invited-employees-formdata";
import invitedEmployeesErrorsReducer from "./features/employees/invited-employees-formdata-errors";
import notificationsReducer from "./features/notifications";
import triggerNotificationReducer from "./features/notifications/triggerNotification";
import signalrConnectionReducer from "./features/notifications/signalR";
import employeeBasicFormDataReducer from "./features/employees/employee-basic-formdata";
import employeeBasicFormErrorsReducer from "./features/employees/employee-basic-formdata-errors";
import employeePhysicalAddressFormDataReducer from "./features/employees/employee-physical-address-formdata";
import EmployerTaxDetailsDataReducer from "./features/household/household-employer-tax-details";
import EmployerTaxDetailsErrorDataReducer from "./features/household/household-employer-tax-details-errors";
import activePayrollReducer from "@/redux/features/activePayroll/";
import createLeaveRequestFormDataReducer from "./features/leaveRequests/create-leave-request-form-data";
import createLeaveRequestFormDataErrorsReducer from "./features/leaveRequests/create-leave-request-form-data-errors";
import companySubscriptionStateReducer from "@/redux/features/company/subscription";
import houseHoldEmployerFormDataReducer from "@/redux/features/household/household-employer-formdata";
import houseHoldEmployerFormDataErrorReducer from "@/redux/features/household/household-employer-formdata-errors";
import houseHoldEmployerTaxFormDataReducer from "@/redux/features/household/household-employer-tax-formdata";
import houseHoldEmployerTaxFormDataErrorReducer from "@/redux/features/household/household-employer-tax-formdata-error";
import subscriptionCreationDataReducer from "@/redux/features/subscription/subscription-creation-data";
import tenantPlanDetailsData from "./features/subscription/tenant-plan-details-data";
import tenantSubscriptionDetailsData from "./features/subscription/tenant-subscription-details-data";
import TenantPlanFeaturesDetailsDataSlice from "./features/subscription/tenant-plan-features-details.data";
import tribePlusCompanyProfileFormDataReducer from "./features/tribe-plus/tribe-plus-company-profile-form-data";
import tribePlusCompanyProfileFormDataErrorReducer from "./features/tribe-plus/tribe-plus-company-profile-form-data-error";

// Add all reducers to the rootReducer object
const rootReducer = combineReducers({
  user: userReducer,
  app_theme: themeReducer,
  stringTrigger: triggerReducer,
  contractsType: contractsReducer,
  companyInfo: companyReducer,
  leavePolicies: leavePoliciesReducer,
  leaveMonthCalendar: leaveMonthCalendarReducer,
  holidays: holidaysReducer,
  headers: headersReducer,
  employees: employeesReducer,
  activeEmployee: activeEmployeeDetailsReducer,
  payrollFormData: payrollFormDataReducer,
  payrollFormErrors: payrollFormErrorReducer,
  PayrollGroups: payrollGroupsReducer,
  PayrollDataBus: payrollDataBusReducer,
  householdEmployeeFormData: householdEmployeeFormDataReducer,
  householdEmployeeFormDataErrors: householdEmployeeFormDataErrorsReducer,
  notificationState: notificationsReducer,
  triggerNotification: triggerNotificationReducer,
  signalR: signalrConnectionReducer,
  invitedEmployee: invitedEmployeesReducer,
  invitedEmployeeErrors: invitedEmployeesErrorsReducer,
  employeeBasicFormData: employeeBasicFormDataReducer,
  employeeBasicFormErrors: employeeBasicFormErrorsReducer,
  employeePhysicalAddressFormData: employeePhysicalAddressFormDataReducer,
  employerTaxDetailsData: EmployerTaxDetailsDataReducer,
  employerTaxDetailsErrorData: EmployerTaxDetailsErrorDataReducer,
  activePayroll: activePayrollReducer,
  createLeaveRequestFormData: createLeaveRequestFormDataReducer,
  createLeaveRequestFormDataErrors: createLeaveRequestFormDataErrorsReducer,
  companySubscriptionStateReducer: companySubscriptionStateReducer,
  houseHoldEmployerFormDataReducer: houseHoldEmployerFormDataReducer,
  houseHoldEmployerFormDataErrorReducer: houseHoldEmployerFormDataErrorReducer,
  houseHoldEmployerTaxFormDataReducer: houseHoldEmployerTaxFormDataReducer,
  houseHoldEmployerTaxFormDataErrorReducer: houseHoldEmployerTaxFormDataErrorReducer,
  subscriptionCreationDataReducer: subscriptionCreationDataReducer,
  tenantPlanDetailsData: tenantPlanDetailsData,
  tenantSubscriptionDetailsData: tenantSubscriptionDetailsData,
  tenantPlanFeaturesDetailsData: TenantPlanFeaturesDetailsDataSlice,
  tribePlusCompanyProfileFormData: tribePlusCompanyProfileFormDataReducer,
  tribePlusCompanyProfileFormDataError: tribePlusCompanyProfileFormDataErrorReducer,
});

export default rootReducer;
