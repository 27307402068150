import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface subscriptionInterface{
    hasSubscription: boolean,
    planId:string,
    planAppId:string,
}

const initialState:subscriptionInterface = {
 hasSubscription:false,
 planAppId:"",
 planId:""
};

export const subscriptionSlice = createSlice({
  name: "companySubscriptionDetail",
  initialState,
  reducers: {
    setHasSubscription: (state: subscriptionInterface, action: PayloadAction<any>) => {
      state.hasSubscription = action.payload.hasSubscription;
    },
    setPlanId: (state: subscriptionInterface, action: PayloadAction<any>) => {
        state.planId = action.payload.planId;
      },
      setPlanAppId: (state: subscriptionInterface, action: PayloadAction<any>) => {
        state.planAppId = action.payload.planAppId;
      },
  },
});

// Action creators are generated for each case reducer function
export const { setHasSubscription, setPlanAppId,setPlanId } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
