import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import useStorage from "@/hooks/useStorage";
import { AUTH_TOKEN_KEY, CORRELATION_ID, TENANT } from "@/constants/common.constants";

import { v4 as uuid } from "uuid";
import { server } from "@/config/server";

const { getItem } = useStorage();

const correlationId = getItem(CORRELATION_ID);
const auth_key = getItem(AUTH_TOKEN_KEY);
const tenant_id = getItem(TENANT);

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: server,
    prepareHeaders: (headers) => {
      headers.set("Authorization", auth_key);
      headers.set("x-correlation-id", correlationId ? correlationId : uuid());
      headers.set("Tenant", tenant_id);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Employees"],
  endpoints: (builder) => ({}),
});
