import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationsState {
  unreadCount: number;
}

// Initialize the notification state
const initialState: NotificationsState = {
  unreadCount: 0
};

export const NotificationState = createSlice({
  name: "notificationsState",
  initialState,
  reducers: {
    /**
     *
     * @param state (optional) use this only if you want to change the state of the notifications 
     * @param action used to pass the payload to the callback function
     * Updates the notification state
     */
    setNotificationState: (state: NotificationsState, action: PayloadAction<any>) => {
      state.unreadCount = action.payload.unreadCount;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotificationState } = NotificationState.actions;

export default NotificationState.reducer;
