import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeaders from "./headers";
import { server } from "@/config/server";
import { LeaveType } from "@/types/Leave";
import { RequestPaginationCursor, ResponsePaginationCursor } from "@/types/Pagination";
import { buildPaginationQueryString } from "@/utils/pagination";

export const leaveTypesApiSlice = createApi({
    reducerPath: "leaveTypesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: server,
        prepareHeaders: getHeaders
    }),
    tagTypes: ["leaveTypes"],
    endpoints: (builder) => ({
        getLeaveTypesByApplicableEmploymentTypesAndTenantId: builder.query<ResponsePaginationCursor<LeaveType>, RequestPaginationCursor>({
            query: (args) => {
                const queryParams = buildPaginationQueryString({
                    nextCursor: args.nextCursor,
                    previousCursor: args.previousCursor,
                    itemsPerPage: args.itemsPerPage,
                    page: args.page
                });

                return {
                    url: `employees/${args.otherRequestParams.employeeId}/leave-types?${queryParams}`,
                    method: "GET"
                }
            }
        })
    })
});

export const {
    useGetLeaveTypesByApplicableEmploymentTypesAndTenantIdQuery
} = leaveTypesApiSlice;